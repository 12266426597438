import React from 'react'
import Skeleton from 'react-loading-skeleton';
const ListSkelton = (props) => {
    const { numCol, numRow, loading } = props
    if(loading){
        return(
            <div className='blog-custom-list-table-data'>
                {Array.from({ length: numRow }, (index) => {
                    return(<div key={ index } className='blog-list-table blog-list-header'>
                        {Array.from({ length: numCol }, (col) => {
                            return(<div key={ col } className='blog-list-column'>
                                <Skeleton count={ 1 } width={ 120 } />
                            </div>)
                        })
                        }
                    </div>)
                })
                }
            </div>

        )
    }else{
        return null
    }
}
export default ListSkelton