import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {  useSelector } from 'react-redux'
import { Button } from 'react-bootstrap';
//import { getDomainData } from 'middleware/domain'
//import { getSite } from 'utils/helpers'
import ButtonLoader from 'components/core/loader/button-loader';
//import FinalStepIcon from '../../../images/finalstep.svg';
//import FinalRocketIcon from '../../../images/tenor.gif';
import finalStepIcon from 'images/custom-domain/final-step-icon.svg';
const FinalStep = (props) => {
    // const dispatch = useDispatch()
    // const site = getSite()
    const { handleDisconnectDomain, navigateDashboardPage, domainData } = props;
    const domainSelector = useSelector((state) => state.domain )
    const customDomain = domainData?.data?.domain?.replace(/(^\w+:|^)\/\//, '')
    const status = domainSelector?.data?.status

    useEffect(() =>{
        //dispatch(getDomainData(site.id))
    },[  ])
    return (
        <div className="domain-step domain-final-step">
            <div className="domain-final-step-wrap">
                <div className="domain-final-icon">
                    <img src={ finalStepIcon } />
                </div>
                { status === 'CONNECTED' ?
                    <div className="domain-cname-detail">
                        <h3>
                            {/*Current Configurations for custom domain <br />*/}
                            Connected  <br /><a href={ `https://${ customDomain }` } rel='noreferrer' target='_blank' >https://{customDomain}</a>
                        </h3>
                        <div className="domain-btns">
                            <Button variant="primary" onClick={ navigateDashboardPage }>Go to Dashboard</Button>
                            <ButtonLoader
                                className="orangeBtn"
                                button={ <Button variant="primary" className="orangeBtn" onClick={ handleDisconnectDomain } >Disconnect</Button> }
                                loadingText={ 'Disconnecting' }
                                loading={ domainData?.disconnectButtonLoading }
                            />
                        </div>
                    </div> : <div className="domain-connect-info">
                        <h4>Your custom domain will be connected within 24 hours!</h4>
                        <p>You should see your custom domain on your Dashboard when successful.</p>
                        <p>If you don’t see the update within 24 hours, please contact support in the bottom-right live chat icon and we’ll take care of it!</p>
                        <Button variant="primary" onClick={ navigateDashboardPage }>Go to Dashboard</Button>
                    </div>}
                {/*<div className="domain-cname-table">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Type</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            SSL Key Name
                                        </td>
                                        <td>
                                            CNAME
                                        </td>
                                        <td>
                                            Key Value
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Custom Domain Name
                                        </td>
                                        <td>
                                            CNAME
                                        </td>
                                        <td>
                                            CloudFront URL
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
    </div>*/}

                {/* { isSiteUp === 0 ? <Skeleton circle={ true } className='skelton-img' width={ 120 } height={ 120 } /> : <img src={ isSiteUp === 1 ? FinalStepIcon : FinalRocketIcon } alt="" width="184" className={ 'circleImg' }/>}
                    <div className={ 'text-center mt-2' }>
                        <h4 className={ 'stepTitle' }>
                            { isSiteUp === 0 ? <Skeleton width={ 300 }  /> :
                                (isSiteUp === 1 ?  'Conguratulation' : "We're checking your custom domain hosting status, please wait!")
                            }
                        </h4>
                        <h5 className={ 'mb-2' } data-tut="reactour_dash_webname">
                            Domain:&nbsp;
                            <a href={ `https://${ domainData?.data?.domain }` } rel="noreferrer" target='_blank' className={ `${ status ? 'success' : 'in-progress' }` }>
                                { domainData?.data?.domain } <TopRightArrow />
                            </a>
                        </h5>
                        <p>
                            { isSiteUp === 0 ? <Skeleton width={ 500 } count={ 3 }  /> :
                                (isSiteUp === 1  ? 'Your Domain is Succesfully Configured! Incase you face any issues please contact admin!' : 'Also please make sure the below mentioned SSL key & CDN Target URL is properly configured on your Domain Name Provider! Incase you face any issues please contact admin!')
                            }
                        </p>
                    </div>
                    <div className="domain-btns">
                        <Button variant="primary" onClick={ navigateDashboardPage }>Go to Dashboard</Button>
                        {/* <ButtonLoader
                            className="orangeBtn"
                            button={ <Button variant="primary" className="orangeBtn" onClick={ handleDisconnectDomain } >Disconnect</Button> }
                            loadingText={ 'Disconnecting' }
                            loading={ domainData?.disconnectButtonLoading }
                        />
                    </div>*/}
            </div>
        </div>
    )
}

FinalStep.propTypes = {
    currentStep: PropTypes.number,
    handleDisconnectDomain: PropTypes.func,
    navigateDashboardPage: PropTypes.func,
    domainData: PropTypes.object
};

export default FinalStep;