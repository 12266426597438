import React, { useEffect,useContext } from 'react';
import { Switch, Route,withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import UserProfilePage from './pages/user-profile';
import PlanSelectionPage from 'pages/plan';
import BillingPage from './pages/billing';
import DomainPage from './pages/domain';
import LoginPage from './pages/login';
import RegisterPage from './pages/register';
import TermsCondition from './pages/terms-conditions';
import PrivacyPolicy from './pages/privacy-policy';
import NotFoundPage from './pages/404';
import Dashboard from './pages/dashboard';
import RedirectAuth from './pages/oauth2/redirect';
import AssessmentPage from './pages/assessment';
import ForgetPasswordPage from './pages/forget-password';
import EditSitePage from './pages/edit-site';
import { ROUTES } from './constants/appRoutes';
import HomePage from './components/homepage';
import CreatePage from './components/edit-site/pages/CreatePage';
import Sites from './components/sites/';
import { PrivateRoute }  from './components/hoc/PrivateRoute';
import { PrivateRouteWOS }  from './components/hoc/PrivateRouteWOS';
import { AssessmentRoute } from './components/hoc/AssessmentRoute';
import { PublicRoute }  from './components/hoc/PublicRoute';
import ConfirmAccount from './pages/confirm-account';
import { ConfirmAccountRoute } from './components/hoc/ConfirmAccountRoute';
import Layout from './components/shared/Layout';
import { MixpanelContext } from 'utils/tracking';
import { EVENTS } from 'constants/app'
import './components/all.sass';
import './components/dashboard.sass';
import 'react-select-input/lib/react-select-input.css'
import { seo, isProductonEnv } from 'utils/helpers'
import AppRoutes from './AppRoutes'
const RegisterRoute = React.lazy(() => import('./components/hoc/RegisterRoute'));
const App = (props) => {
    var timeoutData = null;
    const mixpanel = useContext(MixpanelContext)
    const { location: { pathname } } = props
    const triggerEvent = () => {
        var element = document.querySelector('#crisp-chatbox .cc-unoo')
        if(typeof(element) != 'undefined' && element != null){
            clearInterval(timeoutData)
            element.onclick = function inputChange() {
                mixpanel.track(EVENTS.contactSupportOpen,{})
            }
        }
    }

    useEffect(() => {
        if(isProductonEnv()){
            const script = document.createElement('script');
            const script2 = document.createElement('script');
            script.src = 'https://plausible.io/js/plausible.js';
            script.async = true;
            script.defer = true;
            script.dataset[ 'domain' ] = 'wizrd.org'
            script2.type = 'application/ld+json'
            script2.innerHTML = JSON.stringify({ 'description': 'Wizrd is the easiest blogging platform for businesses to rank higher and get more organic traffic. Try for free today!','name':'Wizrd','@type':'WebSite','url':'https://wizrd.org/','image':'https://wizrd.org/wizard-seo.png','headline':'Wizrd: The Simplest Blogging Platform Made for SEO','@context':'https://schema.org' })
            document.head.appendChild(script);
            document.head.appendChild(script2);
            return () => {
                document.head.removeChild(script2);
                document.head.removeChild(script);
            }
        }
    },[])

    useEffect(() => {
        timeoutData =  setInterval(function(){
            triggerEvent()
        },1000)
        return () =>{
            clearInterval(timeoutData)
        }

    },[])

    useEffect(() => {
        seo({
            title: pathname === '/' ? '/home' : pathname ,
            metaDescription: pathname === '/' ? 'Home' : pathname,
            url: window.location.host
        })
        window.scrollTo(0, 0)

    },[ pathname ])

    const viewRoutes = () => {
        return AppRoutes.map((item,key) => {
            switch(item.type){
            case 'PublicRoute':
                return <PublicRoute key={ key } exact path={ item.path } component={ item.component } />
            case 'RegisterRoute':
                return <RegisterRoute  key={ key } path={ item.path } component={ item.component }/>
            case 'AssessmentRoute':
                return <AssessmentRoute key={ key } exact path={ item.path } component={ item.component } />
            case 'PrivateRoute':
                return <PrivateRoute key={ key } exact path={ item.path } component={ item.component } />
            case 'ConfirmAccountRoute':
                return <ConfirmAccountRoute key={ key } exact path={ item.path } component={ item.component } />
            case 'Route':
                return <Route exact key={ key } path={ item.path } component={ item.component } />
            case 'PrivateRouteWOS':
                return <PrivateRouteWOS key={ key } exact path={ item.path } component={ item.component } />

            }
        })
    }
    return (<Layout className="main-layout">
        <Switch>
            <PublicRoute exact path={ ROUTES.ROOT } component={ HomePage } />
            <PublicRoute path={ ROUTES.LOGIN } name="Login Page" component={ LoginPage }/>
            <RegisterRoute path={ ROUTES.REGISTER } name="Register Page" component={ RegisterPage }/>
            <AssessmentRoute exact path={ ROUTES.ASSESSMENT } component={ AssessmentPage } />
            <PublicRoute path={ ROUTES.FORGET_PASSWORD } name="Forget Password Page" component={ ForgetPasswordPage }/>
            <PublicRoute path={ ROUTES.AUTH_REDIRECT } name="Auth" component={ RedirectAuth }/>
            <PrivateRoute path={ ROUTES.DASHBOARD } component={ Dashboard } />
            <ConfirmAccountRoute  path={ ROUTES.CONFIRM_ACCOUNT } component={ ConfirmAccount } />
            <Route exact path={ ROUTES.TERMS_CONDITIONS } component={ TermsCondition } />
            <Route exact path={ ROUTES.PRIVACY_POLICY } component={ PrivacyPolicy } />
            <PrivateRoute exact path={ ROUTES.PAGE } component={ CreatePage } />
            <PrivateRoute exact path={ ROUTES.EDIT_SITE } component={ EditSitePage } />
            <PrivateRouteWOS exact path={ ROUTES.SITES } component={ Sites } />
            <PrivateRoute exact path={ ROUTES.EDIT_PAGE } component={ CreatePage } />
            <PrivateRouteWOS exact path={ ROUTES.PLAN_SELECTION } component={ PlanSelectionPage } />
            <PrivateRouteWOS exact path={ ROUTES.USER_PROFILE } component={ UserProfilePage } />
            <PrivateRouteWOS exact path={ ROUTES.BILLING } component={ BillingPage } />
            <PrivateRouteWOS exact path={ ROUTES.DOMAIN } component={ DomainPage } />
            {viewRoutes()}
            <Route exact component={ NotFoundPage }/>

        </Switch>
    </Layout>);
}

App.propTypes = {
    location:  PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }),
}
export default withRouter(App);
