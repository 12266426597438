import React from 'react';
import { Container } from 'react-bootstrap';
import { isTravelTemplate } from 'utils/helpers'
import './style.sass'
const LandingHero = ()=> {
    return (
        <section className="publisher-landing-hero">
            <Container>
                <div className="publisher-landing-hero-inner">
                    {isTravelTemplate() ? <h1>The easiest way for travel agents to sell online and <span>get more customers!</span></h1> : <h1>
                        The simpler, faster WordPress blog alternative that <span>ranks higher</span> and <span>gets more SEO traffic</span>
                    </h1>}
                    <p>
                        Faster setup in just seconds. Under 1-second load times. Zero maintenance.
                        Stop getting frustrated, start getting more SEO results easier.
                    </p>
                </div>
            </Container>
        </section>
    )
}

export default LandingHero;