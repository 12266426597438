import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';
import ButtonLoader from 'components/core/loader/button-loader';
import {
    CopyFormDataIcon
} from '../../../utils/svg';
import goDaddyExampleDev from 'images/custom-domain/cdomain-gd-ip-dev.png';
import goDaddyExampleProd from 'images/custom-domain/cdomain-gd-ip-prod.png';
import nameCheapExampleDev from 'images/custom-domain/cdomain-nc-ip-dev.png';
import nameCheapExampleProd from 'images/custom-domain/cdomain-nc-ip-prod.png';
const Step = (props) => {
    const { handleFormChange, handleFirstStep, isLoading, formData, domainError } = props;

    const handleCopy = (data) =>{
        navigator.clipboard.writeText(data)
    }

    return (
        <>
            <div className="domain-step domain-step-1">
                <div className="domain-step-wrap">
                    <div className="domain-step-content">
                        <h3 className="stepTitle">Step 1: Add an “A Record” to Domain Account</h3>
                        <div>
                            <ul className="list-step">
                                <li>Login to your domain provider (GoDaddy, Namecheap, etc)</li>
                                <li>Find the domain you want to use, then click &quot;Manage Domain&quot;</li>
                                <li>Now find the &quot;DNS&quot; or &quot;Advanced DNS&quot; settings page (also called &quot;Manage Zones&quot;)</li>
                                <li><b>Need help?</b> Contact your Domain company&lsquo;s support and ask &quot;How to add a CNAME record to my domain?&quot; (or Google &quot;how to add cname record to &lt;insert domain company&gt;&quot;)</li>
                                <li>Click &quot;Add New Record&quot; or &quot;Add&quot; with the following info (scroll down to see examples at the bottom of this page):
                                </li>
                            </ul>
                            <div className="cname-example">
                                <div className="cname-eg-inputs">
                                    <Form.Group>
                                        <Form.Label>Type</Form.Label>
                                        <Form.Text className="cname-label">
                                            A
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Host</Form.Label>
                                        <Form.Control type="email" value="@" className="host_input" />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Text onClick={ () => handleCopy('@') } className="cname-copy">
                                            <span className="cname-copy-icon">
                                                <CopyFormDataIcon />
                                            </span>
                                            <span>Copy</span>
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Value</Form.Label>
                                        <Form.Control type="email" value={ process.env.REACT_APP_CUSTOM_DOMAIN_CNAME_VALUE } className="cname_value_input" />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Text onClick={ () => handleCopy(process.env.REACT_APP_CUSTOM_DOMAIN_CNAME_VALUE) }  className="cname-copy">
                                            <span className="cname-copy-icon">
                                                <CopyFormDataIcon />
                                            </span>
                                            <span>Copy</span>
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>TTL</Form.Label>
                                        <Form.Text className="cname-label">
                                            Leave as default
                                        </Form.Text>
                                    </Form.Group>
                                </div>
                            </div>
                            <ul className="list-step">
                                <li>Note: If you want to use a subdomain like &quot;blog.yourwebsite.com&quot;, replace the &quot;@&quot; in Host with &quot;blog&quot; or your desired subdomain</li>
                                <li>Save changes and enter your custom domain link in Step 2 below</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="domain-step-wrap">
                    <div className="domain-step-content">
                        <h3 className="stepTitle">Step 2: Enter custom domain name URL and Connect!</h3>
                        <div className="connect-domain-form">
                            <Form.Group className={ 'inputWithIcon' }>
                                <Form.Control onChange={ handleFormChange } name="domain" type="email" placeholder="Enter your custom domain here" value={ formData?.domain } />
                                <ButtonLoader
                                    button={ <Button variant="primary" disabled={ !formData?.domain || domainError } onClick={ handleFirstStep }>Connect</Button> }
                                    loadingText={ 'Connecting' }
                                    loading={ isLoading }
                                />
                            </Form.Group>
                            <Form.Text>
                                <span>
                                    Example: yourwebsitename.com, blog.yourwebsite.com
                                </span>
                            </Form.Text>
                            {domainError && <div className='field_error'>{domainError}</div>}
                        </div>
                        <div className="connect-domain-form">
                            <div style={ { position: 'relative',marginTop: '25px', paddingBottom: '56.25%', height: '0' } } >
                                <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/E6KzPQ73Nw8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="domain-step-example">
                <h3 className="stepTitle">Examples for Step 1</h3>
                <div className="domain-step-preview domain-step-preview-1">
                    <h5>GoDaddy</h5>
                    <div className="domain-step-preview-img">
                        <img src={ process.env.REACT_APP_CUSTOM_DOMAIN_CNAME_VALUE == '3.128.63.250' ? goDaddyExampleDev : goDaddyExampleProd  } />
                    </div>
                    {/* <Form.Group>
                        <Form.Label>Type</Form.Label>
                        <Form.Control as="select">
                            <option>CNAME</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Host</Form.Label>
                        <Form.Control type="email" value="@" className="host_input" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Value</Form.Label>
                        <Form.Control type="email" value="IB.webfin.co" className="cname_value_input" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>TTL</Form.Label>
                        <Form.Control as="select">
                            <option>1 Hour</option>
                        </Form.Control>
                    </Form.Group> */}
                </div>
                <div className="domain-step-preview domain-step-preview-2">
                    <h5>Namecheap</h5>
                    <div className="domain-step-preview-img">
                        <img src={ process.env.REACT_APP_CUSTOM_DOMAIN_CNAME_VALUE == '3.128.63.250' ? nameCheapExampleDev : nameCheapExampleProd  } />
                    </div>
                    {/* <table>
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Host</th>
                                <th>Value</th>
                                <th>TTL</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>CNAME</td>
                                <td>@</td>
                                <td>IB.webfin.co</td>
                                <td>Automatic</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                    <a>
                        Add new record
                    </a> */}
                </div>
            </div>
        </>
    )
}

Step.propTypes = {
    domainLoading: PropTypes.bool,
    handleFormChange: PropTypes.func,
    handleFirstStep: PropTypes.func,
    currentStep: PropTypes.number,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    domainError: PropTypes.string,
    formData: PropTypes.object
};

export default Step;