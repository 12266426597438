import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import{ Modal, Button,Form } from 'react-bootstrap';
import { Field } from 'redux-form';
import { renderStyleMultipleRadio } from 'utils/formUtils'
import CustomColor from 'components/assessment/shared/CustomColor'
import {  headerLinksTemplate } from 'utils/helpers'
import { AllColors } from 'constants/theme'
import { change as reduxChange } from 'redux-form'
import { useDispatch } from 'react-redux';
import ButtonLoader from 'components/core/loader/button-loader'

const ColourPalette = (props) => {
    const dispatch = useDispatch();
    const { colorPalette,customOpen,formName,previewFile,handleClose, setCustomPopup,setColorPalette, site, formValues , loading } = props
    const data = {
        header: formValues.header,
        colors: formValues.colors,
        logoUrl: formValues.logoUrl,
        logoText: formValues.websiteName,
        coverImage: formValues.coverImage,
        headerLinks: headerLinksTemplate(formValues?.menuLinks),
        readOnly: true
    }

    useEffect(() => {
        if(formValues.colors){
            setCustomPopup(JSON.parse(formValues.colors).name === 'custom-color' )
        }

    },[ formValues.colors ])
    const saveColorData = (colors) => {
        dispatch(reduxChange('assessmentUpdateForm', 'colors', JSON.stringify(colors)))
    }
    const handleColorsData = (colors) => {
        const obj = { label: 'Custom Color', value: colors,imageUrl: undefined }
        colorPalette.pop()
        colorPalette.push(obj)
        setColorPalette(colorPalette)
        saveColorData(colors)
        dispatch(reduxChange('assessmentUpdateForm', 'colors', JSON.stringify(colors)))
        handleClose()
    }
    const setCustomColor = () => {
        const obj = { label: 'Custom Color', value: JSON.parse(site?.colors),imageUrl: undefined }
        colorPalette.pop()
        colorPalette.push(obj)
        return colorPalette
    }
    const handleColorChange = ( event ) => {
        site && JSON.parse(site?.colors)?.name === 'custom-color' ? setColorPalette(setCustomColor()) : setColorPalette(AllColors())
        const obj = event.currentTarget.value &&  JSON.parse(event.currentTarget.value)
        obj.name === 'custom-color' ? setCustomPopup(!customOpen) : null
    }
    const handleClick = ( event ) => {
        const obj = event.currentTarget.value &&  JSON.parse(event.currentTarget.value)
        obj.name === 'custom-color' ? setCustomPopup(true) : null
    }

    return(
        <Form onSubmit={ () =>{ } }>
            {
                customOpen ? <CustomColor onBack={ (event) => {
                    event.preventDefault()
                    setCustomPopup(false)
                } } formName={ formName } previewFile={ previewFile }  backFun={ () => setCustomPopup(false) } colorPalette={ colorPalette } handleColorsData={ (colors) => handleColorsData(colors) } loading={ loading } isSubmit={ true } data={ data } />  : <>

                    <Modal.Header closeButton>
                        <div className="new-modal-header">
                            <Modal.Title>Color palette</Modal.Title>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="color-palatte">
                            <div className="color-palatte-selector">
                                <Field
                                    name="colors"
                                    options={ colorPalette || []  }
                                    component={ renderStyleMultipleRadio }
                                    defaultValue={ 'no' }
                                    formValues={ formValues?.colors || site?.colors }
                                    handleChange={ handleColorChange }
                                    handleClick={ handleClick }
                                    placeholder={ 'gaveCraving' }
                                    isColors={ true }
                                    className='styled-radio-btn btn-outline'
                                    imgWidth="30px"
                                />
                            </div>
                        </div>
                        <div className="modal-btns">
                            <ButtonLoader
                                button={ <Button type='button' onClick={ handleClose }  variant="primary">confirm</Button> }
                                loadingText='Saving'
                                loading={ loading }

                            />
                        </div>
                    </Modal.Body>

                </>}
        </Form>

    )
}
ColourPalette.propTypes = {
    formName: PropTypes.string,
    previewFile: PropTypes.string,
    onClose: PropTypes.func,
    assessmentData: PropTypes.object,
    colorPalette: PropTypes.array,
    formValues: PropTypes.func,
    setColorPalette: PropTypes.func,
    saveColorData: PropTypes.func,
    site: PropTypes.object,
    handleSubmit: PropTypes.func,
    handleClose: PropTypes.func,
    loading: PropTypes.func,
    customOpen: PropTypes.bool,
    setCustomPopup: PropTypes.func,
};
export default ColourPalette;