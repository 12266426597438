export const ActionTypes = {
    GET_DOMAIN_DATA_REQUEST: 'GET_DOMAIN_DATA_REQUEST',
    GET_DOMAIN_DATA_SUCCESS: 'GET_DOMAIN_DATA_SUCCESS',
    GET_DOMAIN_DATA_FAILURE: 'GET_DOMAIN_DATA_FAILURE',
    DISCONNECT_DOMAIN_REQUEST: 'DISCONNECT_DOMAIN_REQUEST',
    DISCONNECT_DOMAIN_SUCCESS: 'DISCONNECT_DOMAIN_SUCCESS',
    DISCONNECT_DOMAIN_FAILURE: 'DISCONNECT_DOMAIN_FAILURE',
    CONNECT_DOMAIN_REQUEST: 'CONNECT_DOMAIN_REQUEST',
    CONNECT_DOMAIN_SUCCESS: 'CONNECT_DOMAIN_SUCCESS',
    CONNECT_DOMAIN_FAILURE: 'CONNECT_DOMAIN_FAILURE',
}
