export const ActionTypes = {
    BLOG_CREATE: 'BLOG_CREATE',
    BLOG_CREATE_SUCCESS: 'BLOG_CREATE_SUCCESS',
    BLOG_CREATE_FAILURE: 'BLOG_CREATE_FAILURE',
    PUBLISH_REQUEST: 'PUBLISH_REQUEST',
    PUBLISH_SUCCESS: 'PUBLISH_SUCCESS',
    PUBLISH_FAILURE: 'PUBLISH_FAILURE',
    SOCIAL_MEDIA_REQUEST: 'SOCIAL_MEDIA_REQUEST',
    SOCIAL_MEDIA_SUCCESS: 'SOCIAL_MEDIA_SUCCESS',
    SOCIAL_MEDIA_FAILURE: 'SOCIAL_MEDIA_FAILURE',

    GET_BLOG_LIST: 'GET_BLOG_LIST',
    GET_DRAFT_BLOG_LIST_SUCCESS: 'GET_DRAFT_BLOG_LIST_SUCCESS',
    GET_PUBLISH_BLOG_LIST_SUCCESS: 'GET_PUBLISH_BLOG_LIST_SUCCESS',
    GET_BLOG_LIST_FAILURE: 'GET_BLOG_LIST_FAILURE',
    DELETE_BLOG_REQUEST: 'DELETE_BLOG_REQUEST',
    DELETE_BLOG_SUCCESS: 'DELETE_BLOG_SUCCESS',
    DELETE_BLOG_FAILURE: 'DELETE_BLOG_FAILURE',
    GET_BLOG_REQUEST: 'GET_BLOG_REQUEST',
    GET_BLOG_SUCCESS: 'GET_BLOG_SUCCESS',
    CLEAR_BLOG_FORM: 'CLEAR_BLOG_FORM',
    TOTAL_BLOGS_COUNT: 'TOTAL_BLOGS_COUNT',
    GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',
    GET_CATEGORIES_REQUEST: 'GET_CATEGORIES_REQUEST',
    GET_SEARCH_CATEGORIES_REQUEST: 'GET_SEARCH_CATEGORIES_REQUEST',
    GET_SEARCH_CATEGORIES_SUCCESS: 'GET_SEARCH_CATEGORIES_SUCCESS'
}
