import { ActionTypes } from '../actions/user/actionTypes'
const initialState = {
    user: {},
    sessionData: {},
    openfpmodal: false,
    loading: false,
    sites: [],
    siteMetaData: {},
    faqLoading: false,
    faqData: [],
    userProfileLoading: false
}
export default (state = initialState, action) => {
    switch (action.type) {
    case ActionTypes.LOGIN_REQUEST:
        return { ...state, loading: true };
    case ActionTypes.LOGIN_SUCCESS:
        return { ...state, user: action.payload, loading: false };
    case ActionTypes.LOGIN_FAILURE:
        return { ...state, error: action?.payload, loading: false };
    case ActionTypes.LOGOUT_SUCCESS:
        return { ...state, loading: false };
    case ActionTypes.REGISTRATION_REQUEST:
        return { ...state, loading: true };
    case ActionTypes.REGISTRATION_SUCCESS:
        return { ...state, user: action.payload, loading: false };
    case ActionTypes.REGISTRATION_FAILURE:
        return { ...state, error: action.payload, loading: false };
    case ActionTypes.EMAIL_VERIFICATION_SUCCESS:
        return { ...state, success: action.payload, loading: false };
    case ActionTypes.EMAIL_VERIFICATION_FAILURE:
        return { ...state, error: action.error, loading: false };
    case ActionTypes.RESEND_CODE_REQUEST:
        return { ...state, resendLoading: true }
    case ActionTypes.RESEND_CODE_SUCCESS:
        return { ...state, success: action.payload, loading: false, resendLoading: false };
    case ActionTypes.RESEND_CODE_FAILURE:
        return { ...state, error: action.error, loading: false, resendLoading: false };
    case ActionTypes.FORGET_PASSWORD_REQUEST:
        return { ...state, loading: true };
    case ActionTypes.FORGET_PASSWORD_SUCCESS:
        return { ...state, success: action.payload, loading: false, openfpmodal: action.openfpmodal };
    case ActionTypes.FORGET_PASSWORD_FAILURE:
        return { ...state, error: action?.payload, loading: false };
    case ActionTypes.GET_USER_SUCCESS:
        return { ...state, sessionData: action.payload, loading: false };
    case ActionTypes.UPDATE_USER_PROFILE_REQUEST:
        return { ...state, userProfileLoading: true };
    case ActionTypes.UPDATE_USER_PROFILE_SUCCESS:
        return { ...state, user: action.payload, userProfileLoading: false };
    case ActionTypes.UPDATE_USER_PROFILE_FAILURE:
        return { ...state, error: action.payload, userProfileLoading: false };
    case ActionTypes.DOMAIN_STATUS_SUCCESS:
        return { ...state, status: action.response.data.status }

    case ActionTypes.GET_STATS_REQUEST:
        return { ...state, statsLoading: true }
    case ActionTypes.GET_STATS_SUCCESS:
        return { ...state, statsLoading: false, statsData: action.payload }
    case ActionTypes.GET_STATS_ERROR:
        return { ...state, statsLoading: false, statsData: { subscribersPercentageChange: '',viewsPercentageChange: '' } }
    case ActionTypes.GET_FAQS_REQUEST:
        return { ...state, faqLoading: true }
    case ActionTypes.GET_FAQS_SUCCESS:
        return { ...state, faqLoading: false, faqData: action.payload.faqs }
    case ActionTypes.GET_FAQS_ERROR:
        return { ...state, faqLoading: false }
    case ActionTypes.DASHBOARD_TUTORIAL_VIDEO:
        return { ...state, openTutorial: action.open }
    default:
        return state;
    }
};