import React from 'react';
import PropTypes from 'prop-types';
import './style.sass'
const Thead = (props)=> {
    return (
        <thead>
            <tr>
                <td>{ props.row1 }</td>
                <td>{ props.row2 }</td>
                <td>{ props.row3 }</td>
            </tr>
        </thead>
    )
}
Thead.propTypes = {
    row1: PropTypes.string,
    row2: PropTypes.string,
    row3: PropTypes.string,
};

export default Thead;