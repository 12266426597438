import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Modal, Button,Form } from 'react-bootstrap'
import ButtonLoader from 'components/core/loader/button-loader'
import UploadImageModal from 'components/assessment/shared/UploadImageModal'
import { useDispatch, useSelector } from 'react-redux';
import { getUnsplash } from 'middleware/assessments'
import { change as reduxChange } from 'redux-form'
import { headerLinksTemplate } from 'utils/helpers'
import blogBanner from 'images/blog-banner.png'
import ColorPicker from 'components/core/color-picker'
import WebTemplatesPreview from 'components/assessment/WebTemplatePreview'
import '../assessment/style.sass';
const HeaderCover = (props) => {
    const {  formValues, previewFile, loading, onClose } = props
    const isIpad = window.outerWidth <= 1024
    const [ activeCustomColor , setActiveCustomColor ] = useState(false)
    const [ hsl,  setHsl ] = useState(null)
    const [ hsv,  setHsv ] = useState(null)
    const [ colors ,setColors ] = useState(formValues?.colors && JSON.parse(formValues.colors) || { })
    const unsplashImages  = useSelector((state) => state.assessment.unsplashImages)
    const data = {
        colors: colors || formValues?.colors,
        header: formValues?.header,
        logoUrl: formValues?.logoUrl,
        logoText: formValues?.websiteName,
        coverImage: formValues?.coverImage,
        headerLinks: headerLinksTemplate(),
        readOnly: true
    }
    const [ header, setHeader ] = useState({ heading: data?.header?.heading , subHeading: data?.header?.subHeading  })
    const [ openImageModal, setOpenImage ] = useState(false)
    const dispatch = useDispatch()
    const disableBodyScroll = () => {
        const element =  document.querySelectorAll('.custom-color-modal .modal-content')[ 0 ]
        element.classList.add('stop-scroll')
    }
    useEffect(()=>{
        const query = 'cover'
        dispatch(getUnsplash('/photos',query))
    },[]);
    const getBase64 = (base64) => {
        dispatch(reduxChange('assessmentUpdateForm', 'coverImage', base64))
    }
    const handleSearch = (event) => {
        const query = event.target.value
        dispatch(getUnsplash('/photos',query))
    }
    const toggleImageModal = () => {
        setActiveCustomColor(false)
        setOpenImage(!openImageModal)
    }
    const handleRadio = (event) => {
        if(event.target.checked){
            const colorsData =  Object.assign({}, colors)
            colorsData[ event.target.name ] = event.target.value
            setColors(colorsData)
            dispatch(reduxChange('assessmentUpdateForm', 'colors', JSON.stringify(colorsData)))
        }
    }
    const clearImage = () => {
        dispatch(reduxChange('assessmentUpdateForm', 'coverImage', ''))
    }
    const handleHeaderChange = (event) => {
        const headerObj = { ...header }
        headerObj[ event.target.name ] = event.target.value
        setHeader(headerObj)
        dispatch(reduxChange('assessmentUpdateForm', 'header', headerObj))
    }
    const handleChange = (event,name) => {
        const colorsData =  Object.assign({}, colors)
        colorsData[ name ] = event.target.value
        setColors(colorsData)
        setActiveCustomColor(true)
        dispatch(reduxChange('assessmentUpdateForm', 'colors', JSON.stringify(colorsData)))
        dispatch(reduxChange('assessmentUpdateForm', 'coverImage', ''))

    }
    const handleChangeColor = (d) => {
        isIpad && disableBodyScroll()
        const colorsData =  Object.assign({}, colors)
        colorsData[ 'header-background' ] = d.hex
        setColors(colorsData)
        setHsl(d.hsl)
        setHsv(d.hsv)
        dispatch(reduxChange('assessmentUpdateForm', 'colors', JSON.stringify(colorsData)))
        dispatch(reduxChange('assessmentUpdateForm', 'coverImage', ''))

    }
    const handleClick = () => {
        setActiveCustomColor(true)
    }
    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    const element =  document.querySelectorAll('.custom-color-modal .modal-content')[ 0 ]
                    element.classList.remove('stop-scroll')
                }
            }
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ ref ]);
    }
    const radioView = (name,value1,value2) => {
        const colorsData =  Object.assign({}, colors)
        return(<div className="font-color-switcher">
            <div className={ `custom-radio white-color-font ${ colorsData[ name ] === value1 && 'active' }` }>
                <div className="radio-item">
                    <input type="radio" onChange={ handleRadio } value={ value1 } name={ name } id={ `${ name }-white` } checked={ colorsData[ name ] === value1 } required />
                    <label className="label-icon option-white" htmlFor={ `${ name }-white` }><span>A</span></label>
                </div>
            </div>
            <div className={ `custom-radio black-color-font ${ colorsData[ name ] === value2 && 'active' }` }>
                <div className="radio-item">
                    <input type="radio" onChange={ handleRadio } value={ value2 } name={ name } id={ `${ name }-black` } checked={ colorsData[ name ] === value2 } required />
                    <label className="label-icon option-black" htmlFor={ `${ name }-black` }><span>A</span></label>
                </div>
            </div>
        </div>)
    }
    return(
        <Form onSubmit={ () => {} }>
            <Modal.Header closeButton>
                <div className="new-modal-header">
                    <Modal.Title>Preview</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="custom-color-selection template-form-group">
                    <div className="custom-color-template-preview">
                        <div className='wizrd-blog-preview wizrd-inner-preview'>
                            <WebTemplatesPreview data={ data } />
                        </div>
                    </div>
                    <div className="custom-color-template-selection">
                        <div className='custom-color-palate header-text-edit'>
                            <Form.Group>
                                <label>Heading</label>
                                <input className="form-control" type='text' name='heading' defaultValue={ data && data.header?.heading }  onChange={ handleHeaderChange } />
                            </Form.Group>
                            <Form.Group>
                                <label>Tag Line</label>
                                <input className="form-control" type='text' name='subHeading' defaultValue={ data && data.header?.subHeading } onChange={ handleHeaderChange } />
                            </Form.Group>
                            <div className="font-color-selector">
                                <label>Header Text</label>
                                {radioView('header-color','#FFFFFF', '#000000')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="edit-site-hero-upload">
                    <h5>Choose an image OR color</h5>
                    <div className="hero-bgcolor-img">
                        <div className="hero-img">
                            <div className="cover-img-upload-select">
                                <p onClick={ toggleImageModal } className="preview-logo">
                                    <img src={ data.coverImage || previewFile || blogBanner } alt="cover"/>
                                </p>
                                <p className="upload-file">
                                    <a onClick={ toggleImageModal }>
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="6" width="2" height="14" rx="1" fill="#5540BF"/>
                                            <rect x="14" y="6" width="2" height="14" rx="1" transform="rotate(90 14 6)" fill="#5540BF"/>
                                        </svg>
                                        <span>Change Header Image</span>
                                    </a>
                                </p>
                                <UploadImageModal formName={ 'assessmentUpdateForm' } getBase64={ getBase64 } handleSearch={ handleSearch } clearImage={ clearImage } previewFile={ previewFile } fieldName={ 'coverImage' } unsplashImages={ unsplashImages } openModal={ openImageModal } handleToggleModal={ toggleImageModal } />
                            </div>
                        </div>
                        <div className="hero-bgcolor">
                            <div className="color-selector-group">
                                <label>
                                    Header background color
                                </label>
                                <div className="header-color-selector">
                                    <div onClick={ () => handleClick('header-background') } className={ `color-box-view ${ activeCustomColor ? 'active' : '' }` }>
                                        <span className="color-selector-preview" style={ { background: colors[ 'header-background' ] } }></span>
                                        <input
                                            type='text'
                                            onChange={ (event) => handleChange(event,'header-background') }
                                            className='form-control'
                                            defaultValue={ colors[ 'header-background' ] }
                                            value={ colors[ 'header-background' ] }
                                        />
                                    </div>
                                </div>
                            </div>
                            {activeCustomColor && <ColorPicker useOutsideClick={ useOutsideClick }  active={ 'header-background' } obj={ { hsl: hsl,hsv: hsv } } colors={ colors } onChange={ handleChangeColor } />}
                        </div>
                    </div>
                </div>
                <div className="modal-btns">
                    <ButtonLoader
                        button={ <Button className="btn-primary-2" type='button' onClick={ onClose }  variant="primary">confirm</Button> }
                        loadingText='Saving'
                        loading={ loading }
                    />
                </div>
            </Modal.Body>
        </Form>)
}

HeaderCover.propTypes = {
    handleSubmit: PropTypes.func,
    onClose: PropTypes.func,
    formValues: PropTypes.object,
    previewFile: PropTypes.string,
    loading: PropTypes.boolean
};

export default HeaderCover