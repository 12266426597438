import { isObject } from 'lodash';
import axiosInstance from 'services/api';
import axios from 'axios'
const asyncValidate = async (value /*, dispatch */) => {
    if(value){
        const userName = isObject(value) ? value.userName : value;
        try{
            await axiosInstance.get(`/user/username?userName=${ userName }`)
            return true
        }catch(error){
            return false
        }
    }
}
const asyncValidatePageSlug = async(site,value) => {
    if(value){
        try{
            await axiosInstance.get(`site/${ site?.id }/pages/_check-slug/${ value }`)
            return  { status: true, message: null }
        }catch(error){
            return { status: false, message: error.response.data.message }
        }
    }
}

const asyncValidateBlogSlug = async(site, value) => {
    if(value){
        try{
            await axiosInstance.get(`site/${ site?.id }/posts/_check-slug/${ value }`)
            return { status: true, message: null }
        }catch(error){
            return { status: false, message: error.response.data.message }
        }
    }
}

const asyncValidateDomain = async(value) => {
    const origin = process.env.REACT_APP_WEBSITE_TYPE
    if(value){
        try{
            const result = await axiosInstance.get(`/check-domain?name=${ value }&origin=${ origin }`)
            return result.data
        }catch(error){
            return { available: false }
        }
    }
}
const asyncWildCardEmail= async(value) => {
    if(value){
        try{
            const response = await axios.get(`https://open.kickbox.com/v1/disposable/${ value }`)
            return !response.data.disposable
        }catch(error){
            return false
        }
    }
}
export {
    asyncValidate,
    asyncValidatePageSlug,
    asyncValidateDomain,
    asyncValidateBlogSlug,
    asyncWildCardEmail
}