import { ActionTypes } from '../actions/assessments/actionTypes'
const initialState = {
    assessmentData: {},
    loading: false,
    domainLoading: false,
    unsplashImages: [],
    domains: [],
    unsplashLoading: false,
    siteBuildLoading: false,
    siteSelectLoading: false,
    siteLoadingId: null,
    csvData: [],
    isCreating: false
}

export default (state = initialState, action) => {
    switch (action.type) {
    case ActionTypes.GET_ASSESSMENT_REQUEST:
        return { ...state, loading: true };
    case ActionTypes.GET_ASSESSMENT_SUCCESS:
        return { ...state, assessmentData: action.payload, loading: false };
    case ActionTypes.GET_ASSESSMENT_FAILURE:
        return { ...state, error: action.error, loading: false };
    case ActionTypes.CREATE_ASSESSMENT_REQUEST:
        return { ...state, loading: true, isCreating: true };
    case ActionTypes.CREATE_ASSESSMENT_SUCCESS:
        return { ...state, assessmentData: action.payload, loading: false , isCreating: false };
    case ActionTypes.CREATE_ASSESSMENT_FAILURE:
        return { ...state, error: action.error, loading: false ,updateAssessmentLoader: false, isCreating: false };
    case ActionTypes.GET_UNSPLASH_REQUEST:
        return { ...state , unsplashLoading: true ,unsplashImages: [] }
    case ActionTypes.GET_UNSPLASH_SUCCESS:
        return { ...state, unsplashImages: action.payload || [], unsplashLoading: false }
    case ActionTypes.GET_UNSPLASH_ERROR:
        return { ...state, unsplashImages: [], unsplashLoading: false }
    case ActionTypes.GET_VERIFIED_DOMAIN:
        return { ...state, domains: action.payload.domains, domainLoading: false }
    case ActionTypes.GET_VERIFIED_DOMAIN_REQUEST:
        return { ...state, domainLoading: true }
    case ActionTypes.UPDATE_ASSESSMENT_REQUEST:
        return { ...state, updateAssessmentLoader: true }
    case ActionTypes.UPDATE_ASSESSMENT_SUCCESS:
        return { ...state, updateAssessmentLoader: false }
    case ActionTypes.GET_VERIFIED_DOMAIN_ERROR:
        return { ...state, domains: [], domainLoading: false }
    case ActionTypes.CLEAR_DOMAINS:
        return { ...state, domains: [] }
    case ActionTypes.SITE_BUILD_REQUEST:
        return { ...state, siteBuildLoading: true }
    case ActionTypes.SITE_BUILD_FAILED:
        return { ...state, siteBuildLoading: false }
    case ActionTypes.SITE_BUILD_SUCCESS:
        return { ...state, siteBuildLoading: false }
    case ActionTypes.GET_ALL_SITES_REQUEST:
        return { ...state, loading: true }
    case ActionTypes.GET_ALL_SITES:
        return { ...state, sites: action.payload.sites || action.payload, siteMetaData: action.payload.metaData, loading: false }
    case ActionTypes.GET_ALL_SITES_FAILED:
        return { ...state, loading: false }
    case ActionTypes.GET_SITE_BY_ID_REQUEST:
        return { ...state, siteSelectLoading: true, siteLoadingId: action.payload }
    case ActionTypes.DOWNLOAD_CSV:
        return { ...state, csvData: action.payload }
    case ActionTypes.GET_SITE_BY_ID:
        return { ...state, site: action.payload.data ,siteSelectLoading: false, siteLoadingId: null }

    case ActionTypes.DELETE_SITE_REQUEST:
        return { ...state, loading: true }
    case ActionTypes.DELETE_SITE_SUCCESS:
        return  { ...state, loading: false }
    case ActionTypes.DELETE_SITE_FAILED:
        return { ...state, loading: false }

    case ActionTypes.REMOVE_ALL_SITES_REQUEST:
        return { ...state, loading: true }
    case ActionTypes.REMOVE_ALL_SITES_SUCCESS:
        return  { ...state, loading: false }
    case ActionTypes.REMOVE_ALL_SITES_FAILED:
        return { ...state, loading: true }
    default:
        return state;
    }
};