import { ActionTypes } from '../actions/page/actionTypes'
const initialState = {
    loading: false,
    isReadyPublish: false,
    connecting: false,
    socialMediaLinks: {},
    draftPages: [],
    publishPages: [],
    allPages: [],
    recentPages: [],
    page: null,
    status: null
}

export default (state = initialState, action) => {
    switch (action.type) {
    case ActionTypes.PAGE_CREATE_REQUEST:
        return { ...state, createLoading: true , status: action.payload,isReadyPublish: false };
    case ActionTypes.PAGE_CREATE_SUCCESS:
        return { ...state,createLoading: false,status: null, isReadyPublish: true };
    case ActionTypes.PAGE_CREATE_FAILURE:
        return { ...state, createLoading: false,status: null, isReadyPublish: false };
    case ActionTypes.PUBLISH_REQUEST:
        return { ...state, loading: true, isReadyPublish: false }
    case ActionTypes.PUBLISH_SUCCESS:
        return { ...state, loading: false, isReadyPublish: false }
    case ActionTypes.PUBLISH_FAILURE:
        return { ...state, loading: false, isReadyPublish: true }
    case ActionTypes.GET_PAGE_LIST:
        return { ...state, loading: true }
    case ActionTypes.GET_DRAFT_PAGE_LIST_SUCCESS:
        return { ...state, loading: false, draftPages: action.payload.pages, draftMetaData: action.payload.metaData }
    case ActionTypes.GET_PUBLISH_PAGE_LIST_SUCCESS:
        return { ...state, loading: false, publishPages: action.payload.pages , publishMetaData: action.payload.metaData }
    case ActionTypes.GET_PAGE_LIST_FAILURE:
        return { ...state, loading: false }
    case ActionTypes.GET_ALL_PAGES_REQUEST:
        return { ...state, loading: true }
    case ActionTypes.GET_ALL_PAGES_SUCCESS:
        return { ...state, loading: false, allPages: action.payload.pages }
    case ActionTypes.GET_ALL_PAGES_FAILED:
        return { ...state, loading: false }
    case ActionTypes.GET_PAGE_REQUEST:
        return { ...state, page: action.payload }
    case ActionTypes.GET_PAGE_SUCCESS:
        return { ...state, page: action.payload }
    case ActionTypes.DELETE_PAGE_REQUEST:
        return { ...state, loading: true }
    case ActionTypes.DELETE_PAGE_SUCCESS:
        return { ...state, loading: false  }
    case ActionTypes.DELETE_PAGE_FAILURE:
        return { ...state, loading: false  }
    case ActionTypes.CLEAR_PAGE_FORM:
        return { ...state, page: {} }
    case ActionTypes.GET_RECENT_PAGES_REQUEST:
        return { ...state, recentPageLoading: true, recentPages: [] }
    case ActionTypes.GET_RECENT_PAGES_SUCCESS:
        return { ...state, recentPageLoading: false, recentPages: action.payload.pages }
    case ActionTypes.GET_RECENT_PAGES_FAILED:
        return { ...state, recentPageLoading: false }

    default:
        return state;
    }
};
