import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { TopRightArrow } from '../../../utils/svg';
import connectDomainImg from 'images/custom-domain/connect-domain-img.png'

const Home = (props) => {
    const { site, initiateProcess, currentStep } = props;

    return (
        <div className="domain-step domain-step-begin">
            <div className="domain-status domain-0">
                <div className="domain-image">
                    <img src={ connectDomainImg } />
                </div>
                <div className="domain-name">

                    <h4>{site?.websiteName}</h4>
                    <a href={ `https://${ site?.customDomain || site?.domain }` } rel="noreferrer" target='_blank' className={ `${ status ? 'success' : 'in-progress' }` }>
                        { site?.customDomain || site?.domain } <TopRightArrow />
                    </a>
                </div>
                <div className="domain-btns">
                    <Button variant="primary" disabled={ currentStep > 0 } onClick={ initiateProcess }>+ Custom domain</Button>
                </div>
            </div>
        </div>
    )
}

Home.propTypes = {
    currentStep: PropTypes.number,
    site: PropTypes.object,
    initiateProcess: PropTypes.func,
    handleDisconnectDomain: PropTypes.func
};

export default Home;