import {
    getDraftPageListSuccess,
    getPublishPageListSuccess,
    getPageListFailed,
    getPagesRequest,
    getPageRequest,
    getPageSuccess,

    pageCreateRequest,
    pageCreateSuccess,
    pageCreateFailed,

    deletePageRequest,
    deletePageSuccess,
    deletePageFailed,

    getAllPublishPagesSuccess,
    getAllPagesFailed,

    getRecentPagesSuccess,
    getRecentPagesFailed,

    publishRequest,
    publishSuccess,
    publishFailed

} from '../actions/page';
import axiosInstance from '../services/api';
import { NOTIFICATION_TYPES, PAGE_STATUS } from '../constants/app';
import { notification } from '../services/notification';
import { ROUTES } from '../constants/appRoutes';
import history from '../utils/history'
import { dataURLtoFile , uId , getSite, getUser } from '../utils/helpers'
import { imageUpload } from './assessments'

export const getDraftPages =  (args) => {
    const site = getSite()
    return async(dispatch) => {
        try{
            dispatch(getPagesRequest())
            const result = await axiosInstance.get(`/publisher/site/${ site?.id }/pages?state=${ PAGE_STATUS.DRAFT }&${ args }`)
            if([ 200,203 ].includes(result.status)){
                dispatch(getDraftPageListSuccess(result.data));
            }
        }catch(error){
            dispatch(getPageListFailed(error))
            notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message);
        }
    }
}

export const getPublishedPages =  (args) => {
    const site = getSite()
    return async(dispatch) => {
        try{
            dispatch(getPagesRequest())
            const result = await axiosInstance.get(`/publisher/site/${ site?.id }/pages?state=${ PAGE_STATUS.PUBLISHED }&${ args }`)
            if([ 200,203 ].includes(result.status)){
                dispatch(getPublishPageListSuccess(result.data));
            }
        }catch(error){
            dispatch(getPageListFailed(error))
            notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message);
        }
    }
}
export const getAllPages =  () => {
    const site = getSite()
    return async(dispatch) => {
        try{
            dispatch(getPagesRequest())
            const result = await axiosInstance.get(`/site/${ site?.id }/pages`)
            if([ 200,203 ].includes(result.status)){
                dispatch(getAllPublishPagesSuccess(result.data));
            }
        }catch(error){
            dispatch(getAllPagesFailed(error))
            notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message);
        }
    }
}

export const getRecentPages = () => {
    const site = getSite()
    return async(dispatch) => {
        try{
            dispatch(getPagesRequest())
            const result = await axiosInstance.get(`/site/${ site?.id }/recent-pages`)
            if([ 200,203 ].includes(result.status)){
                dispatch(getRecentPagesSuccess(result.data));
            }
        }catch(error){
            dispatch(getRecentPagesFailed(error))
            notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message);
        }
    }
}
export const createPage = (domain,data,id, pageId, callback) => {
    return async(dispatch) => {
        const selectorData = getUser();
        dispatch(pageCreateRequest(data.status))
        if(data.imageUrl && !data.imageUrl.match('^(http|https)://')){
            const file = dataURLtoFile(data.imageUrl,uId()+'.png')
            data[ 'imageUrl' ] = await imageUpload(domain,'blog-images',file);
        }
        if(id){
            axiosInstance.patch(`/pages/${ id }`, data).then((response)=>{
                history.push(ROUTES.EDIT_SITE)
                callback && callback('Page Updated', { siteId: data?.siteId, status: data?.status, slug: data?.slug, title: data?.title, 'user identifier': selectorData?.id })
                dispatch(pageCreateSuccess(response))
            }).catch((error) => {
                callback && callback('Page Updation Failed', { siteId: data?.siteId, status: data?.status, slug: data?.slug, title: data?.title, 'user identifier': selectorData?.id })
                dispatch(pageCreateFailed(error))
                notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message);
            })
        }else{
            axiosInstance.post('/pages ', data).then((response)=>{
                history.push(ROUTES.EDIT_SITE)
                callback && callback('Page Created', { siteId: data?.siteId, status: data?.status, slug: data?.slug, title: data?.title, 'user identifier': selectorData?.id })
                callback &&  data?.status === 'PUBLISHED' && callback('Page Published Count',{ siteId: data?.siteId, status: data?.status, slug: data?.slug, title: data?.title, 'user identifier': selectorData?.id } )
                dispatch(pageCreateSuccess(response))
            }).catch((error) => {
                callback && callback('Page Creation Failed', { siteId: data?.siteId, status: data?.status, slug: data?.slug, title: data?.title, 'user identifier': selectorData?.id })
                dispatch(pageCreateFailed(error))
                notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message);
            })

        }
    };
};

export const getPageById =  (id) => {
    const site = getSite()
    return async(dispatch) => {
        dispatch(getPageRequest())
        axiosInstance.get(`/site/${ site?.id }/pages/${ id }`,{ headers: { edit: true } }).then((response) => {
            dispatch(getPageSuccess(response.data))
        }).catch(() => {
            history.push(ROUTES.BLOGS)
        })
    }
}

export const deletePage =  (id,draftArgs, publishArgs) => {
    return async(dispatch) => {
        try{
            dispatch(deletePageRequest())
            const result = await axiosInstance.delete(`/pages/${ id }`)
            if([ 200,203, 204 ].includes(result.status)){
                dispatch(deletePageSuccess())
                dispatch(getDraftPages(draftArgs))
                dispatch(getPublishedPages(publishArgs))
            }
        }catch(error){
            dispatch(deletePageFailed(error))
            notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message);
        }
    }
}

export const callPublish = (page,isPublish, publishArgs, draftArgs) => {
    return(dispatch) => {
        // eslint-disable-next-line camelcase
        const data = isPublish ? { status: PAGE_STATUS.PUBLISHED } : { status: PAGE_STATUS.DRAFT }
        dispatch(publishRequest())
        axiosInstance.patch(`/pages/${ page.id }`, data).then((response)=>{
            dispatch(getDraftPages(draftArgs))
            dispatch(getPublishedPages(publishArgs))
            dispatch(publishSuccess(response))
            history.replace('/edit-site')
        }).catch((error) => {
            dispatch(publishFailed(error))
            notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message);
        })
    }
}
