import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
// import RichTextEditor from './rte';
import { Field , change } from 'redux-form';
import { renderFieldWG } from '../../utils/formUtils'
import { getSite, getSlugFromPath,getTour, setTour, bodyScrollLock } from 'utils/helpers'
import {
    AddImgIcon,
} from '../../utils/svg';
import SEOSection from './seo-section'
import {
    Form,
    Button
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm,stopAsyncValidation, reset } from 'redux-form';
import PropTypes from 'prop-types';
import ListGroup from 'react-bootstrap/ListGroup';
import { useHistory } from 'react-router-dom';
import { getCurrentUser } from '../../middleware/auth'
import { createBlog , getBlogById } from '../../middleware/blog';
import { getUnsplash } from '../../middleware/assessments'
import { change as reduxChange } from 'redux-form'
import { trimStringLength ,changeTitleToSlug } from 'utils/helpers'
import { asyncValidateBlogSlug }  from 'utils/asyncValidate';
import { blogValidate as validate } from '../../utils/validates';
import UploadImageModal from '../../components/assessment/shared/UploadImageModal'
import ButtonLoader from 'components/core/loader/button-loader'
import { BLOG_STATUS ,MESSAGE  } from 'constants/app';
import { blogTour } from 'constants/tour-config'
import ShortAddImg from '../../images/add-img.png';
import ShortAddImg1 from '../../images/add1.png';
import ShortAddImg2 from '../../images/add2.png';
import ShortAddImg3 from '../../images/add3.png';
import ShortAddImg4 from '../../images/add4.png';
import Category from './category'
// import { Node } from 'slate'
import _ from 'lodash'
import { mixpanelCommonData } from 'utils/helpers'
import { SkeletonTheme } from 'react-loading-skeleton';
import Tour from 'reactour'
import { MixpanelContext } from 'utils/tracking';
import WsEditor from './ws-editor'
import draftToMarkdown from 'draftjs-to-markdown';
import { EditorState, convertToRaw  } from 'draft-js';

const BlogPage =(props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [ asyncLoad, setAsyncLoad ] =  useState(false)
    const [ seoDescription ,setSeoDescription ] = useState(null)
    const [ availableBlog, setAvailableBlog ] =  useState(false)
    const [ isTourOpen ,setTourOpen ] = useState(false)
    const [ errorMessageUrl, setErrorMessageUrl ] = useState(false)
    const [ errorMessageContent, setErrorMessageContent ] = useState(false)
    const [ seoErrorMessage, setSeoErrorMessage ] = useState({})
    const [ errorCategories, setCategoryError ] = useState(false)
    const [ openModal, setModalOpen ]  = useState(false);
    const blogForm = useSelector((state)=>state.form.blogForm)
    const loading = useSelector((state) => state.blog.createLoading )
    const statusLoading = useSelector((state) => state.blog.status )
    const blog = useSelector((state) => state.blog.blog)
    const unsplashImages  = useSelector((state) => state.assessment.unsplashImages)
    const id = getSlugFromPath(history.location.pathname)
    const [ isEditUrl, setEditUrl ] = useState(id ? false : true )
    const slugRef = useRef();
    const site = getSite()
    const [ editorState , setEditorState ] = useState(EditorState.createEmpty())
    const mixpanel = useContext(MixpanelContext);
    //const isReadyPublish = useSelector((state) => state.blog.isReadyPublish)

    const initialValue = [
        {
            type: 'paragraph',
            children: [
                { text: '' },
            ],
        }
    ]

    const { initialize } = props;

    const handleEvent = (eventName, data) => {
        mixpanel.track(eventName, mixpanelCommonData(data));
    }
    // const serialize = nodes => {
    //     return nodes.map(n => Node.string(n)).join('\n')
    // }
    const submitData = (formData ,status ) => {
        const obj = { ...seoErrorMessage }
        const rawData = draftToMarkdown(convertToRaw(editorState.getCurrentContent()))
        if (!formData.blogUrl) {
            setErrorMessageUrl(true);
            return;
        }
        if (!rawData.trim()) {
            setErrorMessageContent(true);
            return;
        }
        if(!formData?.seoDescription){
            obj[ 'description' ] = true
            setSeoErrorMessage(obj)
        }
        if(!formData?.seoTitle){
            obj[ 'title' ] = true
            setSeoErrorMessage(obj)
        }
        const data = {
            siteId: site?.id,
            type:'blog',
            content: formData.data ? formData.data : initialValue,
            imageUrl: formData.blogUrl,
            title: formData.title,
            slug: formData.slug,
            categories: formData?.categories,
            seoTitle: formData?.seoTitle,
            seoDescription: formData?.seoDescription,
            status: status
        }
        if (id && blog.title === formData.title) {
            delete data.title;
        }
        if(_.isEmpty(formData?.categories)){
            setCategoryError(MESSAGE.VALID_SELECT('category'))
        }else if(formData?.categories && _.isEmpty(formData?.categories.filter((item) =>item.isDefault ))){
            setCategoryError(MESSAGE.SET_DEFAULT_CATEGORY )
        }else{
            formData?.seoDescription && formData?.seoTitle && dispatch(createBlog(site?.domain, data,blog?.id, id, blog?.slug, blog?.status, handleEvent))
        }
    }

    useEffect(()=>{
        const timer1 = setTimeout(() => setTourOpen(getTour('newBlog')), 3.5*1000 );
        const query = 'blogs'
        if(id){
            dispatch(getBlogById(id, site?.id))
        }
        dispatch(getUnsplash('/photos',query))
        dispatch(getCurrentUser())
        dispatch({
            type: 'SET_ACTIVE_SIDEBAR',
            payload: '/blog-content'
        })
        return () => {
            clearTimeout(timer1)
            dispatch(reset('blogForm'))
            dispatch({
                type: 'CLEAR_BLOG_FORM'
            })
        }
    },[])

    // const [ , setRTEData ] = useState(initialValue)
    const [ rteLoading, setRteLoading ] = useState(false)
    console.log(rteLoading)
    const asyncSlugValidate = (value) =>{
        asyncValidateBlogSlug(site, value ).then((result) => {
            if(!result.status){
                setEditUrl(true)
                dispatch(stopAsyncValidation('blogForm', { slug: result.message }))
                setAvailableBlog(false)
            }else{
                setEditUrl(false)
                setAvailableBlog(true)
            }
            setAsyncLoad(false)
        })
    }

    useEffect(() => {
        if(blog && blog.slug) {
            setRteLoading(true)
            blog[ 'blogUrl' ] = blog.imageUrl;
            blog[ 'data' ] = (blog.content);
            delete blog.imageUrl;
            initialize(blog);
            !id && asyncSlugValidate(blog?.slug)
            setTimeout(() => {setRteLoading(false)})
        }
    },[ blog ])
    // const handleRTEdata = (data) => {
    //     let content = data;
    //     if (data && data.length === 1 && !data[ 0 ].text ) {
    //         content = data[ 0 ].children[ 0 ].text?.trim() === '' ? '' : data
    //     }
    //     if(data && !id){
    //         var str = serialize(data)
    //         str = str === '' ?  data[ 0 ]?.children[ 0 ]?.text  : str
    //         str = trimStringLength(str,230)
    //         str = str.trim()
    //         setSeoErrorMessage( { description: false } )
    //         setSeoDescription(str)
    //     }
    //     setErrorMessageContent(_.isEmpty(data))

    //     dispatch(change('blogForm', 'data', content))
    // }
    const handleRTEdata = (data,str) => {
        if(data && !id){
            setSeoErrorMessage( { description: false } )
            setSeoDescription(trimStringLength(str,230))
        }
        setErrorMessageContent(_.isEmpty(data))

        dispatch(change('blogForm', 'data', data))
    }
    useEffect(() => {
        if(blogForm?.values?.seoDescription === undefined || blogForm?.values?.seoDescription !== seoDescription ){
            dispatch(change('blogForm', 'seoDescription', seoDescription))
        }
    },[ seoDescription  ])
    const clearImage = () => {
        setErrorMessageUrl(false)
        dispatch(reduxChange('blogForm', 'blogUrl', null))
    }

    const handleToggleModal = (type) => {
        setModalOpen(!openModal)
        if(type !== 'confirm'){
            clearImage()
        }
    }
    const handleSearch = (event) => {
        const query = event.target.value || 'cat'
        dispatch(getUnsplash('/photos',query))
    }

    const getBase64 = (base64) => {
        setErrorMessageUrl(false)
        dispatch(reduxChange('blogForm', 'blogUrl', base64))
    }

    const capitalize = value => value.charAt(0).toUpperCase() + value.slice(1)
    const disableBody = () => {
        bodyScrollLock(true)
    }
    const enableBody = () => {
        bodyScrollLock(false)
    }

    const closeTour = () => {
        const tour = getTour()
        tour[ 'newBlog' ] = false
        setTour(tour)
        setTourOpen(false)
    }
    const handleChange = (value, blogData) => {
        const dd = id ? blogData?.slug !== value : true
        if(value && dd){
            setAsyncLoad(true)
            asyncSlugValidate(value)
        }else if(!value){
            dispatch(stopAsyncValidation('blogForm', { slug: 'Required' }))
        }
    }
    const saveData = () => {
        if(blogForm?.values?.slug){
            setEditUrl(!availableBlog)
            slugRef.current?.focus()
            const validSlug = id ? blog.slug !== blogForm?.values?.slug : true
            !validSlug && setEditUrl(false)
            !availableBlog && validSlug && dispatch(stopAsyncValidation('blogForm', { slug: 'Blog post URL not available. Try another.' }))
        }else{
            setEditUrl(true)
            dispatch(stopAsyncValidation('blogForm', { slug: 'Required' }))
        }
    }

    const handleTitleChange = (value) => {
        dispatch(reduxChange('blogForm','seoTitle', trimStringLength(value,70)))
        dispatch(reduxChange('blogForm','slug', changeTitleToSlug(trimStringLength(value,70))))
        if(value){
            asyncSlugValidate(changeTitleToSlug(value))
        }
    }
    const asyncValidateFunc = _.debounce(handleChange, 800);
    const asyncChangeCallback = useCallback(asyncValidateFunc, []);

    const asyncValidateTitle = _.debounce(handleTitleChange, 800);
    const asyncChangeTitleCallback = useCallback(asyncValidateTitle, []);
    return(

        <main className="dashboard-data createNewBlog createNewPage">
            <Tour
                closeWithMask={ false }
                onRequestClose={ () => closeTour(false) }
                steps={ blogTour() }
                isOpen={ isTourOpen }
                maskClassName="mask"
                disableInteraction={ true }
                disableFocusLock={ true }
                onAfterOpen={ disableBody }
                onBeforeClose={ enableBody }
                className="helper"
                rounded={ 5 }
                update='5'
                updateDelay={ 5 }
            />
            <section data-tut='reactour__iso' className="dashboard-body">
                <SkeletonTheme color="lightGray">
                    <div className="blog-creation">
                        <Form onSubmit={ () => {} }>
                            <div className="blog-creation-head">
                                <div className="blog-creation-head-left">
                                    {/* <div className="blog-btns mb-3" data-tut="reactour__button">
                                    <ButtonLoader
                                        button={  <Button disabled={ isEditUrl } type='button' onClick={ () => !isEditUrl && submitData(blogForm?.values,BLOG_STATUS.DRAFT) } variant="outline-primary" className="mr-3">Save As Draft</Button> }
                                        loadingText={ 'Save As Draft' }
                                        visible={ statusLoading === BLOG_STATUS.DRAFT }
                                        loading={ loading }
                                        variant="outline-primary"
                                        className="mr-3"
                                    />
                                    <ButtonLoader
                                        button={  <Button disabled={ isEditUrl } type='button' onClick={ () => !isEditUrl && submitData(blogForm?.values,BLOG_STATUS.PUBLISHED) } variant="primary">Publish</Button> }
                                        loadingText={ 'Publish' }
                                        visible={ statusLoading === BLOG_STATUS.PUBLISHED }
                                        loading={ loading }
                                        className="mr-3"
                                    />
                                </div> */}
                                    <Form.Group data-tut='reactour__title' className="blog-title-group ">
                                        <Field
                                            name="title"
                                            component={ renderFieldWG }
                                            placeholder={ 'Blog post title' }
                                            normalize={ capitalize }
                                            handleChange={ asyncChangeTitleCallback }
                                        />
                                        <div className="blog-creation-author-box" data-tut="reactour__image">
                                            <h5>Featured image</h5>
                                            <div className="upload-feature-img-wrap">
                                                <div className="upload-feature-img" onClick={ handleToggleModal }>
                                                    <div className="innerChanges">
                                                        <AddImgIcon />
                                                        {(blogForm?.values?.blogUrl) || !_.isEmpty(blog) ? <img src={ (blogForm?.values?.blogUrl || blog.blogUrl) } /> : 'Add a photo OR choose from thousands of royalty-free images'}
                                                        {!blogForm?.values?.blogUrl &&  <ListGroup className={ 'addImgList' }>
                                                            <ListGroup.Item><img src={ ShortAddImg } alt="" /></ListGroup.Item>
                                                            <ListGroup.Item><img src={ ShortAddImg1 } alt="" /></ListGroup.Item>
                                                            <ListGroup.Item><img src={ ShortAddImg2 } alt="" /></ListGroup.Item>
                                                            <ListGroup.Item><img src={ ShortAddImg3 } alt="" /></ListGroup.Item>
                                                            <ListGroup.Item><img src={ ShortAddImg4 } alt="" /></ListGroup.Item>
                                                        </ListGroup>}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </Form.Group>
                                </div>
                                <div className="blog-categories right-side">
                                    <div className="dflexBox">
                                        <Category
                                            blogForm={ blogForm }
                                            defaultCategories={ blogForm?.values?.categories || [] }
                                            site={ site }
                                            setCategoryError={ setCategoryError }
                                            error={ errorCategories } />
                                        <div className="blog-btns mb-3" data-tut="reactour__button">
                                            {errorMessageUrl && <p><span className="field_error">Please insert image</span></p>}
                                            {errorMessageContent && <p><span className="field_error">Please insert content</span></p>}
                                            <ButtonLoader
                                                button={  <Button disabled={ isEditUrl } type='button' onClick={ () => !isEditUrl  && submitData(blogForm?.values,BLOG_STATUS.DRAFT) } variant="secondary" className="mr-3 btn-secondary">Save As Draft</Button> }
                                                loadingText={ 'Save As Draft' }
                                                visible={ statusLoading === BLOG_STATUS.DRAFT }
                                                loading={ loading }
                                                variant="secondary"
                                                className=""
                                            />
                                            <ButtonLoader
                                                button={  <Button disabled={ isEditUrl } type='button' onClick={ () => !isEditUrl && submitData(blogForm?.values,BLOG_STATUS.PUBLISHED) } variant="primary">Publish</Button> }
                                                loadingText={ 'Publish' }
                                                visible={ statusLoading === BLOG_STATUS.PUBLISHED }
                                                loading={ loading }
                                                className=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="blog-creation-content mt-2">

                                <div className="blog-editor" data-tut="reactour__rte">

                                    <WsEditor editorState={ editorState } setEditorState={ setEditorState }  formName={ 'blogForm' } blogFormData={ blogForm.values } readOnly={ false } handleRTEdata={ handleRTEdata } initialValue={ blog && (blog.content)  } />
                                    {/* {id && blog && blog.content &&  <RichTextEditor formName={ 'blogForm' } blogFormData={ blogForm.values } readOnly={ false } setRTEData={ handleRTEdata } initialValue={ blog && deserializeFromHtml(blog.content) || initialValue } /> }
                                    {!id && !rteLoading  &&  <RichTextEditor formName={ 'blogForm' } blogFormData={ blogForm.values } readOnly={ false } setRTEData={ handleRTEdata } initialValue={ blogForm?.values?.data || initialValue } />} */}
                                </div>

                            </div>

                            <UploadImageModal
                                formName={ 'blogForm' }
                                fieldName={ 'blogUrl' }
                                clearImage={ clearImage }
                                previewFile={ blogForm.values?.blogUrl }
                                getBase64={ getBase64 }
                                handleSearch={ handleSearch }
                                unsplashImages={ unsplashImages }
                                openModal={ openModal }
                                handleToggleModal={ handleToggleModal }
                            />
                        </Form>
                    </div>
                </SkeletonTheme>

                <SEOSection slugRef={ slugRef } id={ id } isEditUrl={ isEditUrl } blog={ blog } saveData={ saveData } asyncLoad={ asyncLoad } asyncChangeCallback ={ asyncChangeCallback } setSeoErrorMessage={ setSeoErrorMessage }site={ site } seoErrorMessage={ seoErrorMessage }  blogForm ={ blogForm } />
            </section>
        </main>

    )
}

BlogPage.propTypes = {
    handleSubmit: PropTypes.func,
    initialize: PropTypes.object
};
String.prototype.toUsername = function(){
    return this?.split('@') && this?.split('@')[ 0 ];
}
export default reduxForm({
    form: 'blogForm',
    validate,
    asyncValidateBlogSlug,
    asyncChangeFields: [ 'slug' ]
})(BlogPage);
