export const loadCachedItem = async (key) => {
    return await sessionStorage.getItem(key)
}

export const cacheItem = async (key, item) => {
    await sessionStorage.setItem(key, item)
}

export const setItem = (key, item) => {
    sessionStorage.setItem(key, JSON.stringify(item));
}

export const removeItem = key => {
    sessionStorage.removeItem(key);
}

export const getItem = key => {
    return sessionStorage.getItem(key);
}
export const getItemParse = key => {
    return sessionStorage.getItem(key) && JSON.parse(sessionStorage.getItem(key));
}

export const loadLocalCachedItem = async (key) => {
    return await localStorage.getItem(key)
}

export const cacheLocalItem = async (key, item) => {
    await localStorage.setItem(key, item)
}

export const setLocalItem = (key, item) => {
    localStorage.setItem(key, JSON.stringify(item));
}

export const removeLocalItem = key => {
    localStorage.removeItem(key);
}

export const getLocalItem = key => {
    return localStorage.getItem(key);
}
export const getLocalItemParse = key => {
    return localStorage.getItem(key) && JSON.parse(localStorage.getItem(key));
}