import React, { useEffect, useState } from 'react'
import { reduxForm, Field } from 'redux-form';
import { renderFieldWG } from '../../utils/formUtils'
import ButtonLoader from 'components/core/loader/button-loader'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types';
import { updateAssessment , getSiteById } from 'middleware/assessments'
import { getSite, headerLinksTemplate } from 'utils/helpers'
import { MESSAGE } from 'constants/app'
import blogBanner from 'images/blog-banner.png'
import { DEFAULT_TRAVEL_SCRIPT } from 'constants/app'
import WebTemplatePreview from 'components/assessment/WebTemplatePreview'
// import withPublicRoute from '../../components/hoc/withPublicRoute'
import
{
    Row,
    Form,
    Button

} from 'react-bootstrap';

const ScriptInject = (props) => {
    const { siteData, loading } = props
    const site = getSite()
    const dispatch = useDispatch()
    const form = useSelector(state => state.form.scriptInjectForm)
    const [ isSave, setSave ] = useState()

    const { handleSubmit, initialize  } = props

    useEffect(() => {
        dispatch({
            type: 'SET_ACTIVE_SIDEBAR',
            payload: '/inject-script'
        })
        initialize({
            headerScript: DEFAULT_TRAVEL_SCRIPT.script1,
        })
        dispatch(getSiteById(getSite()?.id))
        return () => {
            // const element = document.getElementById('widgetWrap')
            // document.getElementById('script1')?.remove()
            // document.getElementById('script2')?.remove()
            // if(element) element.innerHTML = ''
        }
    },[])

    const handleChangeMainScript = (value) => {
        console.log(value)
        // document.getElementById('script1')?.remove()
        // document.getElementById('widgetWrap').innerHTML = ''
        // if(value && value.match(/<[^>]*script/gi) ){
        //     const wrapper = document.querySelector('#root');
        //     const range = document.createRange();
        //     range.setStart(wrapper, 0);
        //     wrapper.appendChild(
        //         range.createContextualFragment(`<div id='script1'>${ value  }</div>`)
        //     )
        // }
    }
    const handleChangeScript = (value) => {
        console.log(value)
        // document.getElementById('script2')?.remove()
        // document.getElementById('widgetWrap').innerHTML = ''
        // if(value && value.match(/<[^>]*script/gi)){
        //     const wrapper = document.querySelector('#root');
        //     const range = document.createRange();
        //     range.setStart(wrapper, 0);
        //     wrapper.appendChild(
        //         range.createContextualFragment(`<div id='script2'>${ value }</div>`)
        //     )
        // }
    }
    const getSiteUrl = (data) => {
        // eslint-disable-next-line no-useless-escape
        const urls =  data?.match(/([\w+]+\:\/\/)?([\w\d-]+\.)*[\w-]+[\.\:]\w+([\/\?\=\&\#\.]?[\w-]+)*\/?/gm)
        return urls && urls[ 0 ]  || null
    }
    useEffect(() => {
        //var timeout = null
        initialize({
            headerScript: siteData?.templateData?.headerScript || DEFAULT_TRAVEL_SCRIPT.script1,
            siteUrl: getSiteUrl(siteData?.templateData?.bodyScript)
        })
        // handleChangeMainScript(siteData?.templateData?.headerScript)
        // timeout = setTimeout(() => {
        //     handleChangeScript(siteData?.templateData?.bodyScript)
        //     clearTimeout(timeout)
        // },300)

    },[ siteData?.id ])

    useEffect(() => {
        if(isSave){
            setTimeout(() => {
                setSave(false)
            },1000)
        }
    },[ isSave ])
    const callback = () =>{
        setSave(true)
        //dispatch(siteBuild(site?.id,() => {}))
    }
    const submitData = (data) => {
        const templateData = { headerScript: DEFAULT_TRAVEL_SCRIPT.script1, bodyScript: DEFAULT_TRAVEL_SCRIPT.script2(data.siteUrl) }
        data[ 'templateData' ] = templateData
        dispatch(updateAssessment(site?.id,data, site?.domain,()=>{ }, ()=>{ }, callback ))
    }
    const data = {
        header: siteData?.header,
        colors: siteData?.colors,
        logoUrl: siteData?.logoUrl,
        logoText: siteData?.websiteName,
        coverImage: siteData?.coverImage || blogBanner,
        headerLinks: headerLinksTemplate(),
        readOnly: true
    }
    return(
        <main className="">
            <section className="inject-script">
                {/* <div className="dashboard-header">
                    <div className="dashboard-title">
                        <h1>Inject Script</h1>
                    </div>
                </div> */}
                <Row className="align-items-center inject-script-wrap">
                    <div className='col-md-5'>
                        <Form onSubmit={ handleSubmit(submitData) }>
                            <div className="form-group">
                                <Field
                                    name="headerScript"
                                    handleChange={ handleChangeMainScript }
                                    component={ renderFieldWG }
                                    placeholder={ 'Enter header script url' }
                                    disabled={ true }
                                />
                            </div>
                            <div className="form-group">
                                <Field
                                    name="siteUrl"
                                    handleKeyUp={ handleChangeScript }
                                    component={ renderFieldWG }
                                    placeholder={ 'Enter site Url' }
                                />
                                <br/>
                                { DEFAULT_TRAVEL_SCRIPT.script2(form?.values?.siteUrl) }
                                <br/>
                                <ButtonLoader
                                    loadingText={ 'Saving..' }
                                    button={ <Button type='submit' variant="primary">Save </Button> }
                                    loading={ loading }
                                />
                            </div>
                        </Form>
                    </div>
                    <div className='col-md-7'>
                        <div className='custom-color-template-preview'>
                            <div className=' wizrd-inner-preview  wizrd-blog-preview '>
                                { /*<WebTemplatePreview data={ data} noScriptImage={ true }>*/ }
                                <WebTemplatePreview data={ data } />
                            </div>

                        </div>
                    </div>
                </Row>
            </section>
        </main>)
}
ScriptInject.propTypes = {
    handleSubmit: PropTypes.func,
    initialize: PropTypes.object,
    siteData: PropTypes.object,
    loading: PropTypes.bool
};
function validate(values){
    const errors = {}
    if (!values.headerScript) {
        errors.headerScript = MESSAGE.VALID_ENTER('header script');
    }
    if (!values.bodyScript) {
        errors.bodyScript = MESSAGE.VALID_ENTER('bodyscript');
    }
    // if(values.bodyScript){
    //     try{
    //         JSON.parse(values.bodyScript)
    //     }catch(e){
    //         errors.bodyScript = e.message
    //         return errors
    //     }
    // }
    return errors;
}
export default reduxForm({
    form: 'scriptInjectForm',
    validate
})(ScriptInject);
