import axiosInstance from '../services/api';

import {
    getDomainDataRequest,
    getDomainDataSuccess,
    getDomainDataFailure,
    connectDomainRequest,
    connectDomainSuccess,
    connectDomainFailure,
    disconnectDomainRequest,
    disconnectDomainFailure,
    disconnectDomainSuccess
} from '../actions/domain';
import { notification } from 'services/notification';
import { NOTIFICATION_TYPES } from 'constants/app';

// GET /api/v1/user/sites/{id}/custom-domain
export const getDomainData = (id) => {
    return (dispatch) => {
        dispatch(getDomainDataRequest({ type: 'isLoading' }))
        axiosInstance.get(`/user/sites/${ id }/custom-domain`).then((response)=>{
            dispatch(getDomainDataSuccess({ type: 'isLoading', data: response.data }))
        }).catch((error) => {
            dispatch(getDomainDataFailure({ type: 'isLoading' }));
            notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message);
        })
    };
};

// POST /api/v1/user/sites/{id}/custom-domain?name=
export const connectDomain = (data, callback, callbackError) => {
    return (dispatch) => {
        dispatch(connectDomainRequest({ type: 'isStep1Loading' }))
        axiosInstance.post(`user/sites/${ data.id }/custom-domain?name=${ data.domain }`).then((response)=>{
            if (callback) {
                callback();
            }
            dispatch(connectDomainSuccess({ type: 'isStep1Loading', data: { ...response.data, domain: data.domain } }))
            dispatch(disconnectDomainSuccess({ type: 'disconnectButtonLoading' }));
        }).catch((error) => {
            callbackError && callbackError()
            dispatch(connectDomainFailure({ type: 'isStep1Loading' }));
            notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message );
        })
    };
};

// DELETE /api/v1/user/sites/{id}/custom-domain
export const disconnectDomain = (id, callback) => {
    return (dispatch) => {
        dispatch(disconnectDomainRequest({ type: 'disconnectButtonLoading' }))
        axiosInstance.delete(`user/sites/${ id }/custom-domain`).then((response)=>{
            dispatch(disconnectDomainSuccess({ type: 'disconnectButtonLoading' }));
            if (callback) {
                callback(response.data?.status);
            }
        }).catch((error) => {
            dispatch(disconnectDomainFailure({ type: 'disconnectButtonLoading' }));
            notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message);
        })
    };
};
