import { ActionTypes } from './actionTypes'

export const getAssessmentRequest = () => {
    return {
        type: ActionTypes.GET_ASSESSMENT_REQUEST,

    };
};
export const getAssessmentSuccess = (data) => {
    return {
        type: ActionTypes.GET_ASSESSMENT_SUCCESS,
        payload: data,
    };
};
export const getAssessmentFailure = (error) => {
    return {
        type: ActionTypes.GET_ASSESSMENT_FAILURE,
        error: error,
    };
};

export const createAssessmentRequest = () => {
    return {
        type: ActionTypes.CREATE_ASSESSMENT_REQUEST,

    };
};

export const createAssessmentSuccess = (data) => {
    return {
        type: ActionTypes.CREATE_ASSESSMENT_SUCCESS,
        payload: data,
    };
};
export const updateAssessmentRequest = () => {
    return {
        type: ActionTypes.UPDATE_ASSESSMENT_REQUEST,
    }
}
export const updateAssessmentSuccess = (data) => {
    return {
        type: ActionTypes.UPDATE_ASSESSMENT_SUCCESS,
        payload: data,
    };
};

export const createAssessmentFailure = (error) => {
    return {
        type: ActionTypes.CREATE_ASSESSMENT_FAILURE,
        payload: error,
    };
};
export const getUnsplashRequest = () => {
    return {
        type: ActionTypes.GET_UNSPLASH_REQUEST,
    };
}
export const getUnsplashSuccess = (data) => {
    return {
        type:  ActionTypes.GET_UNSPLASH_SUCCESS,
        payload: data
    }
}

export const getUnsplashError = (error) => {
    return {
        type:  ActionTypes.GET_UNSPLASH_ERROR,
        error: error
    }
}

export const getVerifiedDomainRequest = () => {
    return {
        type:  ActionTypes.GET_VERIFIED_DOMAIN_REQUEST,
    }
}

export const getVerifiedDomainSuccess = (data,name) => {
    return {
        type: ActionTypes.GET_VERIFIED_DOMAIN,
        payload: data,
        name: name
    }
}
export const getVerifiedDomainError = (error) => {
    return {
        type:  ActionTypes.GET_VERIFIED_DOMAIN_ERROR,
        error: error
    }
}

export const siteBuildRequest = () => {
    return {
        type: ActionTypes.SITE_BUILD_REQUEST
    }
}
export const siteBuildSuccess = () => {
    return {
        type: ActionTypes.SITE_BUILD_SUCCESS
    }
}
export const siteBuildFailed = () => {
    return {
        type: ActionTypes.SITE_BUILD_FAILED
    }
}

export const getAllSitesRequest = () => {
    return { type:  ActionTypes.GET_ALL_SITES_REQUEST }
}
export const getAllSitesSuccess = (response) => {
    return {
        type: ActionTypes.GET_ALL_SITES,
        payload: response
    }
}
export const getAllSitesFailed = () => {
    return {
        type: ActionTypes.GET_ALL_SITES_FAILED,
    }
}
export const getSiteByIdRequest = (response) => {
    return {
        type: ActionTypes.GET_SITE_BY_ID_REQUEST,
        payload: response
    }
}
export const getSiteByIdSuccess = (response) => {
    return {
        type: ActionTypes.GET_SITE_BY_ID,
        payload: response
    }
}

export const downloadCSVSuccess = (response) => {
    return{
        type: ActionTypes.DOWNLOAD_CSV,
        payload: response
    }
}

export const deleteAssessmentRequest = () => {
    return {
        type: ActionTypes.DELETE_SITE_REQUEST,
    }
}
export const deleteAssessmentSuccess = (response) => {
    return {
        type: ActionTypes.DELETE_SITE_SUCCESS,
        payload: { response }
    }
}
export const deleteAssessmentFailed = () => {
    return {
        type: ActionTypes.DELETE_SITE_FAILED,
    }
}

export const removeAllSitesRequest = () => {
    return {
        type: ActionTypes.REMOVE_ALL_SITES_REQUEST,
    }
}
export const removeAllSitesSuccess = (response) => {
    return {
        type: ActionTypes.REMOVE_ALL_SITES_SUCCESS,
        payload: { response }
    }
}
export const removeAllSitesFailed = () => {
    return {
        type: ActionTypes.REMOVE_ALL_SITES_FAILED,
    }
}
