import React from 'react';
import {
    Container,
} from 'react-bootstrap';
import Heading from '../../core/heading';
import SeoCircleChart from './seo-chart-circle';
import webFinLogo from '../../../images/header/wizrd-logo.svg';
import ChartSvg from './images/seo-graph.png'
import './style.sass'
//import './style.css'
const SeoCompare = ()=> {
    return (
        <section className="publisher-seo">
            <Container>
                <div className="publisher-seo-inner">
                    <Heading
                        className="dark-heading"
                        heading={ <>Dominate SEO by outperforming <span>millions</span> of websites</> }
                    />
                    <div className="publisher-seo-compare">
                        <div className="publisher-seo-wizrd publisher-seo-individual lightGreen">
                            <div className="publisher-seo-compare-name">
                                <img src={ webFinLogo } />
                            </div>
                            <div className="publisher-seo-chart">
                                {/* <div className="single-chart">
                                    <svg viewBox="0 0 36 36" className="circular-chart orange">
                                        <path className="circle-bg"
                                            d="M18 2.0845
                                            a 15.9155 15.9155 0 0 1 0 31.831
                                            a 15.9155 15.9155 0 0 1 0 -31.831"
                                        />
                                        <path className="circle"
                                            strokeDasharray="40, 100"
                                            d="M18 2.0845
                                            a 15.9155 15.9155 0 0 1 0 31.831
                                            a 15.9155 15.9155 0 0 1 0 -31.831"
                                        />

                                        <g x="18" y="18" style={ {'transform' : 'rotate(54deg)' } } className="my-group" >
                                            <circle className="my-circle" >
                                            </circle>
                                            <rect className="rect" x="18" y="18" ></rect>
                                        </g>
                                        <text x="18" y="20.35"  className="percentage">40%</text>
                                    </svg>
                                </div> */}
                                <SeoCircleChart
                                    color="green"
                                    count="100"
                                    lable="Performance"
                                />
                                <SeoCircleChart
                                    color="green"
                                    count="100"
                                    lable="Accessibilty"
                                />
                                <SeoCircleChart
                                    color="green"
                                    count="100"
                                    lable="Best Practice"
                                />
                                <SeoCircleChart
                                    color="green"
                                    count="100"
                                    lable="SEO"
                                />
                            </div>
                        </div>
                        <div className="publisher-seo-wizrd publisher-seo-individual lightRed">
                            <div className="publisher-seo-compare-name">
                                The Competition
                            </div>
                            <div className="publisher-seo-chart">
                                <SeoCircleChart
                                    color="red percent_56"
                                    count="56"
                                    lable="Performance"
                                />
                                <SeoCircleChart
                                    color="red percent_80"
                                    count="80"
                                    lable="Accessibilty"
                                />
                                <SeoCircleChart
                                    color="red percent_86"
                                    count="86"
                                    lable="Best Practice"
                                />
                                <SeoCircleChart
                                    color="red percent_83"
                                    count="83"
                                    lable="SEO"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="publisher-seo-performace">
                        <div className="performace-graph">
                            <img src={ ChartSvg } alt="" />
                        </div>
                        <div className="performace-detail">
                            <h4>Why Performance Matters</h4>
                            <ul>
                                <li>
                                    In a Google research study, <b>traffic drops by 20%</b> with every 0.5 second delay in search page generation time
                                </li>
                                <li>
                                    1 second delay in mobile load times can impact mobile conversions by <b>up to 20%</b>
                                </li>
                                <li>
                                    According to Google, as page load time goes from 1 to 6 seconds the probability of <b>bounce increases 106%</b>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default SeoCompare;