import React from 'react';
import {
    Container,
    Button
} from 'react-bootstrap';
import Heading from '../../../components/core/heading';
import PlanDetail from './planDetail';
import PropTypes from 'prop-types';
import './style.sass'
const LandingPricing = (props)=> {
    // eslint-disable-next-line react/prop-types
    const { redirectAssessment } = props
    const getHeading = () => <>Switch to annually to get  <strong>2 months FREE</strong></>;
    return (
        <section className="publisher-pricing">
            <Container>
                <div className="publisher-pricing-inner" id="publisher-pricing-inner">
                    <Heading
                        className="dark-heading"
                        heading={ <>Pricing</> }
                    />
                    <div className="publisher-pricing-boxes">
                        <PlanDetail subHeading={ getHeading() }/>
                    </div>
                    <div className="publisher-pricing-detail">
                        <h3>Set up your website now, pick a plan later</h3>
                        <h6>Try Wizrd FREE for 14-days. No Credit Card required.</h6>
                        <div className="publisher-pricing-action">
                            <Button onClick={ () => redirectAssessment() } className="btn-primary-2 home-btn">Try for Free</Button>
                            {/* <span>*No Credit Card Required</span> */}
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    )
}
LandingPricing.propTypes = {
    redirectAssessment: PropTypes.func
};
export default LandingPricing;