import { ActionTypes } from './actionTypes'

export const getTransactionsRequest = () => {
    return {
        type: ActionTypes.GET_TRANSACTIONS_REQUEST,

    };
};
export const getTransactionsSuccess = (data) => {
    return {
        type: ActionTypes.GET_TRANSACTIONS_SUCCESS,
        payload: data,
    };
};
export const getTransactionsFailure = (error) => {
    return {
        type: ActionTypes.GET_TRANSACTIONS_FAILURE,
        error: error,
    };
};
