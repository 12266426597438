import React, { useRef , useEffect } from 'react';
import PropTypes from 'prop-types';
import { CustomPicker } from 'react-color';
import {
    Hue,
    Saturation
} from 'react-color/lib/components/common';
import {
    CopyIcon,
} from '../../../utils/svg';
import { hexToHsl, hexToHsv } from 'utils/helpers'
export const MyPicker = (props) => {
    const { onChange,obj,active, hsl, hsv , colors, useOutsideClick } = props;
    const copyHex = () => {
        const color = document.getElementsByClassName('select-color-name')[ 0 ]
        color.select();
        color.setSelectionRange(0, 99999)
        document.execCommand('copy');
    }
    const hexObj = colors && colors[ active ]
    const hslObj = colors && hexToHsl(colors[ active ])
    const hsvObj = colors && hexToHsv(colors[ active ])
    const wrapRef = useRef(null)
    useOutsideClick(wrapRef);
    const removeScrollLock = () =>{
        const element =  document.querySelectorAll('.custom-color-modal .modal-content')[ 0 ]
        element?.classList?.remove('stop-scroll')
    }
    useEffect(() => {
        const elemSaturation = document.querySelector('.saturation-white')
        const elemHue = document.querySelector('.hue-vertical')

        elemSaturation.addEventListener('touchend',function(){
            removeScrollLock()
        })
        elemHue.addEventListener('touchend',function(){
            removeScrollLock()
        })
    },[])

    return (
        <div className='webfin-color-selector'>
            <div ref={ wrapRef } >
                <div className="webfin-color-selector-palete">
                    <Saturation hsl={ obj.hsl ||  hslObj || hsl } hsv={ obj.hsv || hsvObj  || hsv  }  onChange={ onChange } />
                </div>
                <div className="webfin-color-selector-line">
                    <Hue direction={ 'vertical' } hsl={ obj.hsl ||  hsvObj || hsl } onChange={ onChange } />
                </div>
            </div>

            <div className="webfin-color-name">
                <label>Hex:</label>
                <div className="webfin-color-copy">
                    <span className="color-selector-preview" style={ { background: colors[ active ] ||  hexObj } }></span>
                    <span><input type='text' className="select-color-name form-control" value={  colors[ active ] || hexObj } /></span>
                    <a onClick={ () => copyHex() } className="copy-color">
                        <CopyIcon />
                    </a>
                </div>
            </div>
        </div>
    );
};
MyPicker.propTypes = {
    obj: PropTypes.object,
    active: PropTypes.string,
    data: PropTypes.object,
    onChange: PropTypes.func,
    colors: PropTypes.object,
    useOutsideClick: PropTypes.func,
    hsl: PropTypes.any,
    hsv: PropTypes.any
}
export default CustomPicker(MyPicker);
