import { DASHBOARD_TOUR , BLOG_TOUR } from 'constants/app'
export const dashboardTour = ( handleEvents ) =>
{
    return [
        {
            selector: '[data-tut="reactour_dash_webname"]',
            content: DASHBOARD_TOUR[ 1 ]
        },
        {
            selector: '[data-tut="reactour_dash_authorbox"]',
            content: DASHBOARD_TOUR[ 2 ],
        },
        {
            selector: '[data-tut="reactour_dash_nextstep"]',
            content: DASHBOARD_TOUR[ 3 ],
        },
        {
            selector: '[data-tut="reactour_dash_views_subscriber"]',
            content: DASHBOARD_TOUR[ 4 ],
        },
        {
            selector: '[data-tut="reactour_dash_sitemenu"]',
            highlightedSelectors: [ '.dropdown-menu' ],
            content: DASHBOARD_TOUR[ 5 ],
            action: () => {
                handleEvents('last')
            }
        }
    ]
}
export const dashboardTourMobile = (handleEvents) =>{
    return[
        {
            selector: '[data-tut="reactour_dash_webname"]',
            content: DASHBOARD_TOUR[ 1 ]
        },
        {
            selector: '[data-tut="reactour_dash_authorbox"]',
            content: DASHBOARD_TOUR[ 2 ],
        },
        {
            selector: '[data-tut="reactour_dash_nextstep"]',
            content: DASHBOARD_TOUR[ 3 ],
        },
        {
            selector: '[data-tut="reactour_dash_views_subscriber"]',
            content: DASHBOARD_TOUR[ 4 ],
            action: () => {
                handleEvents('last')
            }
        }
    ]
}

export const blogTour = () => {
    return [
        {
            selector: '[data-tut="reactour__iso"]',
            content: BLOG_TOUR[ 1 ]
        },
        {
            selector: '[data-tut="reactour__title"]',
            content: BLOG_TOUR[ 2 ],
        },
        {
            selector: '[data-tut="reactour__image"]',
            content: BLOG_TOUR[ 3 ]
        },
        {
            selector: '[data-tut="reactour__rte"]',
            content: BLOG_TOUR[ 4 ]
        },
        {
            selector: '[data-tut="reactour__button"]',
            content: BLOG_TOUR[ 5 ]
        },
    ]
}