/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useContext } from 'react'
import { Link }  from 'react-router-dom'
import PropTypes from 'prop-types';

import {
    Container,
    Row,
    Col,
    Dropdown
}
    from 'react-bootstrap';
import webFinLogo from '../../images/header/wizrd-logo.svg';
import webFinwhiteLogo from '../../images/header/webFin-white-logo.svg';
import { isLoggedIn } from '../../utils/helpers'
import { logoutUser } from '../../middleware/auth';
import { useDispatch, useSelector } from 'react-redux'
import {
    ChevronRight,
    DashboardMenuIcon,
    BlogMenuIcon,
    EditSiteMenuIcon,
    MarketingMenuIcon,
    SupportMenuIcon,
    NotificationIcon,
    MobileDrawerArrowIcon,
    MobileDrawerCloseArrowIcon,
} from '../../utils/svg';
import { ROUTES } from 'constants/appRoutes';
import profilePic from 'images/user-avatar.png';
import history from 'utils/history';
import { MixpanelContext } from 'utils/tracking';
import { EVENTS } from 'constants/app'
const Navbar = (props) => {
    const dispatch  = useDispatch();
    const mixpanel = useContext(MixpanelContext)
    const isIpad = window.outerWidth <= 1024;
    const [ isSideBarActive, toggleSideBar ] = React.useState(true);
    const [ mobileHumberger, setMobileHumberger ] = useState(false)
    const data = useSelector(state => state.user.sessionData?.data?.data)
    const user =  data?.user
    const [ navBarActiveClass , setNavBarActiveClass ] = useState('')
    const btAction = (pathname) => {
        if(pathname === '/register'){
            return 'logo-right no-login';
        } else if(pathname === '/confirm-account'){
            return 'logo-right no-login';
        } else if(pathname === '/login'){
            return 'no-login';
        } else {
            return '';
        }

    }
    const logout = () => {
        dispatch(logoutUser())
    }
    const { pathname , mobilieHamberger , toggleMobileSideBar } = props
    const LinkView = () => {
        if(isLoggedIn()){
            return(
                <>
                </>
            )
        } else if(pathname !== '/forget-password' &&  pathname !== '/confirm-account'){
            return(
                <nav
                    className="navbar is-transparent"
                    role="navigation"
                    aria-label="main-navigation"
                >
                    <div
                        id="navMenu"
                        className={ `navbar-menu ${ navBarActiveClass }` }
                    >
                        <div className="">
                            <ul className="main-navigation">
                                <li>
                                    <a href='#publisher-pricing-inner' onClick={ () => {
                                        mixpanel.track(EVENTS.clickOnPricing,{})
                                    } } className=''>
                                        Pricing
                                    </a>
                                </li>
                                <li>
                                    <Link to="#" onClick={ () => {
                                        mixpanel.track(EVENTS.clickOnLogin,{})
                                        history.push(ROUTES.LOGIN)
                                    } }  className='btn btn-secondary btn-secondary-2'>
                                        login
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#" onClick={ () => {
                                        mixpanel.track(EVENTS.visitTryforFree,{})
                                        history.push(ROUTES.ASSESSMENT)
                                    } }  className='btn btn-primary btn-primary-2'>
                                        Try for free
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>

            )
        }
    }

    const handleMobileHumberger = () => {
        if(!localStorage.user){
            setMobileHumberger(!mobileHumberger)
        }else{
            mobilieHamberger()
        }
    }
    return (
        // <header className={ `main-header ${ btAction(pathname) } ${ !isIpad  ? 'toggle-sidebar' : toggleMobileSideBar && 'toggle-sidebar'  }` }>
        <header className={ `main-header ${ isLoggedIn() ? 'admin-header' : 'landing-header' } ${ btAction(pathname) } ${ !isIpad  ? 'toggle-sidebar' : toggleMobileSideBar && 'toggle-sidebar'  }` }>
            <Container>
                <Row className="header-top myAccount">
                    <Col className="header-logo">
                        <div className="navbar-brand">
                            <Link to={ '/' } className="navbar-item" title="Logo">
                                <img src={ webFinLogo } alt="WebFin" className="dark-logo" />
                                <img src={ webFinwhiteLogo } alt="WebFin" className="light-logo" />
                            </Link>
                        </div>
                    </Col>
                    <Col className="header-menu">
                        <div className={ `mobile-humberger ${ mobileHumberger ? 'change' : '' }` } onClick={ handleMobileHumberger }>
                            {/* <div className="bar1"></div>
                            <div className="bar2"></div>
                            <div className="bar3"></div> */}
                            <span className="close-icon">
                                <MobileDrawerArrowIcon />
                            </span>
                            <span className="open-icon">
                                <MobileDrawerCloseArrowIcon />
                            </span>
                        </div>
                        {LinkView()}
                    </Col>
                </Row>
            </Container>
        </header>
    )
}
Navbar.prototype = {
    pathname: PropTypes.string,
}
export default (Navbar)
