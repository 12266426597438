import { ActionTypes } from '../actions/card/actionTypes'
const initialState = {
    isLoading: false,
    cards: null,
    listLoading: false
}

const  setDefaultCard = (state, action)  => {
    const cards = state.cards
    const cardsData = cards.map((item) => ( Object.assign({}, item,{ primary: item.id === action.payload })))
    return { ...state, cards: cardsData, listLoading: false }
}
export default (state = initialState, action) => {
    switch (action.type) {
    case ActionTypes.GET_CARDS_REQUEST:
        return { ...state, listLoading: true }
    case ActionTypes.CREATE_CARD_REQUEST:
        return { ...state, isLoading: true }
    case ActionTypes.SET_DEFAULT_CARD_REQUEST:
        return { ...state, listLoading: true };
    case ActionTypes.GET_CARDS_SUCCESS:
        return { ...state, cards: action.payload, listLoading: false };
    case ActionTypes.CREATE_CARD_SUCCESS:
        return { ...state, cards: action.payload, isLoading: false };
    case ActionTypes.CREATE_CARD_FAILURE:
        return { ...state, isLoading: false }
    case ActionTypes.GET_CARDS_FAILURE:
    case ActionTypes.SET_DEFAULT_CARD_FAILURE:
        return { ...state, listLoading: false };
    case ActionTypes.SET_DEFAULT_CARD_SUCCESS:
        return setDefaultCard(state,action)
    default:
        return state;
    }
};
