import BlogPage from './pages/blog';
import BlogsPage from './pages/blogs';
import TravelAgency from './pages/travel-agency';
import { ROUTES } from './constants/appRoutes';
import { isTravelTemplate } from 'utils/helpers'
import _ from 'lodash'
var routes = []
const AppRoutes = isTravelTemplate() ? _.concat(routes,[ {
    path: ROUTES.TRAVEL_AGENCY,
    component: TravelAgency,
    type: 'PrivateRoute',
} ]) : _.merge(routes,[ {
    path: ROUTES.BLOG,
    component: BlogPage,
    type: 'PrivateRoute',
},{
    path: ROUTES.EDIT_BLOG,
    component: BlogPage,
    type: 'PrivateRoute',
},{
    path: ROUTES.BLOGS,
    component: BlogsPage,
    type: 'PrivateRoute',
} ]
)
export default AppRoutes;