import { ActionTypes } from './actionTypes';

export const loginRequest = () => {
    return {
        type: ActionTypes.LOGIN_REQUEST,
    };
};

export const loginSuccess = (data) => {
    return {
        type: ActionTypes.LOGIN_SUCCESS,
        payload: data,
    };
};

export const loginFailure = (error) => {
    return {
        type: ActionTypes.LOGIN_FAILURE,
        payload: error,
    };
};

export const logoutSuccess = (data) => {
    return {
        type: ActionTypes.LOGOUT_SUCCESS,
        payload: data,
    };
};

export const registrationRequest = () => {
    return {
        type: ActionTypes.REGISTRATION_REQUEST,

    };
};

export const registrationSuccess = (data) => {
    return {
        type: ActionTypes.REGISTRATION_SUCCESS,
        payload: data,
    };
};

export const registrationFailure = (error) => {
    return {
        type: ActionTypes.REGISTRATION_FAILURE,
        error: error,
    };
};

export const emailVerificationSuccess = (response) => {
    return {
        type: ActionTypes.EMAIL_VERIFICATION_SUCCESS,
        payload: response,
    };
};
export const emailVerificationFailure = (error) => {
    return {
        type: ActionTypes.EMAIL_VERIFICATION_FAILURE,
        error: error,
    };
};
export const resendCodeRequest = () => {
    return{
        type: ActionTypes.RESEND_CODE_REQUEST
    }
}
export const resentCodeSuccess = (response) => {
    return {
        type: ActionTypes.RESEND_CODE_SUCCESS,
        payload: response,
    };
};
export const resendCodeFailure = (error) => {
    return {
        type: ActionTypes.RESEND_CODE_FAILURE,
        error: error,
    };
};

export const forgetPasswordRequest = () => {
    return {
        type: ActionTypes.FORGET_PASSWORD_REQUEST,
    };
};

export const forgetPasswordSuccess = (data, step) => {
    return {
        type: ActionTypes.FORGET_PASSWORD_SUCCESS,
        payload: data,
        openfpmodal: step === 3
    };
};

export const forgetPasswordFailure = (error) => {
    return {
        type: ActionTypes.FORGET_PASSWORD_FAILURE,
        payload: error,
    };
};

export const getUserSuccess = (data) => {
    return {
        type: ActionTypes.GET_USER_SUCCESS,
        payload: data,
    };
};

export const updateUserProfileRequest = () => {
    return {
        type: ActionTypes.UPDATE_USER_PROFILE_REQUEST,

    };
};

export const updateUserProfileSuccess = (data) => {
    return {
        type: ActionTypes.UPDATE_USER_PROFILE_SUCCESS,
        payload: data,
    };
};

export const updateUserProfileFailure = (error) => {
    return {
        type: ActionTypes.UPDATE_USER_PROFILE_FAILURE,
        error: error,
    };
};

export const domainStatusSuccess = (response) => {
    return {
        type: ActionTypes.DOMAIN_STATUS_SUCCESS,
        response: response
    };
};

export const getStatsRequest = () => {
    return {
        type: ActionTypes.GET_STATS_REQUEST,

    };
};
export const getStatsSuccess = (data) => {
    return {
        type: ActionTypes.GET_STATS_SUCCESS,
        payload: data

    };
};
export const getStatsError = () => {
    return {
        type: ActionTypes.GET_STATS_ERROR

    };
};
export const getFaqsRequest = () => {
    return {
        type: ActionTypes.GET_FAQS_REQUEST,

    };
};
export const getFaqsSuccess = (data) => {
    return {
        type: ActionTypes.GET_FAQS_SUCCESS,
        payload: data

    };
};
export const getFaqsError = () => {
    return {
        type: ActionTypes.GET_FAQS_ERROR

    };
};
