import React from 'react';
import PropTypes from 'prop-types';

import './style.sass'
const KeyFeature = (props)=> {
    return (
        <div className={ props.className }>
            <div className="countPercentage">
                { props.percentage }
            </div>
            <div className="countDetail">
                { props.detail }
            </div>
        </div>
    )
}

KeyFeature.propTypes = {
    className: PropTypes.string,
    percentage: PropTypes.any,
    detail: PropTypes.string,
};

export default KeyFeature;