import React,{ useEffect, useCallback, useContext, useState } from 'react'
import { withRouter, useHistory, Link } from 'react-router-dom'
import PropTypes from 'prop-types';
import { getAllSites, getSiteById } from 'middleware/assessments/'
import { getCurrentUser } from 'middleware/auth'
import { PageViewLoader } from './site-loader'
import { Form, Button  } from 'react-bootstrap'
import { setItem } from 'utils/cache'
import SiteView from './view'
import { useSelector, useDispatch } from 'react-redux'
import { ROUTES } from 'constants/appRoutes';
import _ from 'lodash';
import Pagination from 'react-js-pagination';
import { reset } from 'redux-form';
import { SHARING_URLS } from 'constants/app'
import { mixpanelCommonData } from 'utils/helpers'
import { MixpanelContext } from 'utils/tracking';
import {
    SiteGridIcon,
    ExclamationIcon,
    SiteListIcon,
    //SiteDeleteIcon,
    //SiteCloneIcon,
} from 'utils/svg'
import searchIcon from '../../images/search.png';
import addNewAnimation from '../../images/add-new-animation.gif';
const Sites = (props) => {
    const dispatch = useDispatch()
    const history = useHistory();
    const [ activePage, setActivePage ] = useState(0)
    const [ listType, setListType ] = useState(false)
    const [ siteWarning, setSiteWarning ] = useState(false)
    const siteData = useSelector((state) => state.assessment.sites) || []
    const siteMetaData = useSelector((state) => state.assessment.siteMetaData)
    const data = useSelector((state) => state.user.sessionData.data?.data )
    const loading = useSelector((state) => state.assessment.loading )
    const mixpanel = useContext(MixpanelContext);
    const user = data?.user
    const limit = 5
    useEffect(() => {
        dispatch(reset('assessmentForm'));
        dispatch(getCurrentUser())
        dispatch(getAllSites(`?page=${ activePage }&size=${ limit }`));
        dispatch({
            type: 'SET_ACTIVE_SIDEBAR',
            payload: '/sites'
        })
    },[])

    const handleEvent = (eventName, eventData) => {
        mixpanel.track(eventName, mixpanelCommonData(eventData));
    }

    const handleRedirection = (event) => {
        localStorage.removeItem('site')
        event.preventDefault();
        handleEvent('Click on New Site', { email: user?.email, userName: user?.userName, 'user identifier': user?.id })
        history.push(ROUTES.ASSESSMENT);
    }

    const handleSearch = (event) => {
        const args  = event.target.value ? `?keyword=${ event.target.value }` : `?page=${ activePage }&size=${ limit }`
        dispatch(getAllSites(args));
    }

    const handlePageChange = (page) => {
        const args = `?page=${ page - 1 }&size=${ limit }`
        dispatch(getAllSites(args));
        setActivePage(page -1)
    }

    const asyncValidateFunc = _.debounce(handleSearch, 500);
    const asyncChangeCallback = useCallback(asyncValidateFunc, []);
    return(
        <main className="dashboard-data dashboard-edit-site-wrap dashboard-multiple-site">
            <section className="dashboard-body">
                <div className="dashboard-header site-header">
                    <div className="dashboard-actions">
                        <Form className="search-form">
                            <Form.Group controlId="formBasicEmail">
                                <Form.Control onChange={ asyncChangeCallback }  className="form-control" placeholder="Search" />
                            </Form.Group>
                            <Button className="btn-search" type="button">
                                <img src={ searchIcon } alt={ 'searchIcon' } />
                            </Button>
                        </Form>
                    </div>
                    <div className='action-btn'>
                        <Button onClick={ () => setListType(false) } className={ `grid-btn ${ !listType && 'active' }` }>
                            <SiteGridIcon />
                            Grid View
                        </Button>
                        <Button onClick={ () => setListType(true) } className={ `list-btn ${ listType && 'active' }` }>
                            <SiteListIcon />
                            List View
                        </Button>
                    </div>
                </div>
                <div className="dashboard-header site-filter-header">
                    <div className="dashboard-title">
                        {/* <h3>Pages</h3> */}
                    </div>
                </div>
                <div className="pages-listing website-listing">
                    { !listType ?

                        <ul>
                            <li className="add-new-page">
                                {/* {siteMetaData?.count >= user?.planDetails?.numberOfSites  && !isSubscribed(user?.status) ? */}
                                {siteMetaData?.count >= user?.planDetails?.numberOfSites  ?
                                    <a href='javascript:void' onClick={
                                        () => {
                                            setSiteWarning(true)
                                            handleEvent('Click on New Site: Your site creation limit exhausted', { email: user?.email, userName: user?.userName, 'user identifier': user?.id })
                                            setTimeout(() =>setSiteWarning(false),10000)
                                        }
                                    } className='btn btn-primary'>
                                        New Site

                                    </a> :
                                    <Link  to='#' onClick={ handleRedirection } className="btn btn-primary">
                                        New Site
                                    </Link>

                                }
                                {siteWarning && <span className='alert alert-danger'><ExclamationIcon />Your site creation limit exhausted. <Link to={ ROUTES.PLAN_SELECTION }>Please upgrade your plan</Link></span> }

                                <img src={ addNewAnimation } alt="" className="animation-gif" />
                                <p>
                                    Create a new site with the help of our smart assessment
                                </p>
                            </li>

                            { loading ? <PageViewLoader loading={ loading } /> : siteData.map((item, index) =>
                                <SiteView setActivePage={ setActivePage } key={ index } activePage={ activePage } limit={ limit } item={ item } { ...props } />
                            ) }
                        </ul>: <PageListView  setActivePage={ setActivePage } activePage={ activePage } limit={ limit } sites={ siteData } { ...props }  />
                    }

                </div>

            </section>
            {siteMetaData?.count > limit && <div className='blogs-pagination'>
                <Pagination
                    activePage={ activePage + 1 }
                    itemsCountPerPage={ limit }
                    totalItemsCount={ siteMetaData?.count }
                    pageRangeDisplayed={ 5 }
                    onChange={ handlePageChange }
                />
            </div>}
        </main>

    )
}

export const PageListView =( props ) => {
    const { sites, history, activePage,setActivePage, limit } = props
    const dispatch = useDispatch()
    const [ copySuccess, setCopySuccess ] = useState('');
    const [ toggleSite, setToggleSite ] = useState(null)
    const setSite = (event, item) => {
        event.preventDefault()
        dispatch(getSiteById(item.id,(response) => {
            if(response.status === 200){
                setItem('site',response.data)
                history.push(ROUTES.EDIT_SITE)
            }
        }))
    }
    const copyToClipBoard = (event, item) => {
        event.preventDefault();
        const domain = item?.customDomain || item?.domain;
        navigator.clipboard.writeText(`https://${ domain }`)
        setCopySuccess('Copied!');
        setTimeout(() => setCopySuccess(''), 1000);
    };
    const share = (type, event, item) => {
        event.preventDefault();
        const domain = item?.customDomain || item?.domain;
        window.open(`${ SHARING_URLS[ type ] } https://${ domain }`)
    }
    return (
        <div className="blog-custom-list-table page-custom-list-table">
            <div className="blog-custom-list">
                <div className="blog-list-header">
                    <div className="blog-list-column blog-list-title" >
                        Name
                    </div>
                    <div className="blog-list-column blog-list-domain" >
                        Domain
                    </div>
                    <div className="blog-list-column blog-list-status" >
                        Status
                    </div>
                </div>
                <div className="blog-custom-list-table-data">
                    { sites.map((item, index) => {
                        return(
                            <SiteView setActivePage={ setActivePage } activePage={ activePage } limit={ limit } setSiteListView={ setSite } toggleSite={ toggleSite }  setToggleSite={ setToggleSite }  share={ share } copyToClipBoard={ copyToClipBoard }  copySuccess={ copySuccess } setCopySuccess={ setCopySuccess } item={ item } key={ index } isListView={ true } />
                        )
                    })}
                    {_.isEmpty(sites) && <span> No websites avaliable </span>}

                </div>
            </div>
        </div>)
}

PageListView.propTypes = {
    sites: PropTypes.object,
    history: PropTypes.any,
    activePage: PropTypes.number,
    limit: PropTypes.number,
    setActivePage: PropTypes.func
}
Sites.propTypes = {
    submitData: PropTypes.func,
    handleSubmit: PropTypes.func,
    assessmentData: PropTypes.object,
    loading: PropTypes.bool,
    form: PropTypes.object
};
export default withRouter(Sites);