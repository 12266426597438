export const ROUTES = {
    ROOT: '/',
    LOGIN: '/login',
    SITES: '/sites',
    DASHBOARD: '/dashboard',
    REGISTER: '/register',
    ASSESSMENT: '/assessment',
    FORGET_PASSWORD: '/forget-password',
    TERMS_CONDITIONS: '/terms-conditions',
    PRIVACY_POLICY: '/privacy-policy',
    CONFIRM_ACCOUNT: '/confirm-account',
    AUTH_REDIRECT: '/oauth2/redirect',
    BLOG: '/blog',
    BLOGS: '/blog-content',
    PAGE: '/page',
    EDIT_BLOG: '/blogs/:id',
    EDIT_PAGE: '/pages/:id',
    EDIT_SITE: '/edit-site',
    USER_PROFILE: '/user-profile',
    PLAN_SELECTION: '/plan-selection',
    BILLING: '/billing',
    DOMAIN: '/domain',
    TRAVEL_AGENCY: '/travel-agency'
}