import React, { useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useHistory } from 'react-router-dom';

import { getUser, mixpanelCommonData } from 'utils/helpers';
import { getCards } from 'middleware/card';
import { createSubscription, updateSubscription } from 'middleware/subscription';
import AddPaymentMethod  from '../user-profile/components/addPaymentMethod';
import PlanDetail from 'components/homepage/planDetail';
import { MixpanelContext } from 'utils/tracking';

const PlanSelectionPage = () => {
    const dispatch = useDispatch();
    const [ open, setOpen ] = useState(false)
    const history = useHistory();
    const cards = useSelector((state)=>state?.card?.cards);
    const [ selectedPlan, setSelectedPlan ] = useState(null);
    const selectorData = getUser();
    const mixpanel = useContext(MixpanelContext);

    useEffect(() => {
        if (!cards) {
            dispatch(getCards());
        }
    }, []);

    const handleModal = () => {
        setOpen(!open)
    }
    const handleClose = () => {
        setOpen(false)
    }

    const handleEvent = (eventName, data) => {
        mixpanel.track(eventName, mixpanelCommonData(data));
    }

    const manageSubscriptionHandler = (plan = selectedPlan) => {
        const payoad = { planId: plan?.id };
        if (selectorData.subscription) {
            payoad.id = selectorData?.subscription?.id;
            dispatch(updateSubscription(payoad, history, handleEvent ));
        } else {
            dispatch(createSubscription(payoad, history, handleEvent ));
        }
        handleClose();
    }

    const planSelectionHandler = ( plan ) => {
        setSelectedPlan(plan);
        if (cards?.length) {
            manageSubscriptionHandler(plan);
        } else {
            handleModal();
        }
    }
    const getHeading = () => <>Switch to annually to get <strong>2 months FREE</strong></>;

    return(
        <main className="dashboard-data upgradePlanSection website-plan ">
            <section className="dashboard-body">
                <div className={ 'website-plan pt-0 pb-0 container' }>
                    <Row>
                        <Col className={ 'text-left upgradePlanText' }>
                            <h4 className="managePlanTitle">Manage plan</h4>
                        </Col>
                    </Row>
                    <PlanDetail planSelectionHandler={ planSelectionHandler } selectedPlanId={ selectorData?.subscription?.planId } secondHeading={ getHeading() } />
                </div>
                <Modal show={ open } onHide={ handleClose } className={ 'updateBill' }>
                    <AddPaymentMethod callbackEvent={ manageSubscriptionHandler } />
                </Modal>
            </section>
        </main>
    )
}

export default PlanSelectionPage