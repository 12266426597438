import { ActionTypes } from './actionTypes'

export const getPlansRequest = () => {
    return {
        type: ActionTypes.GET_PLANS_REQUEST,

    };
};
export const getPlansSuccess = (data) => {
    return {
        type: ActionTypes.GET_PLANS_SUCCESS,
        payload: data,
    };
};
export const getPlansFailure = (error) => {
    return {
        type: ActionTypes.GET_PLANS_FAILURE,
        error: error,
    };
};
