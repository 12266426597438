import React, { Suspense } from 'react';
import mixpanel from 'mixpanel-browser';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';

import App from './App';
import { MixpanelContext } from './utils/tracking';
import reportWebVitals from './reportWebVitals';
import configureStore from './store';
import browserHistory from './utils/history';
import StripProvider from 'components/stripe';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
// import NotificationService from './service/notification-service';

const store = configureStore();

mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY);

ReactDOM.render(
    <Provider store={ store }>
        <MixpanelContext.Provider value={ mixpanel }>
            <StripProvider>
                <ToastContainer
                    position="top-right"
                    autoClose={ 5000 }
                    hideProgressBar={ false }
                    newestOnTop={ false }
                    closeOnClick
                    rtl={ false }
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <Router history={ browserHistory }>
                    <Suspense fallback={ '' }>
                        <App />
                    </Suspense>
                </Router>
            </StripProvider>
        </MixpanelContext.Provider>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
