import React, { useState , useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types';
import { change as reduxChange } from 'redux-form'
import{ Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { sortableContainer, sortableElement , sortableHandle , arrayMove } from 'react-sortable-hoc';
import { getAllPages, getRecentPages } from 'middleware/page'
import Skeleton  from 'react-loading-skeleton';
import {
    SortIcon,
    //PlusIcon
} from '../../utils/svg';
import InputSelect from 'react-select-input'
import ButtonLoader from 'components/core/loader/button-loader'
const MenuLinks = (props) => {
    const dispatch = useDispatch()
    const allPages = useSelector((state) => state.page.allPages )
    const page = useSelector((state) => state.page )
    const [ loadingtype , setLoadingType ] = useState(false)
    const {  handleClose,validUrls,setLinkType,linkType, isValidMenu,handleRecentMenuLinks, loading,setMenuLinks,handleNewTab, menuLinks,handleChangeMenuUrl,handleChangeMenuName,isValid, addMenuLinks ,removeMenuLink } = props
    const getData = () => {
        return menuLinks[ linkType ]
    }
    useEffect(() =>{
        dispatch(getAllPages())
        dispatch(getRecentPages())
        setLinkType(0)
    },[])
    const setLinkTypeFun = (index,event) => {
        event?.preventDefault()
        if(event?.target?.id !== 'remove'){
            linkType !== index && setLoadingType(true)
            setLinkType(index)
        }
    }
    const addLinks = () =>{
        addMenuLinks()
        setLinkTypeFun(menuLinks.length)
    }
    useEffect(()=>{
        setLoadingType(false)
    },[ linkType ])
    const removeLinkTypeFun = (index,event) =>{
        event.preventDefault()
        setLoadingType(true)
        linkType === index && setLinkType(linkType-1)
        removeMenuLink(index)
        setLoadingType(false)
    }
    const DragHandle = sortableHandle(() => <SortIcon />)
    const onSortEnd = ({ oldIndex, newIndex })  => {
        let menuLinksCopy = [ ... menuLinks ]
        menuLinksCopy = arrayMove(menuLinksCopy, oldIndex, newIndex)
        setMenuLinks(menuLinksCopy)
    }

    const renderItem = ( item, index ) =>{
        return(
            <div className="menu-detail" style = { { zIndex: 100000 } }  >
                <div className="menu-detail-inner" onClick={ (event) =>  setLinkTypeFun(index,event) } >
                    <div className="menu-name" >
                        <span className="menu-drag">
                            <DragHandle />
                            <DragHandle />
                        </span>
                        <span>{ item?.name ? item?.name?.uppercase() : '...' }</span>
                    </div>
                    <div className="">
                        <a className="menu-action" href='#'  >edit</a>&nbsp;
                        {item.name !== 'home' && item.url !=='contact' && <a className="menu-action" id='remove' href='#' onClick={ (event) => removeLinkTypeFun(index,event) }>remove</a>}
                    </div>
                </div>
            </div>)
    }
    const SortableItem = sortableElement(({ item, indexData }) => renderItem(item, indexData ));
    const SortableContainer = sortableContainer(({ children }) => {
        return <ol>{children}</ol>;
    });

    const handleCloseMenu = () => {
        if(validUrls()  && isValidMenu()){
            dispatch(reduxChange('assessmentUpdateForm', 'menuLinks', menuLinks))
            handleClose()
        }
    }
    const skeltonData = () => {
        return(
            <>
                <li>
                    <Skeleton width='100%' count={ 1 } />
                </li>
                <li>
                    <Skeleton width='100%' count={ 1 } />
                </li>
            </>
        )
    }
    return(
        <div className="">
            <Form onSubmit={ () => {} }>
                <Modal.Header closeButton>
                    <div className="new-modal-header menu-edit-header">
                        <Row>
                            <Col className="col-6">
                                <Modal.Title>Site Menu</Modal.Title>
                            </Col>
                            <Col className="col-6">
                                {!isValid && <span className='alert alert-danger' style={ { fontSize: '13px' } }>Error: Name and url should be unique or can&apos;t blank</span>}
                            </Col>
                        </Row>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="edit-menu-listing">
                        <div className="menu-listing">

                            <SortableContainer style = { { zIndex: 100000 } } onSortEnd={ onSortEnd } useDragHandle>
                                {menuLinks.map((item, index) => (
                                    <li key={ index }  className={ `${ linkType === index ? 'active' :'' }` }>
                                        <div className="menu-order">{ index+1 }.</div>
                                        <SortableItem  helperClass='sortableHelper' key={ `item-${ index }` } indexData={ index } index={ index } item={ item } />
                                    </li>
                                ))}
                            </SortableContainer>

                            {menuLinks.length < 7 && <button className='add-icon-btn' type='button' onClick={ addLinks }>+</button>}
                        </div>

                        <div className="add-menu">
                            {!loadingtype &&
                            <Form>
                                <Form.Group>
                                    <Form.Label>Name</Form.Label>
                                    <input type='text'
                                        name='name'
                                        disabled={ getData()?.name === 'home' }
                                        onChange={ (event) => handleChangeMenuName(event,linkType,getData()?.url) }
                                        className='form-control ris-name'
                                        defaultValue={ getData()?.name }
                                        value={ getData()?.name  }

                                    />

                                </Form.Group>
                                <Form.Group className="url-control">
                                    <Form.Label>Link</Form.Label>
                                    <InputSelect
                                        className={ `input-select-menu-link ${ getData()?.name === 'home' ||  getData()?.url === 'contact' ? 'input-disabled' : '' }` }
                                        clearable={ false }
                                        onSelect={ (event) => handleChangeMenuUrl(event,linkType, 'click')  }
                                        onChange={ (event) => handleChangeMenuUrl(event,linkType, 'change') }
                                        options={ allPages.map((item) => ({ label: item.slug, value: item.slug })) || [] }
                                        autoFocus={ false }
                                        disableEnter={ getData()?.name === 'home' || getData()?.url === 'contact'  }
                                        value = { getData()?.url }
                                    />
                                </Form.Group>
                                { getData()?.name !== 'home' &&  <Form.Group className="url-control">
                                    <Form.Label>
                                        <div className="newtab_input">
                                            <input
                                                type='checkbox'
                                                checked={ getData()?.newTab }
                                                onChange={ (event) => handleNewTab(event,linkType) }
                                                name='fsad' />
                                            <span className="newtab_custom_input"></span>
                                        </div>
                                        Open link in a new tab</Form.Label>
                                </Form.Group>}
                            </Form>
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="menu-link-footer">
                        <div className="recent-pages">
                            <h4>Recent pages</h4>
                            <ul>
                                { page.recentPageLoading && skeltonData() }
                                { !page.recentPageLoading && page.recentPages && page.recentPages.map((item, index) => {
                                    return(<li
                                        className={ 'badge ' }
                                        onClick={ (event) => handleRecentMenuLinks(event,item) }  key={ index } >
                                        { item.title }
                                    </li>)
                                })}

                            </ul>
                        </div>
                        <div className="modal-btns text-right">
                            <ButtonLoader
                                button={ <Button type='button' onClick={ handleCloseMenu } variant="primary">confirm</Button> }
                                loadingText='Saving'
                                loading={ loading }

                            />
                        </div>
                    </div>
                </Modal.Footer>
            </Form>
        </div>

    )
}
String.prototype.uppercase = function () {
    return this.charAt(0).toUpperCase() + this.slice(1)
};
MenuLinks.propTypes = {
    setLinkType: PropTypes.func,
    linkType: PropTypes.object,
    handleRecentMenuLinks: PropTypes.func,
    handleNewTab: PropTypes.func,
    handleClose: PropTypes.func,
    handleSubmit: PropTypes.func,
    menuLinks: PropTypes.array,
    addMenuLinks: PropTypes.func,
    loadData: PropTypes.bool,
    assessmentData: PropTypes.object,
    handleChangeMenuName: PropTypes.func,
    handleChangeMenuUrl: PropTypes.func,
    loadText: PropTypes.bool,
    removeMenuLink: PropTypes.func,
    isValid: PropTypes.bool,
    loading: PropTypes.bool,
    setMenuLinks: PropTypes.func,
    validUrls: PropTypes.func,
    isValidMenu: PropTypes.func
};
export default MenuLinks;