import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import propTypes from 'redux-form/lib/propTypes';

const StripProvider = (props) => {
    return (
        <Elements stripe={ loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY) }>
            {props.children}
        </Elements>
    );
};

StripProvider.propTypes = {
    children: propTypes.any
}
export default StripProvider;