import React, { useEffect, useState , useContext } from 'react'
import { reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types';
import { getSiteById } from 'middleware/assessments'
import { getCurrentUser } from 'middleware/auth'
import { getSite, mixpanelCommonData } from 'utils/helpers'
import { MESSAGE } from 'constants/app'
import { Modal, Form } from 'react-bootstrap'
import ScriptInject from './script-inject'
//import { MenuBar } from 'utils/svg'
//import { MenuBar ,ContactIcon } from 'utils/svg'
import SocialMedia from 'components/dashboard/socialMedia'
import { createSocialMedia } from 'middleware/blog'
import { MixpanelContext } from 'utils/tracking';
import Contact from './contact'
// import withPublicRoute from '../../components/hoc/withPublicRoute'

const TravelAgency = () => {
    const mixpanel = useContext(MixpanelContext);
    const connecting = useSelector((state) => state.blog.connecting)
    const site = getSite()
    const [ modalType , setModalType ] = useState(null)
    const [ values, setValues ] = useState({})
    const [ errors, setErrors ] = useState({})
    const [ open , setOpenModal ] = useState(false)
    const userData = useSelector(state => state.user.sessionData?.data?.data)
    const user  = userData?.user

    const dispatch = useDispatch()
    const loading =  useSelector((state) => state.assessment.updateAssessmentLoader)
    const siteData =  useSelector(state => state.assessment.site)

    useEffect(() => {
        dispatch({
            type: 'SET_ACTIVE_SIDEBAR',
            payload: '/travel-agency'
        })
        dispatch(getSiteById(getSite()?.id))
        dispatch(getCurrentUser());
    },[])
    const handleModal = (event, type) => {
        event.preventDefault()
        setOpenModal(!open)
        setModalType(type)
    }
    const handleCloseModal = () => {
        setOpenModal(false)
        setModalType(null)
    }
    const handleEvent = (eventName, data) => {
        mixpanel.track(eventName, mixpanelCommonData(data));
    }

    const handleSocialMediaEvent = (eventName, data) => {
        setOpenModal(false);
        handleEvent(eventName, data)
    }

    const connectData = () => {
        if(!Object.values(errors).includes(true) ){
            dispatch(createSocialMedia(site?.id,{ socialMediaLinks: values }, handleSocialMediaEvent ))
        }
    }

    const renderView =() => {
        switch(modalType){
        case 'social':
            return <SocialMedia onlyBusiness={ true } site={ site } setValues={ setValues } values={ values }  errors={ errors } setErrors={ setErrors }  socialMediaLinks={ siteData?.socialMediaLinks || {} } connecting={ connecting } connectData={ connectData } />
        case 'contact':
            return <Contact user={ user } handleCloseModal={ handleCloseModal } />
        }
    }
    return(<main className="dashboard-data dashboard-edit-site-wrap">
        <section className="dashboard-body">
            <div className="dashboard-header">
                <div className="dashboard-title edit-site-title">
                    <h1>Travel Agency</h1>
                </div>
            </div>
            <div className='edit-site-panel travel-edit-site-panel'>
                <div className="edit-site-btns">
                    <Form.Group controlId="formBasicEmail" className="edit-header-footer">
                        <Form.Label> Social Business </Form.Label>
                        <div className={ 'edit-site-btn no-arrow }' } onClick={ (event) => handleModal(event,'social') }>
                            {/* { <MenuBar/> } */}
                            Links
                        </div>
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail" className="edit-header-footer">
                        <Form.Label> Contact number </Form.Label>
                        <div onClick={ (event) => handleModal(event,'contact') } className={ 'edit-site-btn no-arrow }' }>
                            {/* { <MenuBar/> } */}
                            { user?.phone || 'xxx-xxx-xxxx'}
                        </div>
                    </Form.Group>
                </div>

            </div>
            <div className="dashboard-header site-filter-header">
                <div className="dashboard-title">
                    {/* <h3>Pages</h3> */}
                </div>
                <ScriptInject loading={ loading } siteData={ siteData || site } />
            </div>
        </section>
        <Modal show={ open } onHide={ handleCloseModal } className={ 'logo-upload-modal social-connect-modal' } >
            {renderView()}
        </Modal>
    </main>)
}
TravelAgency.propTypes = {
    handleSubmit: PropTypes.func,
    initialize: PropTypes.object
};
function validate(values){
    const errors = {}
    if (!values.headerScript) {
        errors.headerScript = MESSAGE.VALID_ENTER('header script');
    }
    if (!values.bodyScript) {
        errors.bodyScript = MESSAGE.VALID_ENTER('bodyscript');
    }
    // if(values.bodyScript){
    //     try{
    //         JSON.parse(values.bodyScript)
    //     }catch(e){
    //         errors.bodyScript = e.message
    //         return errors
    //     }
    // }
    return errors;
}
export default reduxForm({
    form: 'scriptInjectForm',
    validate
})(TravelAgency);
