/* eslint-disable react/prop-types */
import React,{ useState, useRef, useEffect, useContext, useCallback } from 'react'
import SocialMedia from './socialMedia'
import { Modal } from 'react-bootstrap'
import PropTypes  from 'prop-types'
import { createSocialMedia } from 'middleware/blog'
import { updateAssessment, downloadCSV } from 'middleware/assessments'
import { useDispatch , useSelector } from 'react-redux';
import { Field ,reduxForm, reset ,stopAsyncValidation } from 'redux-form';
import { renderTextArea, renderFieldChangeWG } from 'utils/formUtils';
import { SOCIAL_MEDIA, SOCIAL_BUSINESS } from 'constants/app'
import { LeftSideLoader, RightSideLoader } from './dashboard-loader'
import { asyncValidate }  from 'utils/asyncValidate';
import UploadImageModal from 'components/assessment/shared/UploadImageModal';
import { change as reduxChange } from 'redux-form';
import ButtonLoader from 'components/core/loader/button-loader'
import _ from 'lodash'
import { EVENTS , MOST_OUT_WIZRD } from 'constants/app'
import { toast } from 'react-toastify';
import history from 'utils/history'
import { SHARING_URLS } from 'constants/app'
import Skeleton from 'react-loading-skeleton';
import{
    Button,
    Col,
    Row,
    Form,
    Dropdown,
    Accordion,
}from 'react-bootstrap';
import {
    TopRightArrow,
    // SocialConnectIcon,
    ShareWebsiteIcon,
    TravelIcon,
    // EditSettingIcon,
    NewPageIcon,
    WriteBlogIcon,
    EditProfileIcon,
    TopArrowSubscriberIcon,
    DownArrowSubscriberIcon
} from 'utils/svg'
import { getUnsplash } from 'middleware/assessments';
import profilePic from 'images/user-avatar.png';
import { updateCurrentUser,  } from 'middleware/auth'
import { isDisabledSite, abbreviateNumber, mixpanelCommonData, isTravelTemplate , trimStringLengthWithDots  } from 'utils/helpers';
import { MixpanelContext } from 'utils/tracking';
import { ROUTES } from 'constants/appRoutes';
import { CSVLink } from 'react-csv';
import youtube from 'images/youtube-color.png'
import facebook from 'images/facebook-color.png'
import twitter from 'images/twitter-color.png'
import { ChevronUp, ChevronDown } from 'utils/svg'
export const SmallLoader = () => {
    return <div className="spinner-border spinner-border-sm" role="status">
        <span className="sr-only">Loading...</span>
    </div>
}

export const AuthorDescription =(props) => {
    const dispatch  = useDispatch()
    // eslint-disable-next-line react/prop-types
    const { handleSubmit, setEditable , initialize, handleEvent } = props
    const selectorData = useSelector(state => state.user.sessionData?.data?.data)
    const user =  selectorData?.user
    const submitData = (data) => {
        dispatch(updateCurrentUser(data, false, handleEvent, 'Updated author description',false))
        setEditable(false)
    }
    useEffect(() => {
        // eslint-disable-next-line react/prop-types
        initialize({ authorDescription: user?.authorDescription })
        return () => {
            dispatch(reset('userProfileForm'))
        }
    },[ user ])
    const cancelFun = () => {
        // eslint-disable-next-line react/prop-types
        initialize({ authorDescription: user?.authorDescription })
        setEditable(false)
    }
    return(
        <Form onSubmit={ handleSubmit(submitData) }>
            <Field
                name="authorDescription"
                label=""
                // eslint-disable-next-line react/prop-types
                defaultValue={ user?.authorDescription }
                component={ renderTextArea }
                maxLength="300"
                placeholder='Enter your description'
            />
            <Button variant='primary' type='submit' className={ 'removeBtn' }>Update</Button>
            <a href='#' onClick={ () => cancelFun() }>cancel</a>
        </Form>
    )
}

export const UserName =(props) => {
    const dispatch  = useDispatch()
    // eslint-disable-next-line react/prop-types
    const { handleSubmit, setUsernameEdit , initialize, handleEvent } = props
    const [ asyncLoad, setAsyncLoad ] =  useState(false)
    const selectorData = useSelector(state => state.user.sessionData?.data?.data)
    const user =  selectorData?.user
    const submitData = (data) => {
        dispatch(updateCurrentUser(data, false, handleEvent, 'Updated user name',false))
        setUsernameEdit(false)
    }
    useEffect(() => {
        // eslint-disable-next-line react/prop-types
        initialize({ userName: user?.userName })
        return () => {
            dispatch(reset('userProfileForm'))
        }
    },[ user ])
    const cancelFun = () => {
        // eslint-disable-next-line react/prop-types
        initialize({ userName: user?.userName })
        setUsernameEdit(false)
    }
    const handleChange = (value) => {
        if(value){
            setAsyncLoad(true)
            asyncValidate(value).then((result) => {
                !result ? dispatch(stopAsyncValidation('userProfileForm', { userName: 'That username is taken' })) : null
                setAsyncLoad(false)
            })
        }
    }
    const asyncValidateFunc = _.debounce(handleChange, 800);
    const asyncChangeCallback = useCallback(asyncValidateFunc, []);
    return(
        <Form onSubmit={ handleSubmit(submitData) }>
            <Field
                name="userName"
                label=""
                // eslint-disable-next-line react/prop-types
                defaultValue={ user?.userName }
                handleChange={ asyncChangeCallback }
                component={ renderFieldChangeWG }
                maxLength="150"
                withoutTouch={ true }
                placeholder='Enter your Username'
            />
            { asyncLoad && <div className="small-up-loader">
                <div className="lds-facebook"><div></div><div></div><div></div></div>
            </div> }
            <Button variant='primary' type='submit' className={ 'removeBtn' }>Update</Button>
            <a href='#' onClick={ () => cancelFun() }>cancel</a>
        </Form>
    )
}

export const WebsiteNameEditor =(props) => {
    const dispatch  = useDispatch()
    // eslint-disable-next-line react/prop-types
    const { site, setWebsiteEdit , handleEventData } = props
    const [ websiteName, setWebsiteName ] = useState(site?.websiteName)
    const domain = site?.customDomain || site?.domain
    const handleEventDataWebsite = () => {
        //(siteBuild(site.id,() => {}))
        handleEventData('Site Website name', { siteId: site?.id, status: site?.status, domain: domain });

    }
    const handleClose = () => {
        setWebsiteEdit(false)
    }
    const handleUpdate = () => {
        dispatch(updateAssessment(site?.id, { websiteName: websiteName?.trim() }, site?.domain, handleClose, ()=>{ }, handleEventDataWebsite ))
        setWebsiteEdit(false)
    }

    const handleChange = (event) => {
        event.preventDefault()
        const value = event.target?.value?.replace(/[^a-zA-Z0-9 -_]/gi, '')
        setWebsiteName(value)

    }
    const cancelFun = () => {
        // eslint-disable-next-line react/prop-types
        setWebsiteEdit(false)
    }

    return(

        <div className="website-title-edit-form">
            <input
                type='text'
                name='websiteName'
                onChange={ handleChange }
                className='form-control'
                value={ websiteName }
            />
            <Button variant='primary' type='button' onClick={ handleUpdate } className={ 'removeBtn' }>Update</Button>
            <a href='#' onClick={ () => cancelFun() }>Cancel</a>
        </div>
    )
}

const UserProfile = (props) =>{
    // eslint-disable-next-line react/prop-types
    const { setProfileModal, profileModal, handleEvent } = props
    const dispatch = useDispatch();
    const userProfileForm = useSelector((state)=>state.form.userProfileForm);
    const unsplashImages  = useSelector((state) => state.assessment.unsplashImages)
    const userProfileLoading = useSelector((state) => state.user.userProfileLoading )

    const handleToggleModal = () => {
        setProfileModal(!profileModal)
    }
    useEffect(() => {
        dispatch(getUnsplash('/photos','profile?w=10&h=10'))
    },[])

    const submitData = ( ) => {
        dispatch(updateCurrentUser({ profileImageUrl: userProfileForm?.values?.profileImageUrl }, false, handleEvent, 'Updated Profile Image',false))
    }

    const clearImage = () => {
        dispatch(reduxChange('userProfileForm', 'profileImageUrl', null))
    }

    const getBase64 = (base64) => {
        dispatch(reduxChange('userProfileForm', 'profileImageUrl', base64))
    }

    const handleSearch = (event) => {
        const query = event.target.value || 'cat'
        dispatch(getUnsplash('/photos',query))
    }
    useEffect(() => {
        if(!userProfileLoading){
            setProfileModal(false)
        }

    },[ userProfileLoading ])

    return(
        <Form onSubmit={ ()  => {} }>
            <UploadImageModal
                fieldName={ 'profileImageUrl' }
                clearImage={ clearImage }
                previewFile={ userProfileForm?.values?.profileImageUrl }
                getBase64={ getBase64 }
                handleSearch={ handleSearch }
                unsplashImages={ unsplashImages }
                openModal={ profileModal }
                submitData={ submitData }
                handleToggleModal={ handleToggleModal }
            >
                <ButtonLoader
                    button={ <Button onClick={ () => submitData() } variant="primary">confirm</Button> }
                    loadingText={ 'Saving' }
                    loading={ userProfileLoading }

                />
            </UploadImageModal>
        </Form>
    )
}
const Dashboard =(props) => {
    const { site, status,statsLoading, statsData } = props
    const [ csvData, setCsvData ] = useState([ ])
    const dispatch = useDispatch()
    const csvRef= useRef(null)
    const [ errors, setErrors ] = useState({})
    const [ values, setValues ] = useState({})

    const [ websiteEdit, setWebsiteEdit ] = useState(false)
    const openTutorial = useSelector((state) => state.user.openTutorial )
    const [ activeToggleVideo, toggleActiveVideo ] = useState(openTutorial)
    const connecting = useSelector((state) => state.blog.connecting)
    const [ openModal ,setOpenModal ] = useState(false)
    const [ editable, setEditable ] = useState(false)
    const [ usernameEdit, setUsernameEdit ] = useState(false)
    const selectorData = useSelector(state => state.user.sessionData?.data?.data)
    const loading = useSelector(state =>state.user.loading || state.assessment.siteSelectLoading)
    const updateAssessmentLoader =   useSelector(state =>state.assessment.updateAssessmentLoader )
    const user =  selectorData?.user
    const [ profileModal, setProfileModal ] = useState(false )
    const mixpanel = useContext(MixpanelContext);
    const isDisabled = isDisabledSite(user?.status);
    const domain = site?.customDomain || site?.domain;

    const handleEvent = (eventName, data) => {
        mixpanel.track(eventName, mixpanelCommonData(data));
    }

    const handleSocialMediaEvent = (eventName, data) => {
        setOpenModal(false);
        handleEvent(eventName, data)
    }

    const connectData = () => {
        if(!Object.values(errors).includes(true) ){
            dispatch(createSocialMedia(site?.id,{ socialMediaLinks: values }, handleSocialMediaEvent ))
        }
    }

    const openEditFunc = () => {
        setEditable(true)
    }

    const openUsernameFunc = () => {
        setUsernameEdit(true)
    }
    const getPercentDisplay = (value) => {
        const isEmpty = value === ''
        return(<>
            {!isEmpty && (parseInt(value) > 0 &&  <TopArrowSubscriberIcon /> || parseInt(value) < 0 && <DownArrowSubscriberIcon /> ) }
            <span>{ value === 0 ? '' : value }</span>
        </>)
    }

    const navigationRedirection = (route, eventName) => {
        handleEvent(eventName, { siteId: site?.id, siteName: site?.websiteName, domain: domain, 'user identifier': user.id })
        history.push(route)
    }

    // const handleConnectSocial = (eventName) => {
    //     handleEvent(eventName, { siteId: site?.id, siteName: site?.websiteName, domain: domain, 'user identifier': user.id })
    //     setOpenModal(!openModal);
    // }

    const viewPercent = statsData?.viewsPercentageChange
    const subscribePercent = statsData?.subscribersPercentageChange

    const handleDownloadCSV = async (event) => {
        event.preventDefault()
        const commonData =  [ [ 'Website', domain ],
            [ ],
            [ 'S.No', 'Email' ] ]
        const response = await downloadCSV(site)
        const data = [ ...commonData , ...response.data.emails ]
        setCsvData(data)
        handleEvent(EVENTS.download, { siteId: site?.id, 'user identifier': user.id });
        //window.location.href = `${ process.env.REACT_APP_API_URL }/v1/user/sites/${ site?.id }/email-subscriber/download`
    }
    useEffect(() => {
        if(!_.isEmpty(csvData)){
            setTimeout(() => {
                csvRef?.current?.link?.click()
                setCsvData([])
            })
        }
    },[ csvData ])
    const dateString = () =>{
        var d = new Date();
        return d.getDate()  + '-' + (d.getMonth()+1) + '-' + d.getFullYear() + '-' +
        d.getHours() + ':' + d.getMinutes();

    }

    const copyToClipBoard = (event) => {
        handleEvent('Share Site', { type: 'Copy to Clip Board', id: site?.id, 'user identifier': user?.id })
        event.preventDefault();
        const dom = site?.customDomain || site?.domain;
        navigator.clipboard.writeText(`https://${ dom }`)
        toast.success('Copy to clipboard', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const shareBlog = (type, event) => {
        handleEvent('Share Site', { type, id: site?.id, 'user identifier': user?.id })
        event.preventDefault();
        const dom = site?.customDomain || site?.domain;
        window.open(`${ SHARING_URLS[ type ] } https://${ dom }`)
    }

    const shareEmailBlogEvent = (event, blog) => {
        event.preventDefault();
        handleEvent('Share Site', { type: 'Email', id: site?.id, 'user identifier': user?.id })
        window.open(SHARING_URLS.EMAIL(blog?.title, 'https://'+site.domain+'/blog/'+ blog.slug))
    }

    const renderLinks = () => {
        return(<><li data-tut="reactour_dash_travelAgency">
            <Button onClick={ () => navigationRedirection(ROUTES.TRAVEL_AGENCY, EVENTS.connectWings) }>Connect WINGS <TravelIcon fill={ '#000' } w={ 32 } h={ 32 } /></Button>
        </li><li data-tut="reactour_dash_newPage">
            <Button onClick={ () => navigationRedirection(ROUTES.PAGE, EVENTS.newPage) }>New Page <NewPageIcon /></Button>
        </li><li data-tut="reactour_dash_shareWebsite">
            <Dropdown>
                <Dropdown.Toggle as={ Button } className='table-action-btns dropdown-indicator'>
                    <span className="share-web">Share <br/>Website</span>
                    <ShareWebsiteIcon />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href="#" onClick={ (e) => copyToClipBoard(e) }>Copy to clipboard</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={ (e) => shareBlog('FACEBOOK',e ) } >Share on facebook</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={ (e) => shareBlog('TWITTER',e ) } >Share on twitter</Dropdown.Item>
                    <Dropdown.Item href="#" onClick={ (e) => shareEmailBlogEvent(e ) } >Share on email</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </li></>)
    }
    const socialMediaArr = isTravelTemplate() ? _.concat(SOCIAL_MEDIA , SOCIAL_BUSINESS)  :  SOCIAL_MEDIA
    return(
        <Col md='12'>
            <Row>
                <div className='col-md-7'>
                    {loading ? <LeftSideLoader loading={ loading }  /> : <div className="dashboard-header">
                        <div className={ `dashboard-title ${ isDisabled ? 'disabled-link' : '' }` }>
                            { websiteEdit ? <WebsiteNameEditor handleEventData={ handleEvent } setWebsiteEdit={ setWebsiteEdit } websiteEdit={ websiteEdit }   site={ site } /> :  <h1>{ updateAssessmentLoader ? <Skeleton count={ 1 } height={ 20 } width={ 200 } style={ { marginLeft: '-5px' } } /> : <><span className="dashboard-site-name">{ trimStringLengthWithDots(site?.websiteName,19) }</span> <a onClick={ () => setWebsiteEdit(true) } >Edit</a></> }</h1>}
                            <h5 className={ 'mb-2 ml-1' } data-tut="reactour_dash_webname">
                                Domain:
                                <a href={ `https://${ domain }` } rel="noreferrer" target='_blank' className={ `${ status ? 'success' : 'in-progress' }` }>
                                    { domain } <TopRightArrow />
                                </a>

                                {/* <span className={  'success' }>
                                    DONE
                                </span> */}
                            </h5>
                        </div>
                        <div className="blog-dashboard-report" >
                            <div className="data-box" data-tut='reactour_dash_nextstep'>
                                <div className="data-box-title">
                                    <h3>Next steps</h3>
                                </div>
                                <ul className={ `blog-dashboard-steps customGrid ${ isDisabled ? 'disabled-link' : '' }` }>
                                    {isTravelTemplate() ? renderLinks( ): <>
                                        <li data-tut="reactour_dash_wriblog">
                                            {/* <h4></h4> */}
                                            <Button onClick={ () => navigationRedirection(ROUTES.BLOG, EVENTS.clickWriteABlog) }>New Blog Post <WriteBlogIcon /></Button>

                                        </li>
                                        <li data-tut="reactour_dash_editsite">
                                            {/* <h4></h4> */}
                                            <Button onClick={ () => navigationRedirection(ROUTES.PAGE, EVENTS.clickEditSite) }>New Page <NewPageIcon /></Button>
                                        </li>
                                        <li data-tut="reactour_dash_consocial">
                                            <Dropdown>
                                                <Dropdown.Toggle as={ Button } className='table-action-btns dropdown-indicator'>
                                                    <span className="share-web">Share <br/>Website</span>
                                                    <ShareWebsiteIcon />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="#" onClick={ (e) => copyToClipBoard(e) }>Copy to clipboard</Dropdown.Item>
                                                    <Dropdown.Item href="#" onClick={ (e) => shareBlog('FACEBOOK',e ) } >Share on facebook</Dropdown.Item>
                                                    <Dropdown.Item href="#" onClick={ (e) => shareBlog('TWITTER',e ) } >Share on twitter</Dropdown.Item>
                                                    <Dropdown.Item href="#" onClick={ (e) => shareEmailBlogEvent(e ) } >Share on email</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            {/* <h4></h4> */}
                                        </li></>}
                                </ul>
                                {isTravelTemplate()  && <Modal show={ openModal } onHide={ () => {setOpenModal(false)} } className="logo-upload-modal social-connect-modal">
                                    <SocialMedia site={ site } setValues={ setValues } values={ values }  errors={ errors } setErrors={ setErrors }  socialMediaLinks={ site?.socialMediaLinks || {} } connecting={ connecting } connectData={ connectData } openModal={ openModal } setOpenModal={ setOpenModal } />
                                </Modal>}
                            </div>
                            <div className="data-box" data-tut='reactour_dash_views_subscriber'>
                                <ul className="dashboard-view-subscribe">
                                    <li>
                                        <h5>Views</h5>
                                        {
                                            <h3>
                                                {  viewPercent > site?.views ? viewPercent : abbreviateNumber(site?.views) || (viewPercent < 0 ? '' : viewPercent) || 0 }
                                                <sub>
                                                    { statsLoading ? <SmallLoader /> : viewPercent < 0 ? '' : getPercentDisplay(viewPercent) }
                                                </sub>
                                            </h3>
                                        }
                                    </li>

                                    <li>
                                        <h5>Subscribers</h5>
                                        <h3>
                                            { abbreviateNumber(site?.subscriberCount || 0 ) }
                                            <sub>
                                                { statsLoading ? <SmallLoader /> : getPercentDisplay(subscribePercent) }
                                            </sub>
                                        </h3>
                                        {site?.subscriberCount > 0 && <>
                                            <a href='#' onClick={ handleDownloadCSV }>Export CSV File</a>
                                            <CSVLink
                                                data={ csvData }
                                                ref={ csvRef }
                                                filename={ `email-subscriber-${ dateString() }.csv` }
                                            />
                                        </>}

                                    </li>
                                </ul>
                            </div>
                            {!isTravelTemplate() &&
                            <div className="data-box" data-tut='reactour_dash_watch_tutorial'>
                                <Accordion defaultActiveKey={ activeToggleVideo }>

                                    <div className={ activeToggleVideo === '0' ? 'panel-wrap active-panel' : 'panel-wrap' }>
                                        <div className="panel-header data-box-title">
                                            <h3>Watch Tutorial</h3>
                                            <Accordion.Toggle onClick={ () => toggleActiveVideo( activeToggleVideo === '0' ? '1' : '0') } className="panel-toggle" variant="link" eventKey="0">
                                                { activeToggleVideo === '0' ? <ChevronDown/> : <ChevronUp /> }
                                            </Accordion.Toggle>
                                        </div>

                                        <Accordion.Collapse eventKey="0">
                                            <div className="panel-body">
                                                <iframe
                                                    width="560"
                                                    height="250"
                                                    src="https://www.youtube.com/embed/videoseries?list=PLPFj8-n_VVLcFpMlW2wvnn8ZqCOVfiEAa"
                                                    title="YouTube video player"
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                ></iframe>
                                            </div>
                                        </Accordion.Collapse>
                                    </div>
                                </Accordion>
                            </div>}
                        </div>
                        <Modal show={ openModal } onHide={ () => {
                            setErrors({})
                            setOpenModal(false)
                        } } className="logo-upload-modal social-connect-modal">
                            <SocialMedia site={ site } setValues={ setValues } values={ values }  errors={ errors } setErrors={ setErrors }  socialMediaLinks={ site?.socialMediaLinks || {} } connecting={ connecting } connectData={ connectData } openModal={ openModal } setOpenModal={ setOpenModal } />
                        </Modal>
                    </div>}
                </div>
                <div className="blog-dashboard-data col-md-5 bloggerProfile">

                    <div className="dashboard-header data-box" data-tut='reactour_dash_authorbox'>
                        { !user || loading ? <RightSideLoader loading={ true } /> : <div className="author-info" >
                            <div className=' bloggerImgSection'>
                                <div className="author-img" data-tut="reactour_dash_auth_profile" onClick={ () => setProfileModal(!profileModal) }>
                                    <img src={ user?.profileImageUrl || profilePic } alt="Jason Miller" />
                                    <a className="edit-profile-avtar">
                                        <EditProfileIcon />
                                    </a>
                                </div>
                                <UserProfile handleEvent={ handleEvent } setProfileModal={ setProfileModal } profileModal={ profileModal } />
                                <div className="author-name" data-tut="reactour_dash_auth_name" >
                                    {
                                        usernameEdit ? <UserName handleEvent={ handleEvent } setUsernameEdit={ setUsernameEdit } { ...props }  /> : <><h5>{user?.userName?.toUsername() } <a href='#' onClick={ openUsernameFunc } >Edit</a></h5></>
                                    }
                                </div>
                            </div>
                            <hr/>
                            <div className='about' data-tut="reactour_dash_auth_desc" >
                                <h6>About</h6>
                                {editable ? <AuthorDescription handleEvent={ handleEvent } setEditable={ setEditable } { ...props } /> : <>
                                    <p>{ user?.authorDescription }</p>
                                    <a href='#' onClick={ openEditFunc } className={ 'flexEnd' }>Edit</a>
                                </>
                                }
                            </div>
                            <hr/>
                            <div className='connect-social'>
                                <h6>Connect your social media accounts</h6>
                                {site?.socialMediaLinks && Object.keys(site?.socialMediaLinks).map((item) => {
                                    const obj = socialMediaArr.filter((icon)=> icon.value === item)[ 0 ]
                                    return site?.socialMediaLinks[ item ] && (
                                        <>
                                            <a rel="noreferrer" target="_blank" href={ site?.socialMediaLinks[ item ] } dangerouslySetInnerHTML={ { __html: obj?.circleImg || obj?.imgUrl } } />
                                        </>)
                                })}
                                <a href='#' className={ `addIcon ${ isDisabled ? 'disabled-link' : '' }` } onClick={ () => { setOpenModal(!openModal)} } >+</a>
                            </div>
                        </div> }
                    </div>
                    <div className='data-box most-out-wrapper'>
                        <div className='most-out'>
                            <h4>Get the most out of wizrd!</h4>
                            <ul>
                                <li><a href={ MOST_OUT_WIZRD.tutorial } target='_blank' rel="noreferrer">
                                    <img src={ youtube } /><br /><span className="img-lable">Watch Tutorials</span>
                                </a>
                                </li>
                                <li><a href={ MOST_OUT_WIZRD.community } target='_blank' rel="noreferrer">
                                    <img src={ facebook } /><br/><span className="img-lable">Join Community</span>
                                </a></li>
                                <li><a href={ MOST_OUT_WIZRD.follow } target='_blank' rel="noreferrer">
                                    <img src={ twitter } /><br/><span className="img-lable">Follow For Updates </span>
                                </a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Row>
        </Col>
    )
}

Dashboard.propTypes = {
    site: PropTypes.object,
    initialize: PropTypes.object,
    user: PropTypes.object,
    status: PropTypes.bool,
    statsData: PropTypes.object,
    statsLoading: PropTypes.bool
}
AuthorDescription.PropTypes = {
    handleSubmit:  PropTypes.func,
    setEditable:  PropTypes.func
}
WebsiteNameEditor.PropTypes = {
    site:  PropTypes.any,
}

String.prototype.toUsername = function(){
    return this?.split('@') && this?.split('@')[ 0 ];
}
export default reduxForm({
    form: 'userProfileForm',
})(Dashboard);