import React from 'react';
import './style.sass'
import PropTypes from 'prop-types';
const Tbody = (props)=> {
    return (
        <tr>
            <td>{ props.row1 }</td>
            <td>{ props.row2 }</td>
            <td>{ props.row3 }</td>
        </tr>
    )
}
Tbody.propTypes = {
    row1: PropTypes.string,
    row2: PropTypes.string,
    row3: PropTypes.string,
};

export default Tbody;