import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form'
import user from './user';
import assessment from './assessment';
import blog from './blog';
import page from './page';
import plan from './plan';
import card from './card';
import transaction from './transaction';
import subscription from './subscription';
import domain from './domain';
import theme from './theme';

const appReducer =  combineReducers({
    user: user,
    blog: blog,
    page: page,
    theme: theme,
    assessment: assessment,
    plan: plan,
    card: card,
    transaction: transaction,
    subscription: subscription,
    domain: domain,
    form: formReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        // eslint-disable-next-line no-param-reassign
        state = undefined
    }
    return appReducer(state, action)
}

export default rootReducer