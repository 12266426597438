import React, { useEffect, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Button } from 'react-bootstrap'
import { reduxForm } from 'redux-form';
import { Prompt } from 'react-router'
import PropTypes from 'prop-types';
import { updateAssessment, getSiteById } from 'middleware/assessments'
import PageList from 'components/edit-site/pages/List'
import ColourPalette from 'components/edit-site/ColourPalette';
//import Niche from 'components/edit-site/Niche'
import UploadLogo from 'components/edit-site/UploadLogo'
import HeaderCover from 'components/edit-site/HeaderCover'
import MenuLinks from 'components/edit-site/MenuLinks'
import ButtonLoader from 'components/core/loader/button-loader'
import { getSite , diffObjects } from 'utils/helpers'
import _ from 'lodash';
import { MenuBar } from 'utils/svg'
import { getCurrentUser } from 'middleware/auth'
import { change as reduxChange } from 'redux-form'
// import searchIcon from '../../images/search.png';
// import filterIcon from '../../images/filter.png';
import ConfirmAlert from 'components/core/confirm-alert'
import { confirmAlert } from 'react-confirm-alert';
import { AllColors } from 'constants/theme'
import { isDisabledSite, mixpanelCommonData, isTravelTemplate } from 'utils/helpers';
import { MixpanelContext } from 'utils/tracking';
//import { getSite } from 'utils/helpers';
import Skeleton from 'react-loading-skeleton';
import blogBanner from 'images/blog-banner.png';
import travelBanner from 'images/wizrd-plan-header.jpeg';
import defaultFavIcon from 'images/default-favicon.png'
const EditSitePage =(props) => {
    const dispatch = useDispatch();
    const { handleSubmit , initialize } = props
    const [ open, setOpen ] = useState(false)
    const [ linkType, setLinkType ] = useState(0)
    const [ isValid, setIsValid ] = useState(true)
    const [ loadData, setLoadData ] = useState(false)
    const [ modalType, setModalType ] = useState(null)
    const { assessmentData ,updateAssessmentLoader, siteBuildLoading } = useSelector((state) => state.assessment)
    const form  = useSelector((state) => state.form.assessmentUpdateForm)
    const unsplashImages  = useSelector((state) => state.assessment.unsplashImages)
    const [ menuLinks, setMenuLinks ] = useState(form?.values?.menuLinks)
    const data = useSelector(state => state.user.sessionData?.data?.data)
    const site =   useSelector((state) => state.assessment.site)
    const [ colorPalette , setColorPalette ] = useState(AllColors())

    const loading =  useSelector((state) => state.assessment.loading)
    const [ customOpen, setCustomPopup ] = useState(false)
    const mixpanel = useContext(MixpanelContext);
    const isDisabled = isDisabledSite(data?.user?.status);
    const siteLocalData = JSON.parse(localStorage.site)

    const validUrls = () => {
        let result = false
        const names= menuLinks.map((item)=> item.name)
        const customUrls = menuLinks.filter((item) => item.name !== 'home').map((i) => i.url)
        const isDuplicateName =  !_.isEmpty(names.filter((item, index) => names.indexOf(item) != index))
        const urls= menuLinks.map((item)=> item.url)
        const isDuplicateUrl =  !_.isEmpty(urls.filter((item, index) => urls.indexOf(item) != index))
        if(isDuplicateName || isDuplicateUrl){
            result =  false
        }else if(customUrls.includes('') || customUrls.includes(undefined)){
            result =  false
        }else{
            result =  true
        }
        setIsValid(result)
        setTimeout(()=> setIsValid(true),10000)
        return result
    }

    const isValidMenu = () => {
        return menuLinks && !menuLinks.map((item) => item.name ).includes(undefined)
    }
    const handleClose = () => {
        setModalType(null)
        setCustomPopup(false)
        setOpen(false)
    }

    const handleCloseModal = () => {
        const siteColors = site?.colors && JSON.parse(site.colors)
        const colors = form?.values?.colors || site?.colors
        if(modalType == 'header'){
            const colorObj = Object.assign({},JSON.parse(colors))
            colorObj[ 'header-background' ] = siteColors[ 'header-background' ]
            dispatch(reduxChange('assessmentUpdateForm', 'colors', JSON.stringify(colorObj)))
            dispatch(reduxChange('assessmentUpdateForm', 'header', site?.header))
            dispatch(reduxChange('assessmentUpdateForm', 'coverImage', site?.coverImage))
        }else if(modalType == 'menuLinks'){
            // const updatedMenuLinks = _.filter(menuLinks,v => _.keys(v).length !== 0);
            // setMenuLinks(updatedMenuLinks)
            dispatch(reduxChange('assessmentUpdateForm', 'menuLinks', siteLocalData?.menuLinks))
        }else if(modalType === 'colors'){
            const colorObj = Object.assign({},JSON.parse(colors))
            colorObj[ 'header-background' ] = colors && JSON.parse(colors)[ 'header-background' ]
            dispatch(reduxChange('assessmentUpdateForm', 'colors', JSON.stringify(colorObj)))
        }
        else{
            dispatch(reduxChange('assessmentUpdateForm', modalType, site && site[ modalType ] ))
        }

        handleClose()
    }

    const handleEvent = (isSuccess) => {
        let eventName = isSuccess ? 'Updated' : 'Failed Updation';
        switch(modalType){
        case 'colour':
            eventName = `${ eventName } Site Color`;
            break;
        case 'logo':
            eventName = `${ eventName } Site Logo`;
            break;
        case 'menulinks':
            eventName = `${ eventName } Site Menu Link`;
            break;
        case 'favicon':
            eventName = `${ eventName } Site Favicon Icon`;
            break;
        case 'header':
            eventName = `${ eventName } Site Header`;
            break;
        }
        //dispatch(siteBuild(site.id,() => {}))
        mixpanel.track(eventName, mixpanelCommonData({ siteId: site?.id, status: site?.status, domain: site?.domain, 'user identifier': data?.user?.id }));
    }
    const saveData = (formData) => {
        formData[ 'menuLinks' ] = menuLinks
        dispatch(updateAssessment(site?.id, formData, site?.domain, handleClose, handleEvent))
    }

    const handleBlockedNavigation = (event) => {
        const pathname = event?.pathname
        const obj = diffObjects(form?.values,site)
        if(!_.isEmpty(obj)){
            confirmAlert({
                // eslint-disable-next-line react/display-name
                customUI: ({ onClose }) => {

                    const handleNavigation = () => {
                        onClose()
                        handleClose && handleClose()
                        window.location.href = pathname

                    }
                    const handleModalClose = () => {
                        onClose()
                    }
                    const handleSave = () => {
                        onClose()
                        saveData(form?.values)
                    }
                    return(
                        <ConfirmAlert
                            btnTitle='save'
                            key={ 'box' }
                            handleNavigation={ handleNavigation }
                            linkLabel={ 'leave' }
                            onClose={ handleModalClose }
                            handleAction={ () =>  handleSave() }
                            subHeading='If you leave before saving, your changes will be lost.' />
                    );
                }
            });
            return false

        }
        return true
    }

    useEffect(() => {
        dispatch({
            type: 'SET_ACTIVE_SIDEBAR',
            payload: '/edit-site'
        })
        dispatch(getSiteById(getSite()?.id))
        //dispatch(getAssessment())
        dispatch(getCurrentUser());

        return () => {
        }
    }, [  ]);

    useEffect(() => {
        if(!_.isEmpty(form?.values?.menuLinks)){
            setMenuLinks(form?.values?.menuLinks)
        }

    }, [ form?.values?.menuLinks ])

    useEffect(() => {
        if(!_.isEmpty(form?.values?.colors && JSON.parse(form?.values?.colors)?.name === 'custom-color')){
            const obj = { label: 'Custom Color', value: JSON.parse(form?.values?.colors),imageUrl: undefined }
            colorPalette.pop()
            colorPalette.push(obj)
            setColorPalette(colorPalette)
        }
    }, [ form?.values?.colors ])

    useEffect(() => {
        mixpanel.track(`Click on ${ modalType } (EDIT SITE )`,mixpanelCommonData({}))
    },[ modalType ])

    useEffect(() => {
        if(!_.isEmpty(site)){
            const siteMenuLinks =  [ ... site?.menuLinks ]
            const formData = {
                colors: site.colors,
                logoUrl: site.logoUrl,
                coverImage: site.coverImage,
                faviconUrl: site.faviconUrl,
                header: site.header,
                menuLinks: _.isEmpty(siteMenuLinks ) ? [ { name: 'home',url: '' }, { name: 'contact',url: 'contact' } ] : siteMenuLinks
            }
            initialize(formData)
        }

    }, [ site ])
    const handleModal = (event, type) => {
        event.preventDefault()
        if (!isDisabled) {
            setModalType(!open ? type : null)
            setOpen(!open)
        }
    }
    const getBase64 = (base64, fieldName) => {
        dispatch(reduxChange('assessmentUpdateForm', fieldName, base64))
    }

    const clearImage = (event, field) => {
        event.preventDefault()
        dispatch(reduxChange('assessmentUpdateForm', field, ''))
    }
    const addMenuLinks = () =>{
        const obj = {}
        setLoadData(true)
        const menuLinksClone = Object.assign([],menuLinks)
        menuLinksClone.push(obj)
        setMenuLinks(menuLinksClone)
        setTimeout(()=>{
            setLoadData(false)
        })
    }

    const removeMenuLink = (index) => {
        const obj = [ ...menuLinks ]
        obj.splice(index, 1);

        setMenuLinks(obj)
        // dispatch(reduxChange('assessmentUpdateForm', 'menuLinks', obj))
        // validUrls()
    }

    const handleNewTab = (event,index) => {
        event.preventDefault()
        setLoadData(true)
        const menuLinksClone = Object.assign([],menuLinks)
        const obj = { ...menuLinksClone[ index ] }
        obj[ 'newTab' ] = event.target.checked
        menuLinksClone[ index ] = obj
        setMenuLinks(menuLinksClone)
        //dispatch(reduxChange('assessmentUpdateForm', 'menuLinks', menuLinksClone))
        setTimeout(()=> setLoadData(false))
    }

    const handleChangeMenuName = (event, index,url) =>{
        event.preventDefault();
        if(!event.target.value.match(/[&/\\#,+()@$~%.'"*?<>{}]/g)){
            setLoadData(true)
            const name = event.target?.value
            const obj = { name: name, url: (url === 'contact' ? url : '') }
            menuLinks[ index ] = obj
            setMenuLinks(menuLinks)
            // obj.url === '' && obj.name === 'home' ? null :  validUrls()
            // dispatch(reduxChange('assessmentUpdateForm', 'menuLinks', menuLinks))
            setTimeout(()=> setLoadData(false))
        }
    }
    const handleChangeMenuUrl = ( event, index, type ) => {
        const value = type === 'click' ? event.value : event?.target?.value
        if(!value.match(/[&\\#,+!()^@$~%'";*?<>{}]/g)){
            setLoadData(true)
            const obj = menuLinks[ index ]
            obj[ 'url' ] = value.trim() && value?.trim().replace(/[&\\#,+!()@$~%'";*?<>{}]/g,'')
            menuLinks[ index ] = obj
            // setMenuLinks(menuLinks)
            // obj.url === '' && obj.name === 'home' ? null :  validUrls()
            //dispatch(reduxChange('assessmentUpdateForm', 'menuLinks', menuLinks))
            setTimeout(()=> setLoadData(false))
        }
    }

    const handleRecentMenuLinks = (event, item) =>{
        event.preventDefault()
        setLoadData(true)
        const menuLinksClone = Object.assign([],menuLinks)
        const name = item.title
        const obj = { name: name, url: item.slug }
        menuLinksClone.push(obj)
        setLinkType(menuLinks.length)
        setMenuLinks(menuLinksClone)
        // obj.url === '' && obj.name === 'home' ? null :  validUrls()
        //dispatch(reduxChange('assessmentUpdateForm', 'menuLinks', menuLinksClone))
        setTimeout(()=> setLoadData(false))
    }

    const renderModalView = () =>{
        switch(modalType){
        // case 'niche':
        //     return <Niche form={ form } handleSubmit={ handleSubmit }  submitData= { submitData } assessmentData={ assessmentData } loading={ updateAssessmentLoader } />
        case 'colors':
            return <ColourPalette formName={ 'assessmentUpdateForm' } handleClose={ handleClose } customOpen={ customOpen } setCustomPopup={ setCustomPopup }  previewFile={ form?.values?.coverImage }  handleSubmit={ handleSubmit }   formValues={ form?.values }  site={ site }  setColorPalette={ setColorPalette } colorPalette={ colorPalette }  onClose={ handleClose }  loading={ updateAssessmentLoader } />
        case 'logoUrl':
            return <UploadLogo handleSubmit={ handleSubmit }  handleClose={ handleClose } fieldName='logoUrl' previewFile={ form?.values?.logoUrl } unsplashImages={ unsplashImages } clearImage={ clearImage } getBase64={ getBase64 }  assessmentData={ assessmentData } loading={ updateAssessmentLoader } />
        case 'menuLinks':
            return <MenuLinks linkType={ linkType } setLinkType={ setLinkType } handleRecentMenuLinks={ handleRecentMenuLinks } handleNewTab={ handleNewTab }  validUrls={ validUrls } isValidMenu={ isValidMenu }  handleChangeMenuUrl={ handleChangeMenuUrl } handleClose={ handleClose } setMenuLinks={ setMenuLinks }   isValid={ isValid } removeMenuLink={ removeMenuLink } handleChangeMenuName={ handleChangeMenuName } loadData={ loadData } menuLinks={ menuLinks } addMenuLinks={ addMenuLinks } loading={ updateAssessmentLoader } />
        case 'faviconUrl':
            return <UploadLogo site={ site } form={ form } allowExtenstions={ 'image/jpeg, image/png, .ico' } handleSubmit={ handleSubmit }   fieldName='faviconUrl' previewFile={ form?.values?.faviconUrl } unsplashImages={ unsplashImages } clearImage={ clearImage } getBase64={ getBase64 }  assessmentData={ assessmentData } handleClose={ handleClose } loading={ updateAssessmentLoader } />
        case 'header':
            return <HeaderCover allowExtenstions={ 'image/jpeg, image/png, .ico' } formName={ 'assessmentUpdateForm' } customOpen={ customOpen } setCustomPopup={ setCustomPopup }  previewFile={ form?.values?.coverImage }  handleSubmit={ handleSubmit }   formValues={ form.values }   setColorPalette={ setColorPalette } colorPalette={ colorPalette }  onClose={ handleClose }  loading={ updateAssessmentLoader }  />
        }
    }
    const getHeaderCover = () => {
        const values  = form?.values
        let cover =  values?.coverImage ? <img src={ values?.coverImage } alt="s"/> : (values?.colors && JSON.parse(values?.colors)[ 'header-background' ] && <span style={ { background: JSON.parse(values?.colors)[ 'header-background' ] } } ></span>)
        cover = cover || (site?.coverImage ?  <img src={ site?.coverImage } alt="d"/> : (site?.colors && JSON.parse(site?.colors)[ 'header-background' ] &&  <span style={ { background: JSON.parse(site?.colors)[ 'header-background' ] } }></span>))
        return cover || (isTravelTemplate() ? <img src={ travelBanner } alt='cover' /> : <img src={ blogBanner } alt="cover"/>)
    }
    const obj =  _.isEmpty(diffObjects(form?.values,site)) ? diffObjects(form?.values?.menuLinks,siteLocalData?.menuLinks) : diffObjects(form?.values,site)

    return(
        <main className="dashboard-data dashboard-edit-site-wrap">
            <Prompt
                when={ true }
                message={ handleBlockedNavigation }/>
            <section className="dashboard-body">
                <div className="dashboard-header">
                    <div className="dashboard-title edit-site-title">
                        <h1>Edit Site</h1>
                    </div>
                </div>
                <div className='edit-site-panel'>
                    <div className="edit-site-btns">
                        {/*<Form.Group controlId="formBasicEmail">
                            <Form.Label>Blog / Niche:</Form.Label>
                            <div className={ `edit-site-btn ${ isDisabled ? 'disabled-link' : '' }` } onClick={ (event) => handleModal(event,'niche') }>
                                {loading && !site ? <Skeleton className="content-col-12" count={ 1 } width={ '100%' } /> : <><img src={ niche?.icon } />{ niche?.label }</> }
                            </div>
    </Form.Group>*/}
                        <Form.Group controlId="formBasicEmail" className="edit-header-footer">
                            <Form.Label>Header/Tagline </Form.Label>
                            <div onClick={ (event) => handleModal(event,'header') } className="image-box">
                                { getHeaderCover() }
                            </div>
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Color palette:</Form.Label>
                            <div className={ `edit-site-btn ${ isDisabled ? 'disabled-link' : '' }` } onClick={ (event) => handleModal(event,'colors') }>
                                {loading && !site  ? <Skeleton className="content-col-12" count={ 1 } width={ '100%' } /> : <><span className="checkbox-colors round-border">
                                    <span style={ { backgroundColor: form?.values?.colors && JSON.parse(form?.values?.colors)[ 'top-menu' ] } }></span>
                                </span>{form?.values?.colors && JSON.parse(form?.values?.colors)?.name }</>}
                            </div>
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail" className='menu-links'>
                            <Form.Label>Menu:</Form.Label>
                            <div className={ `edit-site-btn no-arrow ${ isDisabled ? 'disabled-link' : '' }` } onClick={ (event) => handleModal(event,'menuLinks') }>
                                {loading && !site ? <Skeleton className="content-col-12" count={ 1 } width={ '100%' } /> : <MenuBar/> }
                            </div>
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail" className='logo'>
                            <Form.Label>Site logo:</Form.Label>
                            <div className={ `logo-preview-btn edit-site-btn no-arrow ${ isDisabled ? 'disabled-link' : '' }` } onClick={ (event) => handleModal(event,'logoUrl') }>
                                {loading && !site ? <Skeleton count={ 1 } className="content-col-12" width={ '100%' } /> : (form?.values?.logoUrl  ? <img src={ form?.values?.logoUrl || site?.logoUrl } width={ 20 } /> : site?.websiteName ) }
                            </div>
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail" className='favicon'>
                            <Form.Label>Site icon:</Form.Label>
                            <div className={ `logo-preview-btn edit-site-btn no-arrow ${ isDisabled ? 'disabled-link' : '' }` } onClick={ (event) => handleModal(event,'faviconUrl') }>
                                { loading && !site ? <Skeleton count={ 1 } width={ '100%' } /> : <img src={ form?.values?.faviconUrl || site?.faviconUrl || defaultFavIcon } width={ 20 } /> }
                            </div>
                        </Form.Group>
                        <Form.Group className='update-site'>
                            <Form.Label></Form.Label>
                            { !_.isEmpty(obj) ? <ButtonLoader
                                loadingText={ 'Saving..' }
                                button={ <Button onClick={ () => saveData( form?.values ) } variant="primary" >Save Changes</Button> }
                                loading={ updateAssessmentLoader || siteBuildLoading }
                            /> : <Button variant="primary" disabled={ true }>Save Changes</Button> }
                        </Form.Group>
                    </div>
                    <Modal show={ open } onHide={ handleCloseModal } className={ `${ customOpen || modalType === 'header' ? 'new-modal custom-color-modal header-edit-modal' : 'new-modal logo-upload-modal' }` }>
                        {renderModalView()}
                    </Modal>
                </div>
                <div className="dashboard-header site-filter-header">
                    <div className="dashboard-title">
                        {/* <h3>Pages</h3> */}
                    </div>
                    <PageList />
                </div>
            </section>
        </main>

    )
}
EditSitePage.propTypes = {
    handleSubmit: PropTypes.func,
    initialize: PropTypes.object,
    onClose: PropTypes.func
};

String.prototype.uppercase = function () {
    return this.charAt(0).toUpperCase() + this.slice(1)
};
export default reduxForm({
    form: 'assessmentUpdateForm',
    destroyOnUnmount: false
})(EditSitePage);