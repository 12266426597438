import axiosInstance from '../services/api';

import {
    getTransactionsFailure,
    getTransactionsRequest,
    getTransactionsSuccess
} from '../actions/transaction';

export const getTransactions = () => {
    return (dispatch) => {
        dispatch(getTransactionsRequest())
        axiosInstance.get('/transactions').then((response)=>{
            dispatch(getTransactionsSuccess(response.data.data))
        }).catch((error) => {
            dispatch(getTransactionsFailure(error))
        })
    };
};
