import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser } from '../../middleware/auth'
import { getSiteById } from '../../middleware/assessments'
import { removeItem } from '../../utils/cache'
//import { CrossIcon } from 'utils/svg'
import Dashboard from '../../components/dashboard'
import { getStats } from 'middleware/auth'
import { getSite , getTour,setTour,dayCount, bodyScrollLock , mixpanelCommonData ,closeWarningAleart  } from 'utils/helpers';
import Tour from 'reactour'
import { dashboardTour, dashboardTourMobile } from 'constants/tour-config'
import { MixpanelContext } from 'utils/tracking';
import { EVENTS, MESSAGE, TRIAL_PERIOD } from 'constants/app'
import  { isTravelTemplate } from 'utils/helpers'
const DashboardPage =() => {
    const isIpad = window.outerWidth <= 768;
    const dispatch = useDispatch();
    const [ isTourOpen,setTourOpen ] = useState(false)
    const mixpanel = useContext(MixpanelContext)
    const data = useSelector(state => state.user.sessionData?.data?.data)
    const status = useSelector(state => state.user.status)
    const statsLoading = useSelector(state => state.user.statsLoading)
    const statsData = useSelector(state => state.user.statsData)
    const user =  data?.user
    const site =  useSelector(state => state.assessment.site)
    var timeoutData = null;
    useEffect(() => {
        const timer1 = setTimeout(() => setTourOpen(getTour('dashboard')), 3.5 * 1000);
        dispatch(getStats(getSite()?.id))
        dispatch(getCurrentUser());
        dispatch(getSiteById(getSite()?.id))

        removeItem('assessmentForm');
        dispatch({
            type: 'SET_ACTIVE_SIDEBAR',
            payload: '/dashboard'
        })

        return () => {
            clearInterval(timeoutData)
            clearTimeout(timer1)
        }
    }, [  ]);

    useEffect(() => {
        if(isTourOpen){
            mixpanel.track(EVENTS.firstLogin,mixpanelCommonData({}))
        }
    },[ ])
    // const setActiveSite = () => {
    //     const siteData = localStorage.site && JSON.parse(localStorage.site)
    //     siteData[ 'isActive' ] = true
    //     setItem('site',siteData)
    // }
    // const checkDomainStatus = async(domain) => {
    //     try{
    //         const response = await trackDomainStatus(domain)
    //         dispatch(domainStatusSuccess(response))
    //         if(response.data.status === 'SUCCESS'){
    //             setActiveSite()
    //             clearInterval(timeoutData)
    //         }
    //     // eslint-disable-next-line no-empty
    //     }catch(err){

    //     }
    // }
    // useEffect(() =>{
    //     const domain = site?.domain
    //     //domain && checkDomainStatus(domain )
    //     timeoutData = domain &&  setInterval(function(){
    //         checkDomainStatus(domain)
    //     },20000)
    //     return () =>{
    //         clearInterval(timeoutData)
    //     }
    // },[ site?.domain ])

    const disableBody = () => {
        bodyScrollLock(true)
    }
    const enableBody = () => {
        bodyScrollLock(false)
    }

    const closeTour = () => {
        const tour = getTour()
        tour[ 'dashboard' ] = false
        setTour(tour)
        dispatch({
            type: 'CLOSE_SIDEBAR_2'
        })
        mixpanel.track(EVENTS.tourClosed,mixpanelCommonData({}))
        setTourOpen(false)
    }
    const handleEvents = () => {
        mixpanel.track(EVENTS.tourCompleted,mixpanelCommonData({}))
    }
    const remainDay = user?.planDetails?.planName === TRIAL_PERIOD && user?.preSubscribedDays?.daysRemaining
    return(
        <main className="dashboard-data">
            <section className="dashboard-body">
                {remainDay && remainDay < 6 &&  <div className="warning-fixed-alert">
                    <div className="alert alert-danger alert-dismissible in " role="alert">
                        <span>
                            {  dayCount(remainDay) }
                            {MESSAGE.UPGRADE_MESSAGE1}
                            <Link to='/plan-selection?utm_source=inbound&utm_medium=banner&utm_campaign=free-trial-ending-banner'>Click here to upgrade</Link>
                            <span dangerouslySetInnerHTML={ { __html: MESSAGE.UPGRADE_MESSAGE2 } } />

                        </span>
                        <a hef='#' onClick={ (event) => closeWarningAleart(event) } className="close-alert">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18 6L6 18" stroke="#1B1B1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M6 6L18 18" stroke="#1B1B1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </a>
                    </div>
                </div> }
                {isTravelTemplate() &&
                <Tour
                    closeWithMask={ false }
                    onRequestClose={ () => closeTour() }
                    maskSpace={ 3 }
                    disableInteraction={ true }
                    disableFocusLock={ true }
                    steps={ isIpad ? dashboardTourMobile(handleEvents) : dashboardTour(handleEvents) }
                    isOpen={ isTourOpen }
                    maskClassName="mask"
                    onAfterOpen={ disableBody }
                    onBeforeClose={ enableBody }
                    className="helper"
                    rounded={ 5 }

                />}

                <Dashboard status={ status } user={ user } site={ site } statsData={ statsData } statsLoading={ statsLoading } />
            </section>
        </main>

    )
}
String.prototype.toUsername = function(){
    return this?.split('@') && this?.split('@')[ 0 ];
}
export default DashboardPage