/* eslint-disable react/prop-types */
import React, { useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Container } from 'react-bootstrap';
import history from '../../../utils/history'
import { ROUTES } from '../../../constants/appRoutes';
import { NOTIFICATION_TYPES } from '../../../constants/app';
import { notification } from '../../../services/notification';
import { createAssessment, AsyncGetSite } from '../../../middleware/assessments';
import { setItem } from '../../../utils/cache';
import { queryStringToObject } from '../../../utils/helpers'
import { getUser, isBlockedSite, setTour , mixpanelCommonData } from 'utils/helpers';
import { MixpanelContext } from 'utils/tracking';
import { EVENTS } from 'constants/app'
import { getItemParse , getItem } from 'utils/cache'
const RedirectAuth = (props) => {
    const mixpanel = useContext(MixpanelContext)
    const dispatch  = useDispatch();
    const checkValidAssessmentData = () =>{
        const assessmentForm = getItemParse('assessmentForm')
        return assessmentForm.colors && assessmentForm.websiteName
    }

    const setSite = async (queryData) => {
        if(queryData.siteId){
            const site = await AsyncGetSite(queryData.siteId)
            setItem('site',site)
            history.push(ROUTES.DASHBOARD)
        }else{
            isBlockedSite(queryData.status) ? history.push(ROUTES.BILLING) : history.push(ROUTES.SITES)
        }
    }
    useEffect(()=> {
        const queryData = queryStringToObject(props.history.location.search)
        if (queryData?.token?.length) {
            const test = queryData.test === 'true'
            setItem('user', { accessToken: queryData.token,enabled: true, test: test });
            if(test){
                mixpanel.track(`${ EVENTS.loginUser } (${ queryData.provider } )`,mixpanelCommonData({}))
                setSite(queryData)
                notification(NOTIFICATION_TYPES.SUCCESS, 'Login Successfully');
            }else if(getItem('assessmentForm') && checkValidAssessmentData()){
                const user= getUser();
                user[ 'test' ] = true
                user[ 'enabled' ] = true
                setItem('user', user)
                const assessmentData = getItemParse('assessmentForm')
                setTour()
                mixpanel.track(`Complete Registration (${ queryData.provider } )`,mixpanelCommonData({}))
                dispatch(createAssessment(assessmentData,true,true))

            }else{
                history.push(ROUTES.ASSESSMENT)
                //notification(NOTIFICATION_TYPES.SUCCESS, 'Please fill assessment');
            }

        } else if (queryData?.error){
            history.push(ROUTES.LOGIN)
            notification(NOTIFICATION_TYPES.ERROR, 'Something went wrong please try again');
        }
    },[ props.history.location.search ]);

    return(
        <section className="login-section main-section">
            <Container>
            </Container>
        </section>
    )
}

export default withRouter(RedirectAuth);
