/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types';
import { resendCode, emailVerification } from '../../middleware/auth'
import ButtonLoader from 'components/core/loader/button-loader'
import WebTemplatePreview from 'components/assessment/WebTemplatePreview'
import { MixpanelContext } from 'utils/tracking';

import
{
    //Container,
    Row,
    Col,
    Form,
    //Carousel,
    Button
}
    from 'react-bootstrap';
import { renderOTPField } from '../../utils/formUtils'
import { registerValidate as validate } from '../../utils/validates'
import { headerLinksTemplate } from 'utils/helpers'
import { reduxForm } from 'redux-form';
import { Field } from 'redux-form';
import { getAllSites } from 'middleware/assessments/'
import { EVENTS } from 'constants/app'
//import accountSlider1 from '../../public/images/account-slider-1.png';
import { useDispatch, useSelector } from 'react-redux';
//import congratesAnimation from '../../images/congrates-animation.gif';
const ConfirmAccount = (props) => {
    const mixpanel = useContext(MixpanelContext)
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.user.loading )
    const confirmAcouuntForm = useSelector((state) => state.form.confirmAcouuntForm )
    const siteData = useSelector((state) => state.assessment.sites) || {}
    const templateView = {
        header: siteData[ 0 ]?.header,
        colors: siteData[ 0 ]?.colors || [],
        logoUrl: siteData[ 0 ]?.logoUrl,
        logoText: siteData[ 0 ]?.websiteName,
        coverImage: siteData[ 0 ]?.coverImage,
        readOnly: true,
        headerLinks: headerLinksTemplate(),
    }
    const { handleSubmit } = props
    useEffect(() => {
        dispatch(getAllSites('?size=1'))
    },[])

    const handleEvents = (data) => {
        mixpanel.track(EVENTS.confirmAccount,data)
    }
    const submitData = (data) => {
        dispatch(emailVerification(data, handleEvents))
    }

    const handleResendCode = () => {
        dispatch(resendCode())
    }

    return(
        <section className="forgot-password-section main-section main-form-section">
            <div className="signup-container confirm-container">
                <Row className="positionUnset align-items-center">
                    <Col className="crausel-side crausel-side-left col-6 text-center">
                        <div className="signup-preview-wrap">
                            <div className='wizrd-blog-preview wizrd-inner-preview'>
                                {/* <div className="congrats-animation">
                                    <img src={ congratesAnimation } className="congrats-animation-img1" />
                                    <img src={ congratesAnimation } className="congrats-animation-img2"  />
                                    <img src={ congratesAnimation } className="congrats-animation-img3" />
                                </div> */}
                                <WebTemplatePreview data={ templateView } />
                            </div>
                            {/* <Carousel controls={ false } className="carousel slide" id="sampleSlide">
                                <Carousel.Item className="carousel-item">
                                    <img src={ accountSlider1 } alt='slider1' />
                                </Carousel.Item>
                                <Carousel.Item className="carousel-item">
                                    <img src={ accountSlider1 } alt='slider2'/>
                                </Carousel.Item>
                                <Carousel.Item className="carousel-item">
                                    <img src={ accountSlider1 } alt='slider3'/>
                                </Carousel.Item>
                            </Carousel> */}
                        </div>
                    </Col>
                    <Col className="col-6 form-side form-side-right">

                        <div className="forget-forms signup-forms">

                            <Form className="form" onSubmit={ handleSubmit(submitData) }>
                                <div className="forget-form-inner">
                                    <div className="forget-forms-element">
                                        <div className="small-wrapper">
                                            <h1 className="section-heading">
                                                Confirm account
                                            </h1>
                                            <p className="heading-detail">
                                                Please enter confirmation code.
                                            </p>

                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Label>Code:</Form.Label>
                                                <Field
                                                    label="Code"
                                                    name="code"
                                                    type="text"
                                                    numInputs={ 6 }
                                                    component={ renderOTPField }
                                                    formClass="otpFieldsInput"
                                                />
                                                <Form.Text className="form-text">
                                                    Review your email!
                                                </Form.Text>
                                            </Form.Group>

                                        </div>
                                    </div>
                                    <div className="forgot-btns">
                                        <div className="small-wrapper">
                                            { confirmAcouuntForm?.values?.code ?

                                                <ButtonLoader
                                                    button={  <Button className="btn btn-primary" type="submit">
                                                        Continue
                                                    </Button> }
                                                    loadingText={ 'Continue' }
                                                    loading={ loading }
                                                    className="btn btn-primary"
                                                /> :  <Button className="btn btn-primary" disabled={ true } type="button">
                                                    Continue
                                                </Button> }

                                            <p className="heading-detail">
                                                Didn't receive code  <a href="#" onClick={ handleResendCode } >Resend</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </Form>

                        </div>
                    </Col>
                </Row>
            </div>
        </section>

    )}

ConfirmAccount.propTypes = {
    handleSubmit: PropTypes.func
};

export default reduxForm({
    form: 'confirmAcouuntForm',
    validate
})(ConfirmAccount);
