import React, { useEffect, useState, useContext } from 'react';
import { Link, withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logoutUser } from '../../middleware/auth';
import history from 'utils/history'
import PropTypes from 'prop-types';
import _ from 'lodash'
import {
    ChevronRight,
    DashboardMenuIcon,
    BlogMenuIcon,
    DrawerArrowIcon,
    TravelIcon,
    EditSiteMenuIcon,
    ViewWebsiteIcon,
    // MarketingMenuIcon,
    // SupportMenuIcon,
    //NotificationIcon,
} from '../../utils/svg'
import whiteLogo from '../../images/header/webFin-white-logo.svg';
import profilePic from 'images/setting-icon.svg';
import { Dropdown } from 'react-bootstrap';
import { ROUTES } from 'constants/appRoutes';
import { getSite, isBlockedSite, getTour, getUser, mixpanelCommonData, isTravelTemplate } from 'utils/helpers';
import { MixpanelContext } from 'utils/tracking';
import { EVENTS } from 'constants/app'
const SideBar = (props) => {
    const dispatch  = useDispatch();
    const [ isOpen, setOpen ] = useState(false)
    const isIpad = window.outerWidth <= 1024;
    const { toggleMobileSideBar, location: { pathname } } = props
    const [ isSideBarActive, setSidbarActive ] = React.useState(!isIpad);
    const data = useSelector(state => state.user.sessionData?.data?.data)
    const theme = useSelector((state) => state.theme)
    const sidebar2 = useSelector((state) => state.theme.sidebar2 )
    const mixpanel = useContext(MixpanelContext);
    const site = getSite();
    const user = getUser();
    const logout = () => {
        dispatch(logoutUser())
    }

    useEffect(() => {
        setOpen(sidebar2)
    },[ sidebar2 ])

    useEffect(() => {
        if(pathname === ROUTES.DASHBOARD){
            const timer1 = setTimeout(() => setOpen(getTour('dashboard')), 3.5 * 1000);
            return () => {
                setOpen(false)
                clearTimeout(timer1)
            }
        }

    },[ pathname ])
    useEffect(()=> {
        if(!localStorage.site){
            setSidbarActive(false)
        }else if(!isIpad){
            setSidbarActive(true)
        }
    },[ pathname ])

    const handleViewWebSite = (event) => {
        event.preventDefault();
        const url = `https://${ site?.customDomain || site?.domain }`;
        mixpanel.track('View Website', mixpanelCommonData({ siteId: site?.id, url: url, 'user identifier': data?.user?.id }));
        window.open(
            url,
            '_blank'
        );
    }

    const handleClickDomain = () => {
        mixpanel.track(EVENTS.clickDomain, mixpanelCommonData({ siteId: site?.id, 'user identifier': user?.id }));
        history.push(ROUTES.DOMAIN)
    }

    const handleClickBilling = () => {
        mixpanel.track(EVENTS.clickOnBilling, mixpanelCommonData({}));
        history.push(ROUTES.BILLING)
    }

    const handleClickEditSite = () => {
        mixpanel.track(EVENTS.clickOnEditSite, mixpanelCommonData({}));
        history.push(ROUTES.EDIT_SITE)
    }
    return(
        <aside className={ `dashboard-menu ${ isSideBarActive  || toggleMobileSideBar ? 'toggle-sidebar' : 'toggle-sidebar-closed' }` }>
            {!isIpad && <div className="drawer-toggle" onClick={ () => setSidbarActive(!isSideBarActive) }>
                {/* <span className="desktop-drawer-icon"> */}
                <DrawerArrowIcon />
                {/* </span>
                <span className="mobile-drawer-icon">
                    <MobileDrawerArrowIcon />
                </span> */}
            </div>}
            <div className="sidebar-logo">
                <a>
                    <img src={ whiteLogo } alt='logo' />
                </a>
            </div>
            <div className="sidebar-view-site">
                {!_.isEmpty(site) && !isBlockedSite(data?.user?.status) &&  <a href={ `https://${ site.domain || site.customDomain }` } onClick={ handleViewWebSite } rel="noreferrer" target='_blank' >
                    <span>View Website</span>
                    <ViewWebsiteIcon />
                </a>}
            </div>
            <ul>
                {  !_.isEmpty(site) && !isBlockedSite(data?.user?.status) && <>
                    <li className={ `${ theme.sidebarActive === ROUTES.DASHBOARD ?  'active' : '' }` }>
                        <Link to={ ROUTES.DASHBOARD }>
                            Dashboard
                            <DashboardMenuIcon />
                        </Link>
                    </li>
                    {!isTravelTemplate() &&
                    <li className={ `${ theme.sidebarActive === ROUTES.BLOGS ?  'active' : '' }` } >
                        <Link to={ ROUTES.BLOGS }>
                            Blog
                            <BlogMenuIcon />
                        </Link>
                    </li>}
                    <li className={ `${ theme.sidebarActive === ROUTES.EDIT_SITE ?  'active' : '' }` } >
                        <Link to={ '#' } onClick={ handleClickEditSite } >
                            Edit Site
                            <EditSiteMenuIcon />
                        </Link>
                    </li>
                    {isTravelTemplate() &&  <li className={ `${ theme.sidebarActive === ROUTES.TRAVEL_AGENCY ?  'active' : '' }` } >
                        <Link  to={ ROUTES.TRAVEL_AGENCY } >
                            Travel Agency
                            <TravelIcon />
                        </Link>
                    </li>

                    }
                </>}
                {/* pathname !== ROUTES.SITES && _.isEmpty(site) && isSubscribed(data?.user?.status) &&
                    <li className={ `${ theme.sidebarActive === ROUTES.ASSESSMENT ?  'active' : '' }` }>
                        <Link to={ ROUTES.ASSESSMENT }>
                            Add Site
                            <DashboardMenuIcon />
                        </Link>
                    </li>
                /*}
                {/* <li className={ `${ theme.sidebarActive === '' ?  'active' : '' }` } >
                    <Link to="#">
                        Marketing
                        <MarketingMenuIcon />
                    </Link>
    </li>*/}
                {/* <li className={ `${ theme.sidebarActive === '' ?  'active' : '' }` } >
                    <Link to="#">
                        Support
                        <SupportMenuIcon />
                    </Link>
                </li> */}
                <li className="header-profile-img" data-tut="reactour_dash_sitemenu">
                    <Dropdown onClick={ () => {
                        !toggleMobileSideBar && setSidbarActive(true)
                    } } className='dropdown-22' show={ pathname === ROUTES.DASHBOARD && isTravelTemplate() && isOpen ? true : undefined } >
                        <Dropdown.Toggle >
                            <span>
                                <span className="nav-profile-pic">
                                    <img src={ profilePic } />

                                </span>
                            </span>
                            <span className="dropdown-label">My Account</span>
                            <ChevronRight />
                        </Dropdown.Toggle>
                        <div className='ddd'>

                            <Dropdown.Menu className='dropdown-menu' menuAlign="left">
                                { !isBlockedSite(data?.user?.status) && <Dropdown.Item onClick={ () => history.push(ROUTES.SITES) }> My Websites</Dropdown.Item> }
                                { !_.isEmpty(site) && !isBlockedSite(data?.user?.status) && <Dropdown.Item onClick={ handleClickDomain }>Domain</Dropdown.Item> }
                                <Dropdown.Item onClick={ handleClickBilling }>Billing</Dropdown.Item>
                                <Dropdown.Item onClick={ () => history.push(ROUTES.USER_PROFILE) }>Profile</Dropdown.Item>
                                <Dropdown.Item onClick={ logout }>Log out</Dropdown.Item>
                            </Dropdown.Menu>
                        </div>
                    </Dropdown>
                    {/* <Link to='#' className="notification-menu">
                        <span className="notification-link">
                            <NotificationIcon />
                            <span className="notification-bubble">1</span>
                        </span>

                    </Link> */}
                </li>
            </ul>
        </aside>)
}
SideBar.propTypes = {
    toggleMobileSideBar: PropTypes.bool,
    location:  PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }),
    pathname: PropTypes.string.isRequired,
}

export default withRouter(SideBar)
