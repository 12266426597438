import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash'
import { getPlans } from 'middleware/plan';
import { getAllSitesWP } from 'middleware/assessments'
import { PLAN_TYPE } from 'constants/app';
import ButtonLoader from 'components/core/loader/button-loader';
import { convertCurrencyWithOutFraction } from 'services/currency';
import { formatNumber } from 'services/number';
import { convertPriceInDollerAndCent, isAnnualPlan, isBusinessPlan } from 'utils/helpers';
import Skeleton from 'react-loading-skeleton';
import { MixpanelContext } from 'utils/tracking';
import PlanDowngradeModal from './planDowngradeModal'
import { EVENTS } from 'constants/app'
const PlanList = (props) =>  {
    const mixpanel = useContext(MixpanelContext)
    const { planSelectionHandler, selectedPlanId,redirectAssessment, planType = PLAN_TYPE.ANNUAL_PLAN } = props;
    const dispatch = useDispatch();
    const [ open, setOpen ] = useState(false)
    const [ currentPlan, setCurrentPlan ] = useState(null)
    const planData = useSelector((state) => state.plan.plans)
    const sites = useSelector((state) => state.assessment.sites)
    const plansLoading = useSelector((state) => state.plan.plansLoading )
    const subscriptionData = useSelector((state) => state.subscription)
    const selectedPlan = (planData[ planType ] || [])?.filter((item) => selectedPlanId === item.id)[ 0 ]

    useEffect(() => {
        if (!planData?.length) {
            dispatch(getPlans());
        }
        dispatch(getAllSitesWP())
    }, []);

    const currentPlanSelection = (plan) => {
        if(plan.numberOfSites < sites?.length ){
            setOpen(true)
        }else{
            mixpanel.track(EVENTS.clickOnPlan,{ plan: plan })
            planSelectionHandler(plan);
        }

    }
    const hideModal = () => {
        setOpen(false)
        setCurrentPlan(null)
    }

    const renderFreePlan = (plan) => {
        return (<>
            <li>Get a beautiyful blog in seconds!</li>
            <li>{plan?.numberOfSites || 0} Website</li>
            <li>1 Custom Sub-Domain</li>
            <li>{formatNumber(plan?.viewsPerMonth)} Views</li>
            <li>{+plan?.storageInMb || 100} MB of space </li>
        </>)
    }

    const renderPaidPlan = (plan) => {
        const viewsPerMonth = plan?.viewsPerMonth ? `${ formatNumber(plan?.viewsPerMonth) } Views` : 'Unlimited Views / Months';

        return (<><li>{plan?.numberOfSites || 0} Websites</li>
            <li>Connect Custom Domain</li>
            <li>{viewsPerMonth}</li>
            <li>{(+plan?.storageInMb / 1024) || 0} GB space </li>
            <li><b>Collect email subscribers<span className={ 'w-100 d-flex' }>(Up to {formatNumber(plan?.emailSubscribers)})</span></b></li>
        </>)
    }

    const renderPlanList = () => {
        const plans = (planData[ planType ] || [])?.map(plan => {
            const amountFactor = convertPriceInDollerAndCent(plan?.price);
            const annualPrice = convertCurrencyWithOutFraction(plan?.price)
            let price = '';
            if (isAnnualPlan(planType)) {
                price = <>${amountFactor[ 0 ]} <sup>{amountFactor[ 1 ]}</sup><sub>/mo</sub></>
            } else {
                price = <>{annualPrice} <sub>/mo</sub></>
            }

            return (plan?.price ? <Col key={ plan.id } sm={ 12 } xl={ 6 } md={ 6 }>
                <div className={ selectedPlanId == plan?.id ? 'active new_plan_box' : 'new_plan_box' }>
                    <div className="plan-detail">
                        {isAnnualPlan(planType) && <span className="annual-box">Annual</span>}
                        <div className="plan-detail-inner">
                            <h4 className={ 'plan-name' + (isBusinessPlan(plan?.name) ? ' businessPlan' : '') }>{plan?.name}</h4>
                            <ul className="plan-feature">
                                {plan?.price ? renderPaidPlan(plan) : renderFreePlan(plan)}
                            </ul>
                            <div className="plan-price">{price}</div>
                            {isAnnualPlan(planType) && <div className="billBYyear">Billed at {annualPrice} / yr.</div>}
                            <div className="plan-action">
                                {!planSelectionHandler && <button className="btn btn-primary" onClick={ () => redirectAssessment() } >Try Now</button>}
                                {planSelectionHandler && <ButtonLoader
                                    button={ <Button disabled={ selectedPlanId == plan?.id } onClick={ () =>{
                                        setCurrentPlan(plan)
                                        currentPlanSelection(plan)
                                    } } variant="primary">{selectedPlanId == plan?.id ? 'Active' : 'Choose this plan'}</Button> }
                                    loading={ subscriptionData.isLoading && (currentPlan?.id === plan?.id) }
                                    loadingText={ 'Changing' }
                                    className={ 'removeHover' }

                                />}
                            </div>
                        </div>
                    </div>
                </div>
            </Col> : null)
        })

        return <>{plans}<PlanDowngradeModal planSelectionHandler={ planSelectionHandler }  sites={ sites }  selectedPlan={ selectedPlan }  subscriptionData={ subscriptionData } hideModal={ hideModal } plan={ currentPlan }  open={ open }  /></>;
    }

    return <Row className="no-gutters">{plansLoading && _.isEmpty(planData) ? <PlanListSkelton loading={ plansLoading } /> : renderPlanList() }</Row>
}

const PlanListSkelton = (props) => {
    const { loading } = props
    if(loading){
        const arr  = [ ...Array(4).keys() ]
        return arr.map((item) => {
            return(<Col key={ item } sm={ 12 } xl={ 6 } md={ 6 }>
                <div className={ 'new_plan_box' }>
                    <div className="plan-detail">
                        <div className="plan-detail-inner">
                            <h4 className='plan-name'><Skeleton count={ 1 } width={ '100px' } /></h4>
                            <ul className="plan-feature">
                                <Skeleton count={ 1 } width={ '100%' } />
                                <Skeleton count={ 1 } width={ '100%' } />
                                <Skeleton count={ 1 } width={ '100%' } />
                                <Skeleton count={ 1 } width={ '100%' } />
                            </ul>
                            <div className="plan-price"><Skeleton count={ 1 } width={ '80px' } /></div>
                            <div className="billBYyear"><Skeleton count={ 1 } width={ '100px' } /></div>
                            <div className="plan-action">
                                <Skeleton count={ 1 } width={ 200 } className="buttonSkel"/>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>)
        })
    }
    return null
}
PlanListSkelton.propTypes = {
    loading: PropTypes.bool,
}

PlanList.propTypes = {
    redirectAssessment: PropTypes.func,
    planSelectionHandler: PropTypes.func,
    selectedPlanId: PropTypes.number,
    planType: PropTypes.string
};

export default PlanList
