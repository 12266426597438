import React from 'react'
import { Button } from 'react-bootstrap'
import PropTypes from 'prop-types';
const AssessmentFooter = (props) => {
    //const isIpad = window.outerWidth <= 769;
    const { valid, prevPage , isFinalScreen, onSubmit } = props
    //if(isIpad)
    return (
        <div className="step-btns">
            { prevPage && <div className="step-btn-inner step-btn-left">
                <div className="step-btn">
                    <Button onClick={ prevPage } type="button" className="btn-secondary-2" variant="secondary" >
                        Back
                    </Button>
                </div>
            </div> }
            <div className="step-btn-inner step-btns-right"> <div className="step-btn">
                { valid  ?
                    <Button type="submit" onClick={ onSubmit }  className="btn-primary-2" variant="primary">
                        { isFinalScreen ? 'Finish!': 'Next' }
                    </Button>
                    :
                    <Button type="button" disabled={ true } className="btn-primary-2" variant="primary">
                        Next
                    </Button>}
            </div>
            </div>
        </div>
    )
    // else
    //     return null
}

AssessmentFooter.propTypes = {
    valid: PropTypes.bool,
    prevPage: PropTypes.func,
    isFinalScreen: PropTypes.any,
    onSubmit: PropTypes.func
}

export default AssessmentFooter