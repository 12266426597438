/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Modal, Row, Col,Button,Form } from 'react-bootstrap'
import { SOCIAL_MEDIA , SOCIAL_BUSINESS } from 'constants/app'
import { isTravelTemplate } from 'utils/helpers'
const SocialMedia =(props) => {
    const { onlyBusiness, openModal ,site, setOpenModal, values, setValues, connectData, errors,setErrors ,connecting, socialMediaLinks } = props

    const [ inputRadio , setInputRadio ] = useState(onlyBusiness ? 'yelp-business' :'facebook')
    const validateUrls = (event) =>{
        switch(inputRadio){
        case 'facebook':
            return !(event.target?.value?.match('facebook.com') || event.target?.value?.match('facebook.co')) ? 'should be correct domain name' : false
        case 'instagram':
            return !(event.target?.value?.match('instgram.com') || event.target?.value?.match('instagram.co')) ? 'should be correct domain name' : false
        case 'twitter':
            return !(event.target?.value?.match('twitter.com') || event.target?.value?.match('twitter.co')) ? 'should be correct domain name' : false
        case 'linkedin':
            return !(event.target?.value?.match('linkedin.com') || event.target?.value?.match('linkedin.co')) ? 'should be correct domain name' : false
        case 'youtube':
            return !(event.target?.value?.match('youtube.com') || event.target?.value?.match('youtube.co')) ? 'should be correct domain name' : false
        }
    }
    const handleChange = (event) => {
        const errorsObj = Object.assign({},errors)
        const valuesObj = { ...values }
        errorsObj[ inputRadio ] = event.target.value && (!(event.target.value.indexOf('http://') == 0 || event.target.value.indexOf('https://') == 0) || validateUrls(event))
        valuesObj[ inputRadio ] = event.target.value
        if(!event.target.value){
            delete valuesObj[ inputRadio ]
        }
        setValues(valuesObj)
        setErrors(errorsObj)
    }
    const handleRadioChange = (event) => {
        setInputRadio(event.target.value)
    }
    useEffect(() => {
        if(!_.isEmpty(socialMediaLinks)){
            setValues(socialMediaLinks)
        }
    },[ socialMediaLinks ])

    const socialMediaArr = isTravelTemplate() ? (onlyBusiness ? SOCIAL_BUSINESS :  _.concat(SOCIAL_MEDIA , SOCIAL_BUSINESS))  :  SOCIAL_MEDIA
    return(<>
        <Modal.Header closeButton>
            <div className="logo-upload-header">
                <Row>
                    <Col className="col-12">
                        <Modal.Title>Social Media</Modal.Title>
                    </Col>
                </Row>
            </div>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col className="col-8 ">
                    <Row>
                        { socialMediaArr.map((item, index) =>
                            <Col className="col-6" key={ index }>
                                <div className={ `form-group ${ item.value === (inputRadio) && 'active'  } ${ errors[ item.value ] && 'err' }` }>
                                    <div className={ 'styled-radio-btn' } id={ item.value }>
                                        <input
                                            type="radio"
                                            onChange={ handleRadioChange }
                                            id={ item.value }
                                            name={ 'social-icon' }
                                            value={ item.value }
                                            className="styled-radio"
                                            checked={ item.value === (inputRadio || 'facebook') }
                                        />
                                        <div className='input-radio-label'>
                                            <span className='emojiText mb-0 mt-1'>
                                                {item.name}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Col>)
                        }
                    </Row>
                </Col>
                <Col className="col-4 social-url-form">
                    <div className="social-url-title">
                        <h4>Link To: {inputRadio}</h4>
                        <div className="social-url-img" dangerouslySetInnerHTML={ { __html: socialMediaArr.filter((item)=> item.value === inputRadio)[ 0 ]?.imgUrl } }></div>
                        <div className="form-group">
                            <Form.Label>URL:</Form.Label>
                            {inputRadio &&
                                <input
                                    type='text'
                                    key={ inputRadio ? `notLoadedYet${ inputRadio }` : `loaded${ inputRadio }` }
                                    onChange={ handleChange }
                                    placeholder="Enter your URL"
                                    label="URL"
                                    name='url'
                                    defaultValue={ values[ inputRadio ] }
                                    className='form-control'/>}
                        </div>
                        { errors && errors[ inputRadio ] && (typeof errors[ inputRadio ] === 'string' ?  <span className='field_error'>{ errors[ inputRadio ] }</span> :  <span className='field_error'>should be http:// or https://</span>)}
                    </div>
                    <div className="modal-btns">
                        { connecting ? <Button disabled={ true } variant="primary">connecting...</Button> : <Button onClick={ () => connectData() } disabled={ Object.values(errors).includes(true) || Object.values(errors).includes('should be correct domain name') } variant="primary">connect</Button> }
                    </div>
                </Col>
            </Row>
        </Modal.Body>
    </>)
}

SocialMedia.propTypes = {
    errors: PropTypes.object,
    values: PropTypes.object,
    setValues: PropTypes.func,
    setErrors: PropTypes.func,
    openModal: PropTypes.bool,
    site: PropTypes.object,
    setOpenModal: PropTypes.func,
    connectData: PropTypes.func,
    connecting: PropTypes.bool,
    socialMediaLinks: PropTypes.object,
    onlyBusiness: PropTypes.bool
}

export default SocialMedia