import { toast } from 'react-toastify';

import { MESSAGE, NOTIFICATION_TYPES } from '../../constants/app';

export const notification = (type = NOTIFICATION_TYPES.INFO, message) => {
    let msg = message || '';
    if (!msg.length) {
        if (type === NOTIFICATION_TYPES.ERROR) {
            msg = MESSAGE.SOMETHING_WRONG
        } else {
            return;
        }
    }
    toast[ type ](msg, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}
