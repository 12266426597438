import React from 'react'
import { Col, Row, Container, Button } from 'react-bootstrap'
import webFinLogo from 'images/header/wizrd-logo.png';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ButtonLoader from 'components/core/loader/button-loader'
import { useSelector } from 'react-redux'
// import {
//     WizrdLogo,
// } from '../../utils/svg';

const AssessmentHeader = (props) => {
    //const isIpad = window.outerWidth <= 1024;
    const submitting = useSelector((state) => state.assessment.isCreating )
    const { valid, prevPage , isFinalScreen, onSubmit } = props
    return (
        <header className={ 'main-header' }>
            <Container>
                <Row className="header-top assesment-header">
                    <Col className="header-logo">
                        <div className="navbar-brand">
                            <Link to={ '/' } className="navbar-item" title="Logo">
                                <img src={ webFinLogo } alt="WebFin" />
                            </Link>
                        </div>
                    </Col>
                    {/* { !isIpad &&
                        <> */}
                    <Col className="assesment-btns">
                        <div className="step-btns step-btns-left">
                            <div className="step-btn">
                                { prevPage && <Button onClick={ prevPage } type="button" variant="secondary" className="btn-secondary-2" >
                                    Back
                                </Button> }
                            </div>
                        </div>
                        <div className="step-btns step-btns-right">
                            <div className="step-btn">
                                <span>
                                    { valid  ?
                                        <ButtonLoader
                                            button={ <Button type="submit" onClick={ onSubmit } disabled={ submitting } variant="primary" className="btn-primary-2">
                                                { isFinalScreen ? 'Finish!': 'Next' }
                                            </Button> }
                                            loadingText={ 'Saving' }
                                            loading={ submitting }

                                        />
                                        :
                                        <Button type="button" disabled={ true } variant="primary"  className="btn-primary-2">
                                            { isFinalScreen ? 'Finish!': 'Next' }
                                        </Button>}
                                </span>
                                {/* <span className="enter-btn">
                                    <a>
                                        Click next to proceed
                                        <AssesmentNextButton />
                                    </a>
                                </span> */}
                            </div>
                        </div>
                    </Col>
                    {/* </>
                    } */}
                </Row>
            </Container>
        </header>
    )
}

AssessmentHeader.propTypes = {
    valid: PropTypes.bool,
    prevPage: PropTypes.func,
    isFinalScreen: PropTypes.any,
    onSubmit: PropTypes.func
}

export default AssessmentHeader