import React from 'react';
import {
    Container,
    Tab,
    Row,
    Col,
    Nav,
} from 'react-bootstrap';
// import {
//     HowWorkImgVector5,
//     HowWorkImgVector2,
//     HowWorkImgVector3,
//     HowWorkImgVector4
// } from '../../../utils/svg';
import WhatIncludedImg from './images/whats-included.png';
import Heading from '../../../components/core/heading';
import './style.sass'
const WhatIncluded = ()=> {
    return (
        <section className="publisher-whta-included">
            <div className="publisher-whta-included-inner">
                <Container>
                    <Heading
                        className="dark-heading"
                        heading={ <>What’s Included </> }
                    />
                </Container>
            </div>
            <div className="publisher-whta-included-inner">
                <Container>
                    <div className="publisher-whta-included-row">
                        <div className="whta-included-left">
                            <div className="whta-included-img">
                                <img src={ WhatIncludedImg } className="personImg"/>
                                {/* <div className="whta-included-img-vector">
                                    <div className="vector1">
                                        <HowWorkImgVector5 />
                                    </div>
                                    <div className="vector2">
                                        <HowWorkImgVector2 />
                                    </div>
                                    <div className="vector3">
                                        <HowWorkImgVector4 />
                                    </div>
                                    <div className="vector4">
                                        <HowWorkImgVector3 />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="whta-included-right">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Row>
                                    <Col sm={ 10 }>
                                        <Tab.Content className="customTab">
                                            <Tab.Pane eventKey="first">
                                                <h4>Performance + Security  </h4>
                                                <p>Get blazing fast load times, avoid crashes, and easily handle millions of visitors. Built with the same technology used by Netflix, NASA and Twitter.</p>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second">
                                                <h4>SEO </h4>
                                                <p>Our sites are built for Google Search so you get more traffic and rank higher, with no experience necessary.</p>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="third">
                                                <h4>Mobile Responsive </h4>
                                                <p>According to Google, over 50% of website traffic comes from mobile visitors.</p>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="fourth">
                                                <h4>Collect Email Subscribers </h4>
                                                <p>Easily collect email subscribers with built in opt-in forms that help you build and grow and audience.</p>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                    <Col sm={ 2 }>
                                        <Nav variant="pills" className="flex-column">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first">01</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second">02</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="third">03</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="fourth">04</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </div>
                    </div>
                </Container>
            </div>
        </section>
    )
}

export default WhatIncluded;