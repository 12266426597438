/* eslint-disable react/prop-types */
import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types';
import _ from 'lodash'
import { getUser, getSite } from '../../utils/helpers'

export const AssessmentRoute = ({ component: Component, ...rest }) => {
    const user = getUser();
    const site = getSite();
    return (
        <Route
            { ...rest }
            render={ (props) =>
                (
                    !_.isEmpty(site) && user && user.test ?
                        <Redirect
                            to={ {
                                pathname: '/sites',
                                state: { from: props.location },
                            } }
                        />
                        :
                        <Component { ...props } /> )

            }
        />
    )
}
AssessmentRoute.prototype = {
    component: PropTypes.Component,

}