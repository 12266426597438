import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, stopAsyncValidation } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _ from 'lodash'

import { updateUserProfileValidate as validate } from '../../utils/validates';
import { asyncValidate }  from 'utils/asyncValidate';
import { togglePassword, getUser } from '../../utils/helpers';
import { updateCurrentUser } from '../../middleware/auth';
import { normalizePhone } from 'utils/normalize'
import { ROUTES } from 'constants/appRoutes';
import { getCards } from 'middleware/card';
import { getTransactions } from 'middleware/transaction';
import { ProfileForm } from './components/profileForm';

const UserProfilePage =(props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [ asyncLoad, setAsyncLoad ] =  useState(false)
    const userProfileLoading = useSelector((state)=>state.user.userProfileLoading);
    const { handleSubmit , initialize } = props;

    useEffect(async () => {
        dispatch(getCards());
        dispatch(getTransactions());
        dispatch({
            type: 'SET_ACTIVE_SIDEBAR',
            payload: '/user-profile'
        })
        const user = getUser();
        if (user && Object.keys(user).length) {
            initialize(user)
        } else {
            history.push(ROUTES.DASHBOARD)
        }
    }, [])

    const submitData = (data) => {
        Object.entries(data).forEach((k) => [ null, '', undefined ].includes(data[ k ]) && delete data[ k ]);
        dispatch(updateCurrentUser(data, true))
    }

    const handleChange = (value) => {
        if(value){
            setAsyncLoad(true)
            asyncValidate(value).then((result) => {
                !result ? dispatch(stopAsyncValidation('userProfileForm', { userName: 'That username is taken' })) : null
                setAsyncLoad(false)
            })
        }
    }
    const asyncValidateFunc = _.debounce(handleChange, 800);
    const asyncChangeCallback = useCallback(asyncValidateFunc, []);

    return(
        <main className="dashboard-data">
            <section className="dashboard-body">
                <div className="dashboard-header">
                    <div className="dashboard-title">
                        <h4>My Profile</h4>
                    </div>
                </div>
                <ProfileForm
                    handleSubmit={ handleSubmit }
                    userProfileLoading={ userProfileLoading }
                    asyncChangeCallback={ asyncChangeCallback }
                    submitData={ submitData }
                    asyncLoad={ asyncLoad }
                    togglePassword={ togglePassword }
                    normalizePhone={ normalizePhone }
                />
            </section>
        </main>
    )
}

UserProfilePage.propTypes = {
    handleSubmit: PropTypes.func,
    initialize: PropTypes.object,
    submitting: PropTypes.bool,
    asyncValidating: PropTypes.bool
};

export default reduxForm({
    form: 'userProfileForm',
    validate,
    asyncValidate,
    asyncChangeFields: [ 'userName' ]
})(UserProfilePage);
