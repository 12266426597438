import axiosInstance from '../services/api';

import {
    getCardsRequest,
    getCardsSuccess,
    createCardRequest,
    createCardSuccess,
    setDefaultCardRequest,
    setDefaultCardSuccess,
    createCardFailure,
    setDefaultCardFailure,
    getCardsFailure
} from '../actions/card';
import { notification } from 'services/notification';
import { NOTIFICATION_TYPES } from 'constants/app';
import {  getUser , getSite, mixpanelCommonData } from 'utils/helpers'
export const getCards = () => {
    return (dispatch) => {
        dispatch(getCardsRequest())
        axiosInstance.get('/cards').then((response)=>{
            dispatch(getCardsSuccess(response.data.data))
        }).catch((error) => {
            dispatch(getCardsFailure());
            notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message);
        })
    };
};

export const createCard = (token, callbackEvent, callback ) => {
    const site = getSite()
    const user = getUser()
    return (dispatch, getState) => {
        const cardState = [ ...getState()?.card?.cards || [] ]
        callback && callback('Adding Card information',mixpanelCommonData({ siteId: site.id, 'user identifier': user.id }))

        dispatch(createCardRequest())
        axiosInstance.post('/cards', { token }).then((response)=>{
            dispatch(createCardSuccess([ ...cardState, response.data ]))
            callback && callback('Add Card',mixpanelCommonData({ siteId: site.id, 'user identifier': user.id, cardData: response.data }))
            if(callbackEvent) {
                callbackEvent();
            }
        }).catch((error) => {
            dispatch(createCardFailure());
            callback && callback('Failed Adding card information',mixpanelCommonData({ error: error?.response?.data?.message }))
            if(callbackEvent) {
                callbackEvent();
            }
            notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message);
        })
    };
};

export const setDefaultCard = (id) => {
    return (dispatch) => {
        dispatch(setDefaultCardRequest())
        axiosInstance.put(`/cards/${ id }/_set_default`).then(()=>{
            //dispatch(getCards());
            dispatch(setDefaultCardSuccess(id))
        }).catch((error) => {
            dispatch(setDefaultCardFailure());
            notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message);
        })
    };
};