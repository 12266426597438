import moment from 'moment';

export const convertLongFormat = ( date ) => {
    return moment(date).format('L')
}

export const daysRemaining = (date) => {
    var eventdate = moment(date);
    var todaysdate = moment();
    return eventdate.diff(todaysdate, 'days');
}