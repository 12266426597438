import React from 'react';
import PropTypes from 'prop-types';
import {
    Seo100Score,
    Seo56Score,
    Seo80Score,
    Seo86Score,
    Seo83Score
} from '../../../utils/svg';
import './style.sass'
const SeoCircleChart = (props)=> {
    return (
        <div className={ props.color +' seo-chart' }>
            <div className="seo-chart-container">
                <div className="seo-chart-circle">
                    { props.count == 100 && <Seo100Score /> }
                    { props.count == 56 && <Seo56Score /> }
                    { props.count == 80 && <Seo80Score /> }
                    { props.count == 86 && <Seo86Score /> }
                    { props.count == 83 && <Seo83Score /> }
                    <div className="seo-chart-counter">
                        { props.count }
                    </div>
                </div>
                <div className="seo-chart-label">
                    { props.lable}
                </div>
            </div>
        </div>
    )
}
SeoCircleChart.propTypes = {
    count: PropTypes.any,
    lable: PropTypes.string,
    color: PropTypes.string,
};
export default SeoCircleChart;