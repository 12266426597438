import React from 'react';
import { Container, Button } from 'react-bootstrap';
import Heading from '../../../components/core/heading';
import webFinLogo from '../../../images/header/wizrd-logo.svg';
import { TableCheckIcon } from '../../../utils/svg.js';
import Thead from './saving-table/thead';
import Tbody from './saving-table/tbody';
import Tfoot from './saving-table/tfoot';
import PropTypes from 'prop-types';

import './style.sass'
const MoneyTimeSaving = (props)=> {
    const { redirectAssessment } = props
    return (
        <section className="publisher-money-time">
            <Container>
                <div className="publisher-money-time-inner">
                    <div className="publisher-money-save">
                        <Heading
                            className="dark-heading"
                            heading={ <>Here’s How Much <span>Time</span> You Can Save</> }
                        />
                        <div className="publisher-saving-table">
                            <table>
                                <Thead
                                    row1=""
                                    row2={ <>The “Old Way” </> }
                                    row3={ <> <img src={ webFinLogo } /> </> }
                                />
                                <tbody>
                                    <Tbody
                                        row1="Website Set Up"
                                        row2="1 Week to Months"
                                        row3={ <> <TableCheckIcon />Included </> }
                                    />
                                    <Tbody
                                        row1="Professional Web Design"
                                        row2="4-7 Days"
                                        row3={ <> <TableCheckIcon />Included </> }
                                    />
                                    <Tbody
                                        row1="Mobile Responsive"
                                        row2="2-3 Hours"
                                        row3={ <> <TableCheckIcon />Included </> }
                                    />
                                    <Tbody
                                        row1="SEO"
                                        row2="1-2 Hours Per Day"
                                        row3={ <> <TableCheckIcon />Included </> }
                                    />
                                    <Tbody
                                        row1="Maintenance/Plug-in Updates"
                                        row2="4 Hours Per Week"
                                        row3={ <> <TableCheckIcon />Included </> }
                                    />
                                    <Tbody
                                        row1="Email Marketing Setup"
                                        row2="1-2 Hours"
                                        row3={ <> <TableCheckIcon />Included </> }
                                    />
                                </tbody>
                                <Tfoot
                                    title="Total"
                                    label1={ <>Set Up Time: <b>Weeks to Months</b> </> }
                                    label2={ <>Maintenance: <b>4 Hours Per Week</b> </> }
                                    label3={ <>Set Up Time: <b>1-5 Minutes</b> </> }
                                    label4={ <>Maintenance: <b>None!</b> </> }
                                />
                            </table>
                        </div>
                    </div>
                    <div className="publisher-money-save innerGap">
                        <Heading
                            className="dark-heading"
                            heading={ <>Here’s How Much <span>Money</span> You Can Save</> }
                        />
                        <div className="publisher-saving-table">
                            <table>
                                <tbody>
                                    <Tbody
                                        row1="Managed Cloud Hosting"
                                        row2="$30/Month"
                                        row3={ <> <TableCheckIcon />Included </> }
                                    />
                                    <Tbody
                                        row1="CDN"
                                        row2="$20/Month"
                                        row3={ <> <TableCheckIcon />Included </> }
                                    />
                                    <Tbody
                                        row1="Premium Themes"
                                        row2="$99/Year"
                                        row3={ <> <TableCheckIcon />Included </> }
                                    />
                                    <Tbody
                                        row1="Email Marketing"
                                        row2="$10/Month"
                                        row3={ <> <TableCheckIcon />Included </> }
                                    />
                                    <Tbody
                                        row1="Premium Security + SSL"
                                        row2="$15/Month"
                                        row3={ <> <TableCheckIcon />Included </> }
                                    />
                                    <Tbody
                                        row1="Professional Website Set-up & Design"
                                        row2={ <>$1,000 one time <br /> (+Monthly Maintence Fee)</> }
                                        row3={ <> <TableCheckIcon />Included </> }
                                    />
                                </tbody>
                                <Tfoot
                                    title="Total"
                                    label1={ <>Set Up Fee: <b>$1,000</b> </> }
                                    label2={ <>Recurring Fees: <b>$112/Month</b> </> }
                                    label3={ <>Starting at  <b>$25/Month</b> </> }
                                />
                            </table>
                        </div>
                    </div>
                    <div className="saveMoneyBtn">
                        <Button className="btn-primary-2 home-btn" onClick={ () => redirectAssessment() } >Start Saving Time and Money Now!</Button>
                        <span>*No Credit Card Required</span>
                    </div>
                </div>
            </Container>
        </section>
    )
}
MoneyTimeSaving.propTypes = {
    redirectAssessment: PropTypes.func
};

export default MoneyTimeSaving;