import React,{ useContext,useEffect } from 'react'
import './home.sass';
import { mixpanelCommonData } from 'utils/helpers'
import LandingHero from './landing-hero';
import ColorScheme from './color-scheme';
import HowItWork from './how-it-work';
import SeoCompare from './seo-compare';
import KeyFeatures from './key-feature';
import LandingPricing from './home-pricing';
import MoneyTimeSaving from './mone-time-saving';
import WhatIncluded from './what-Included';
import Faq from './faq';
import history from 'utils/history';
import { ROUTES } from 'constants/appRoutes';
import { MixpanelContext } from 'utils/tracking';
import { EVENTS } from 'constants/app'
const HomePage = () => {
    const mixpanel = useContext(MixpanelContext);
    const redirectAssessment = () => {
        mixpanel.track(EVENTS.visitTryforFree,mixpanelCommonData({  }))
        history.push(ROUTES.ASSESSMENT)
    }

    useEffect(() => {
        mixpanel.track(EVENTS.siteVisit,mixpanelCommonData({}))
    },[])
    return(
        <section id='home' className="publisher-landing">
            <LandingHero redirectAssessment={ redirectAssessment }/>
            <ColorScheme redirectAssessment={ redirectAssessment } />
            <HowItWork  redirectAssessment={ redirectAssessment } />
            <SeoCompare redirectAssessment={ redirectAssessment } />
            <WhatIncluded redirectAssessment={ redirectAssessment } />
            <KeyFeatures  redirectAssessment={ redirectAssessment }/>
            <LandingPricing redirectAssessment={ redirectAssessment } />
            <MoneyTimeSaving redirectAssessment={ redirectAssessment } />
            <Faq />
        </section>)
}

export default HomePage
