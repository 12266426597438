import React from 'react'
import Skeleton from 'react-loading-skeleton';
const SiteLoader =() => {
    return(<></>)
}
const PageViewLoader = (props) => {
    const { loading } = props

    if(loading){
        return [ 1, 2, 3, 4, 5 ].map((item) => {
            return(
                <li key={ item } className="">
                    <div className="page-detail customSiteSkel">
                        <div className="page-name">
                            <div className="page-action-btns">
                                <Skeleton count={ 1 } width={ 80 } />
                            </div>
                            <div className="page-edit">
                                <Skeleton count={ 1 } width={ 70 }/>
                            </div>
                        </div>
                        <div className="page-layout">
                            <div className="page-layout-img">
                                <Skeleton count={ 1 }  />
                                <Skeleton reactangle={ true } className="content-col-12" height={ 65 } />
                                <Skeleton reactangle={ true } className="content-col-4" height={ 65 } />
                                <Skeleton reactangle={ true } className="content-col-8" height={ 65 } />
                            </div>

                        </div>
                        <div className="page-action justify-content-end">
                            {/* <div className="page-action-btns">
                                <Skeleton count={ 1 } width={ 80 } />
                            </div> */}
                            <div className="page-edit">
                                <Skeleton count={ 1 } width={ 70 }/>
                            </div>
                        </div>
                    </div>
                </li>)
        })
    }else{
        return null
    }
}
export default SiteLoader
export { PageViewLoader }