import React from 'react';
import PropTypes from 'prop-types';

import './style.sass'
const Tfoot = (props)=> {
    return (
        <tfoot>
            <tr>
                <td>
                    { props.title }
                </td>
                <td>
                    { props.label1 } <br />
                    { props.label2}
                </td>
                <td>
                    { props.label3 } <br />
                    { props.label4 }
                </td>
            </tr>
        </tfoot>
    )
}
Tfoot.propTypes = {
    title: PropTypes.string,
    label1: PropTypes.string,
    label2: PropTypes.string,
    label3: PropTypes.string,
    label4: PropTypes.string,
};

export default Tfoot;