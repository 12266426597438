export const ActionTypes = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    REGISTRATION_REQUEST: 'REGISTRATION_REQUEST',
    REGISTRATION_SUCCESS: 'REGISTRATION_SUCCESS',
    REGISTRATION_FAILURE: 'REGISTRATION_FAILURE',
    EMAIL_VERIFICATION_SUCCESS: 'EMAIL_VERIFICATION_SUCCESS',
    EMAIL_VERIFICATION_FAILURE: 'EMAIL_VERIFICATION_FAILURE',
    RESEND_CODE_SUCCESS: 'RESEND_CODE_SUCCESS',
    RESEND_CODE_FAILURE: 'RESEND_CODE_FAILURE',
    FORGET_PASSWORD_REQUEST: 'FORGET_PASSWORD_REQUEST',
    FORGET_PASSWORD_SUCCESS: 'FORGET_PASSWORD_SUCCESS',
    FORGET_PASSWORD_FAILURE: 'FORGET_PASSWORD_FAILURE',
    GET_USER_SUCCESS: 'GET_USER_SUCCESS',
    UPDATE_USER_PROFILE_REQUEST: 'UPDATE_USER_PROFILE_REQUEST',
    UPDATE_USER_PROFILE_SUCCESS: 'UPDATE_USER_PROFILE_SUCCESS',
    UPDATE_USER_PROFILE_FAILURE: 'UPDATE_USER_PROFILE_FAILURE',
    DOMAIN_STATUS_SUCCESS: 'DOMAIN_STATUS_SUCCESS',
    RESEND_CODE_REQUEST: 'RESEND_CODE_REQUEST',

    GET_STATS_REQUEST: 'GET_STATS_REQUEST',
    GET_STATS_SUCCESS: 'GET_STATS_SUCCESS',
    GET_STATS_ERROR: 'GET_STATS_ERROR',

    DASHBOARD_TUTORIAL_VIDEO: 'DASHBOARD_TUTORIAL_VIDEO',

    GET_FAQS_REQUEST: 'GET_FAQS_REQUEST',
    GET_FAQS_SUCCESS: 'GET_FAQS_SUCCESS',
    GET_FAQS_ERROR: 'GET_FAQS_ERROR'

}
