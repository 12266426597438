export const convertCurrencyWithFraction = ( data ) => {
    return (data || 0).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });
}

export const convertCurrencyWithOutFraction = ( data ) => {
    return (data || 0).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumSignificantDigits: 3
    });
}