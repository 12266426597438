import React , { useEffect, useContext } from 'react';
import {
    Container,
    Accordion,
    Card,
    Button
} from 'react-bootstrap';
import { useDispatch , useSelector } from 'react-redux'
import Heading from '../../../components/core/heading';
import { getFaqs } from '../../../middleware/auth'
import Skeleton from 'react-loading-skeleton';
import { MixpanelContext } from 'utils/tracking';
import { EVENTS } from 'constants/app'
import _ from 'lodash'
import './style.sass'
const Faq = ()=> {
    const mixpanel = useContext(MixpanelContext)
    const dispatch = useDispatch()
    const { faqData, faqLoading }  = useSelector((state) => state.user)
    useEffect(() => {
        dispatch(getFaqs())
    },[])

    const skeltonLoader = () => {
        return faqLoading && _.isEmpty(faqData) &&
        <Card>
            <Skeleton height={ 30 }   count={ 7 } />
        </Card>
    }

    const handleClick = (item) =>{
        mixpanel.track(EVENTS.clickOnFAQ,item)
    }
    return (
        <section className="publisher-faq">
            <Container>
                <div className="publisher-faq-inner">
                    <Heading
                        className="dark-heading"
                        heading={ <>Our FAQ</> }
                    />
                    <Accordion className="accordion-faq">
                        {skeltonLoader()}
                        { faqData.map((item, index) => {
                            return(
                                <Card key={ index } onClick={ () => handleClick(item) } className={ `accordion-card ${ faqData.length -1  == index && 'borderBottom ' }` }>
                                    <Card.Header className="accordion-card-header">
                                        <Accordion.Toggle as={ Button } variant="link" eventKey={ `${ index }` }  className="accordion-card-btn">
                                            { item.question }
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={ `${ index }` } >
                                        <Card.Body className="accordion-card-body">
                                            { item.answer }
                                        </Card.Body>
                                    </Accordion.Collapse>
                                    <div className="changeIcon">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="showIcon" style={ { display: 'none' } }>
                                            <rect x="8.46143" width="3.07692" height="20" rx="1.53846" fill="#5540BF"/>
                                            <rect x="20" y="8.46191" width="3.07692" height="20" rx="1.53846" transform="rotate(90 20 8.46191)" fill="#5540BF"/>
                                        </svg>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="hideIcon" style={ { display: 'none' } }>
                                            <rect x="20" y="8.46191" width="3.07692" height="20" rx="1.53846" transform="rotate(90 20 8.46191)" fill="#5540BF"/>
                                        </svg>
                                    </div>
                                </Card>)
                        })
                        }
                    </Accordion>
                </div>
            </Container>
        </section>
    )
}

export default Faq;