// import { NOTIFICATION_TYPES, MESSAGE } from '../../constants/app';
// import { notification } from '../../services/notification';
import axiosInstance from '../services/api';

import {
    getPlansRequest,
    getPlansSuccess,
    getPlansFailure
} from '../actions/plan';
import { NOTIFICATION_TYPES } from 'constants/app';
import { notification } from 'services/notification';

export const getPlans = () => {
    return (dispatch) => {
        dispatch(getPlansRequest())
        axiosInstance.get('/plans').then((response)=>{
            dispatch(getPlansSuccess(response.data))
        }).catch((error) => {
            dispatch(getPlansFailure())
            notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message);
        })
    };
};
