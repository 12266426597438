import React, { useState , useContext } from 'react';
import { Field } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { createCard } from 'middleware/card';
import { renderFieldPaymentDeatils, renderFieldWG } from 'utils/formUtils';
import { paymentFormValidate as validate } from 'utils/validates'

import
{
    Form,
    Button,
} from 'react-bootstrap';
import ButtonLoader from 'components/core/loader/button-loader';
import { MixpanelContext } from 'utils/tracking';
import {  mixpanelCommonData } from 'utils/helpers'
const AddPaymentMethod = (props) => {
    const stripe = useStripe();
    const form = useSelector((state) => state.form?.addPaymentMethod )
    const cardState = useSelector((state) => state.card )
    const mixpanel = useContext(MixpanelContext)

    const elements = useElements();
    const dispatch = useDispatch();
    const [ error, setError ] = useState(null);

    const handleEvent = (eventType, data) => {
        mixpanel.track(eventType, mixpanelCommonData(data))
    }
    const submitData = async (data) => {
        setError(null);
        if (!stripe || !elements) {
            return;
        }
        const cardElement = elements.getElement(CardNumberElement, CardExpiryElement, CardCvcElement);
        let result = null
        try{
            result = await stripe.createToken( cardElement, { 'name': data.name } );
            if (result?.token) {
                dispatch(createCard(result?.token?.id, props.callbackEvent, handleEvent))
            } else {
                const err = result?.error === 'string' ? result?.error : result?.error?.message
                setError( err );
            }
        }catch(err){
            setError(result?.error?.message);
        }
    };
    const displayError = (event) => {
        event.preventDefault()
        if(props.invalid){
            const errors = form?.syncErrors
            setError(errors.name || errors.cardNumber || errors.cardExpiry || errors.cardCvc)
        }else if(error && error.message){
            setError(error && error.message)
        }else{
            submitData(form.values)
        }
    }

    return (
        <div className="profile-left profile-add-payment">
            <h3 className="profile-subhead">Add Payment Information</h3>
            <Form onSubmit={ (event) => displayError(event) } className="pt-2">
                <Field
                    label="Name on Card:"
                    type="text"
                    name="name"
                    handleChange={ () => setError('') }
                    component={ renderFieldWG }
                    placeholder='Name on Card'
                />
                <Field
                    label="Credit Card Number:"
                    setCardError={ setError }
                    type="text"
                    name="cardNumber"
                    inputName="cardNumber"
                    component={ renderFieldPaymentDeatils }
                    placeholder='0000-0000-000-0000'
                />
                <div className="expiration-date">
                    <Field
                        label="Expiration Date:"
                        type="text"
                        setCardError={ setError }
                        name="cardExpiry"
                        inputName="cardExpiry"
                        component={ renderFieldPaymentDeatils }
                        placeholder='00/00'
                    />
                </div>
                <div className="security-number">
                    <Field
                        label="Security Number:"
                        type="text"
                        setCardError={ setError }
                        name="cardCvc"
                        inputName="cardCvc"
                        component={ renderFieldPaymentDeatils }
                        placeholder='Security Number:'
                    />
                </div>
                <div className="default-error formHeight" >{ error }</div>
                <div className="card-actions">
                    <ButtonLoader
                        button={ <Button type="submit" variant="primary">Add Card</Button> }
                        loading={ cardState.isLoading }
                        loadingText={ 'Adding' }

                    />
                </div>
            </Form>
        </div>
    )
}

AddPaymentMethod.propTypes = {
    handleSubmit: PropTypes.func,
    initialize: PropTypes.object,
    submitting: PropTypes.bool,
    asyncValidating: PropTypes.bool,
    invalid: PropTypes.bool,
    dirty: PropTypes.bool,
    anyTouched: PropTypes.bool,
    callbackEvent: PropTypes.func
};
export default reduxForm({
    form: 'addPaymentMethod',
    validate
})(AddPaymentMethod);
