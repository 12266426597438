import React,{ useState , useEffect , useCallback, useContext } from 'react'
import { Field } from 'redux-form';
import { renderFieldChangeWG,renderField } from '../../utils/formUtils'
import { headerLinksTemplate, assessmentIntialValues, isTravelTemplate } from '../../utils/helpers'
import { getUnsplash } from '../../middleware/assessments'
import PropTypes from 'prop-types';
import { assessmentFormValidate as validate } from '../../utils/validates'
import { reduxForm , stopAsyncValidation } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux'
import { change as reduxChange } from 'redux-form'
import { AvailableIcon, UnAvailableIcon } from '../../utils/svg.js'
import _ from 'lodash';
import { asyncValidateDomain } from 'utils/asyncValidate'
import blogBanner from 'images/blog-banner.png'
import travelBaner from 'images/wizrd-plan-header.jpeg'
import WebTemplatePreview from './WebTemplatePreview'
import
{
    Form,
    Container,
} from 'react-bootstrap';
import UploadImageModal from './shared/UploadImageModal'
import AssessmentHeader from 'pages/assessment/header'
import AssessmentFooter from 'pages/assessment/footer'
import { HEADER, EVENTS } from 'constants/app'
import {
    UploadLogoButtonIcon
} from '../../utils/svg';
import AssessmentStepFooter from './shared/steps-footer';
import { MixpanelContext } from 'utils/tracking';
const StepThree = (props) => {
    const mixpanel = useContext(MixpanelContext)
    const dispatch = useDispatch()
    const [ asyncLoad , setAsyncLoad ] = useState(false)
    const [ domainUrl, setDomainUrl ] = useState('')
    const [ fieldName, setFieldName ] = useState('logoUrl')
    const [ openModal, setModalOpen ] = useState(false)
    const form  = useSelector((state) => state.form.assessmentForm)
    const unsplashImages  = useSelector((state) => state.assessment.unsplashImages)
    const [ colors ,setColors ] = useState( assessmentIntialValues().colors && JSON.parse(assessmentIntialValues().colors) || form?.values?.colors && JSON.parse(form?.values?.colors)  )
    const [ header, setHeader ] = useState({
        heading: form?.values?.header?.heading || isTravelTemplate() ? HEADER.TRAVEL_HEADING : HEADER.HEADING,
        subHeading: form?.values?.header?.subHeading
    })

    const { handleSubmit ,prevPage, finalSubmit } = props;
    const handleToggleModal = (field) => {
        setFieldName(field)
        setModalOpen(!openModal)
        !field && dispatch(reduxChange('assessmentForm', fieldName, null))

    }

    useEffect(()=>{
        const query = 'other'
        dispatch(getUnsplash('/photos',query))
        dispatch(reduxChange('assessmentForm', 'header', header))
        window.scrollTo(0, 0);
    },[]);

    useEffect(() => {
        if(form?.values?.domain ){
            setAsyncLoad(true)
            asyncValidateDomain(form?.values?.domain).then((result) => {
                if(!result.available){
                    dispatch(stopAsyncValidation('assessmentForm', { domain: 'dummy-error' }))
                }else{
                    setDomainUrl(result.domain)
                }
                setAsyncLoad(false)
            })
        }
    },[ ])

    const handleSearch = (event) => {
        let query  = 'other'
        query = event.target.value || query
        dispatch(getUnsplash('/photos',query))
    }
    const clearImage = () => {
        dispatch(reduxChange('assessmentForm', fieldName, null))
    }

    const handleChange = (value) => {
        if(value){
            asyncValidateDomain(value).then((result) => {
                if(!result?.available){
                    dispatch(stopAsyncValidation('assessmentForm', { domain: 'dummy-error' }))
                }else{
                    setDomainUrl(result.domain)
                }
                setAsyncLoad(false)
            })
        }
    }
    const changeDomain = _.debounce(handleChange, 800);
    const changeDomainCallback = useCallback(changeDomain, []);
    const handleDomainChange = (value) => {
        if(value){
            setAsyncLoad(true)
            changeDomainCallback(value)
        }else{
            setAsyncLoad(false)
        }
    }

    const handleRadio = (event) => {
        if(event.target.checked){
            const colorsData =  Object.assign({}, colors)
            colorsData[ event.target.name ] = event.target.value
            setColors(colorsData)
            dispatch(reduxChange('assessmentForm', 'colors', JSON.stringify(colorsData)))
        }
    }
    const handleHeaderChange = (event) => {
        const headerObj = { ...header }
        headerObj[ event.target.name ] = event.target.value
        setHeader(headerObj)
        mixpanel.track(`Change text for ${ event.target.name } (Assessment Flow)`,{ value: event.target.value })
        dispatch(reduxChange('assessmentForm', 'header', headerObj))
    }

    const handleMixpanelEvents = (formName, name, imageUrl) => {
        if(name === 'logoUrl')
            mixpanel.track(EVENTS.uploadLogo,{ formName: formName,fieldName: name, imageUrl: imageUrl })
        else{
            mixpanel.track(EVENTS.headerImage,{ formName: formName,fieldName: name, imageUrl: imageUrl })
        }
    }
    const radioView = (name,value1,value2) => {
        const colorsData =  Object.assign({}, colors)
        return(<div className="font-color-switcher">
            <div className={ `custom-radio white-color-font ${ colorsData[ name ] === value1 && 'active' } ` }>
                <div className="radio-item">
                    <input type="radio" onChange={ handleRadio } value={ value1 } name={ name } id={ `${ name }-white` } checked={ colorsData[ name ] === value1 } required />
                    <label className="label-icon option-white" htmlFor={ `${ name }-white` }><span>A</span></label>
                </div>
            </div>
            <div className={ `custom-radio black-color-font ${ colorsData[ name ] === value2 && 'active' }` }>
                <div className="radio-item">
                    <input type="radio" onChange={ handleRadio } value={ value2 } name={ name } id={ `${ name }-black` } checked={ colorsData[ name ] === value2 } required />
                    <label className="label-icon option-black" htmlFor={ `${ name }-black` }><span>A</span></label>
                </div>
            </div>
        </div>)
    }
    const data = {
        header: form?.values?.header,
        colors: colors || form?.values?.colors,
        logoUrl: form.values.logoUrl,
        logoText: form.values.websiteName,
        coverImage: openModal ? (isTravelTemplate() ? travelBaner : blogBanner) : form.values.coverImage,
        headerLinks: headerLinksTemplate(),
        readOnly: true
    }
    const capitalize = value => value.charAt(0).toUpperCase() + value.slice(1)
    return(
        <div className="template-logo-step template-steppers">
            <Form className="form" onSubmit={ handleSubmit(finalSubmit) }>
                <AssessmentHeader isFinalScreen={ true } prevPage={ prevPage } { ... props } valid={ props.valid && !asyncLoad } />
                <Container>
                    <div className="template-stepper-wrap">
                        <h2 className="stepper-title">Name your website!</h2>
                        <p className="stepper-detail"><b>Optional:</b> Upload your logo and edit your header image, headline + tagline</p>
                        <div className="template-blog-logo template-form-group">
                            <div className="template-logo-upload">
                                <Form.Group>
                                    <label>Website Name</label>
                                    <Field
                                        name="websiteName"
                                        component={ renderField }
                                        minLength={ 1 }
                                        maxLength={ 100 }
                                        placeholder={ 'Your Website Name Here' }
                                        normalize={ capitalize }
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <label>Domain Name</label>
                                    <Field
                                        name="domain"
                                        component={ renderFieldChangeWG }
                                        maxLength={ 14 }
                                        isDomain={ true }
                                        handleChange={ (value) =>  handleDomainChange(value) }
                                        minLength={ 1 }
                                        placeholder={ 'Your Domain Here' }

                                    />
                                    {asyncLoad && <div className="small-loader">
                                        <div className="lds-facebook"><div></div><div></div><div></div></div>
                                    </div>}
                                    { !form?.syncErrors?.domain && !asyncLoad && (form?.asyncErrors?.domain ? <span className={ 'domain-available-status failed' }><UnAvailableIcon /> Unavailable</span> : <span className={ 'domain-available-status success' }><AvailableIcon />Available</span>) }
                                    { !form?.syncErrors?.domain && !asyncLoad && (form?.asyncErrors?.domain ? null : <span className="available-domain-name">Domain: <a href='#' className="colorPrimary">{ domainUrl }</a></span>) }
                                </Form.Group>

                                <div className="logo-upload-instruction">
                                    <p><b>Optional:</b> Upload your Logo</p>
                                    <div className="uploaded-logo-preview">
                                        <div className={ `uploaded-img-preview ${ form.values.logoUrl ? 'uploaded' : '' }` }>
                                            {form.values.logoUrl && <div className="uploaded-cover-img-preview">
                                                <img src={ form.values.logoUrl } onClick={ () => handleToggleModal('logoUrl') } />
                                                <span onClick={ () => dispatch(reduxChange('assessmentForm', 'logoUrl', null)) } className="cover-img-clear">Clear</span>
                                            </div>}
                                            <button  type='button' onClick={ () => handleToggleModal('logoUrl') }>
                                                <span>
                                                    <UploadLogoButtonIcon />
                                                </span>
                                                <span className="uploaded-img-text">Upload</span>
                                            </button>
                                        </div>
                                    </div>
                                    <p className="size-instruction">Recommended Dimensions: <br /> 250 x 50 px</p>
                                    <p className="size-instruction">Recommended File Type: PNG</p>
                                </div>
                            </div>
                            <div className="template-logo-preview">
                                <div className="wizrd-blog-preview wizrd-inner-preview">
                                    <WebTemplatePreview data={ data } noBlogs={ true }/>
                                </div>
                                <div className="template-header-edit">
                                    <div className="template-header-text">
                                        <Form.Group>
                                            <label>Headline</label>
                                            <input className="form-control" type='text' name='heading' defaultValue={ form?.values?.header?.heading }  onChange={ handleHeaderChange } />
                                        </Form.Group>
                                        <Form.Group>
                                            <label>Tagline</label>
                                            <input className="form-control" type='text' placeholder="Enter Your Tagline Here" name='subHeading' defaultValue={ form?.values?.header?.subHeading } onChange={ handleHeaderChange } />
                                        </Form.Group>
                                    </div>
                                    <div className="template-header-hero">
                                        <div className="font-color-selector">
                                            <label>Header Text</label>
                                            {radioView('header-color','#FFFFFF', '#000000')}
                                        </div>
                                        <div className="uploaded-header-img-preview">
                                            <div className={ `uploaded-img-preview ${ form.values.coverImage ? 'uploaded' : '' }` }>
                                                {form.values.coverImage && <div className="uploaded-cover-img-preview">
                                                    <img src={ form.values.coverImage } onClick={ () => handleToggleModal('coverImage') } />
                                                    <span onClick={ () => dispatch(reduxChange('assessmentForm', 'coverImage', null)) } className="cover-img-clear">Clear</span>
                                                </div>}
                                                <button type='button' onClick={ () => handleToggleModal('coverImage') }>
                                                    <span>
                                                        <UploadLogoButtonIcon />
                                                    </span>
                                                    <span className="uploaded-img-text">Upload</span>
                                                </button>
                                            </div>
                                        </div>
                                        <p className="header-img-instruction">
                                            <span>Choose from our library</span>
                                            <br /> OR <br />
                                            <span>Upload your own</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="template-step-action">
                            <UploadImageModal formName={ 'assessmentForm' } handleMixpanelEvents={ handleMixpanelEvents } fieldName={ fieldName } clearImage={ clearImage } previewFile={  form?.values && form?.values[ fieldName ] }  handleSearch={ handleSearch } unsplashImages={ unsplashImages } openModal={ openModal } handleToggleModal={ handleToggleModal } />
                            <AssessmentFooter prevPage={ prevPage } { ...props } />
                        </div>
                    </div>
                </Container>
            </Form>
            <AssessmentStepFooter />
        </div>
    )
}
String.prototype.upcaseWithSpace = function () {
    let data = this.split(' ')
    data = data.map((item) => item.charAt(0).toUpperCase() + item.slice(1))
    return data.join(' ')
};
StepThree.propTypes = {
    handleSubmit: PropTypes.func,
    submitData: PropTypes.func,
    assessmentData: PropTypes.object,
    saveData: PropTypes.func,
    colorPalette: PropTypes.object,
    valid: PropTypes.valid,
    setStep: PropTypes.func,
    prevPage: PropTypes.func,
    finalSubmit: PropTypes.func,
};
export default reduxForm({
    form: 'assessmentForm',
    destroyOnUnmount: false,
    asyncValidateDomain,
    asyncChangeFields: [ 'domain' ],
    validate
})(StepThree);