const initialState = {
    loading: false,
    sidebar2: undefined
}

export default (state = initialState, action) => {
    switch (action.type) {
    case 'SET_ACTIVE_SIDEBAR':
        return { ...state, sidebarActive: action.payload };
    case 'CLOSE_SIDEBAR_2':
        return { ...state, sidebar2: false }
    case 'OPEN_SIDEBAR_2':
        return { ...state, sidebar2: true }
    default:
        return state;
    }
};