import React, { useContext, useEffect } from 'react'
import { Field } from 'redux-form';
import { Modal, Row, Col ,Form, Button } from 'react-bootstrap'
import ButtonLoader from 'components/core/loader/button-loader';
import { normalizePhone } from 'utils/normalize'
import { reduxForm } from 'redux-form';
import { renderFieldWG } from 'utils/formUtils';
import { contactValidate as validate } from 'utils/validates';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux'
import { updateCurrentUser } from 'middleware/auth';
import { mixpanelCommonData } from 'utils/helpers'
import { MixpanelContext } from 'utils/tracking';
import { EVENTS } from 'constants/app'
const Contact =(props) => {
    const { handleSubmit, handleCloseModal, initialize, user } = props
    const mixpanel = useContext(MixpanelContext);

    const userProfileLoading = useSelector((state)=>state.user.userProfileLoading);
    const dispatch = useDispatch()
    const handleEvent = (event,data) => {
        mixpanel.track(EVENTS.contactUpdate, mixpanelCommonData(data));
        handleCloseModal()
    }
    useEffect(() =>{
        initialize({
            phone: user?.phone
        })
    },[])
    const submitData = (data) => {
        dispatch(updateCurrentUser(data,false, handleEvent, '',true))
    }
    return(<>
        <Modal.Header closeButton>
            <div className="logo-upload-header">
                <Row>
                    <Col className="col-12">
                        <Modal.Title>Contact Number</Modal.Title>
                    </Col>
                </Row>
            </div>
        </Modal.Header>
        <Modal.Body>
            <Form onSubmit={ handleSubmit(submitData) } className="phone-number-form">
                <Field
                    name="phone"
                    label="Phone number:"
                    type="text"
                    component={ renderFieldWG }
                    disabled={ userProfileLoading }
                    placeholder='Enter your phone number'
                    normalize={ normalizePhone }
                />
                <ButtonLoader
                    loadingText={ 'Saving' }
                    button={ <Button type="submit" variant="primary">Save</Button> }
                    loading={ userProfileLoading }
                />
            </Form>
        </Modal.Body>
    </>)
}

Contact.propTypes = {
    handleSubmit: PropTypes.func,
    handleCloseModal: PropTypes.func,
    initialize: PropTypes.object,
    user: PropTypes.object
}
export default reduxForm({
    form: 'contactForm',
    validate,
})(Contact);
