import { ActionTypes } from './actionTypes'

export const getCardsRequest = () => {
    return {
        type: ActionTypes.GET_CARDS_REQUEST,
    };
};
export const getCardsSuccess = (data) => {
    return {
        type: ActionTypes.GET_CARDS_SUCCESS,
        payload: data,
    };
};
export const getCardsFailure = (error) => {
    return {
        type: ActionTypes.GET_CARDS_FAILURE,
        error: error,
    };
};

export const createCardRequest = () => {
    return {
        type: ActionTypes.CREATE_CARD_REQUEST,

    };
};
export const createCardSuccess = (data) => {
    return {
        type: ActionTypes.CREATE_CARD_SUCCESS,
        payload: data,
    };
};
export const createCardFailure = (error) => {
    return {
        type: ActionTypes.CREATE_CARD_FAILURE,
        error: error,
    };
};

export const setDefaultCardRequest = () => {
    return {
        type: ActionTypes.SET_DEFAULT_CARD_REQUEST,

    };
};
export const setDefaultCardSuccess = (data) => {
    return {
        type: ActionTypes.SET_DEFAULT_CARD_SUCCESS,
        payload: data,
    };
};
export const setDefaultCardFailure = (error) => {
    return {
        type: ActionTypes.SET_DEFAULT_CARD_FAILURE,
        error: error,
    };
};