import React from 'react'
import PropTypes from 'prop-types'
import closeIcon from '../../../../src/images/close.png'

const ConfirmAlert = (props) => {
    const { onClose, handleAction, heading, subHeading='', btnTitle, linkLabel, handleNavigation, isSecondLinkHide } = props

    const handleCloseButton = () => {
        if(linkLabel) {
            handleNavigation();
        }

        onClose();
    }

    return (
        <div className='confirm-modal'>
            <div className="confirm-modal-header">
                <a className="confirm-modal-close"  onClick={ onClose }>
                    <img src={ closeIcon } />
                </a>
            </div>
            <div className="confirm-modal-body">
                <h2>{ heading || 'Are you sure?' }</h2>
                <p>{subHeading}</p>
                {/* <p>You want to delete this?</p> */}
                <div className="confirm-modal-btns">
                    { handleAction && <div className="confirm-btn">
                        <button className="btn btn-primary"
                            onClick={ (e) => {
                                handleAction(e)
                                onClose();
                            } }
                        >
                            {btnTitle || 'Confirm'}
                        </button>
                    </div> }
                    {!isSecondLinkHide && <div className="cancel-btn">
                        <button className="btn" onClick={ handleCloseButton }>{linkLabel || 'Cancel'}</button>
                    </div>}
                </div>
            </div>
        </div>
    );
}
ConfirmAlert.propTypes = {
    onClose: PropTypes.func,
    handleAction: PropTypes.func,
    handleNavigation: PropTypes.func,
    subHeading: PropTypes.string,
    linkLabel: PropTypes.string,
    heading: PropTypes.any,
    isSecondLinkHide: PropTypes.bool,
    btnTitle: PropTypes.string
}
export default ConfirmAlert;