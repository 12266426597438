import React from 'react';
import { Container } from 'react-bootstrap';
import  { useSelector } from 'react-redux'
import history from 'utils/history'
import { setItem } from 'utils/cache'
import StepTwo from '../../assessment/StepTwo'
import Heading from '../../../components/core/heading';
import './style.sass'
const ColorScheme = ()=> {
    const assessmentForm = useSelector((state) => state.form.assessmentForm)
    const saveData = () =>{
        const values = assessmentForm.values
        if(values){
            setItem('assessmentForm', values)
        }
    }
    const nextPage = () => {
        saveData()
        history.push({
            pathname: '/assessment',
            state: { step: 2 }
        })
    }
    return (
        <section className="publisher-color-scheme">
            <Container>
                <Heading
                    parentClassName="center-heading"
                    className="dark-heading"
                    heading={ <>Try it out! Choose a color scheme or create your own.</> }
                />
                <StepTwo className="landing-color-pallate" isHomePage={ true } saveData={ saveData }  onSubmit={ nextPage }/>
            </Container>
        </section>
    )
}

export default ColorScheme;