import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap'
import ColorPicker from 'components/core/color-picker'
// import UploadImageModal from './UploadImageModal'
import { useDispatch } from 'react-redux'
// import { change as reduxChange } from 'redux-form'
// import blogBanner from 'images/blog-banner.png'
import ButtonLoader from 'components/core/loader/button-loader'
import WebTemplatePreview from 'components/assessment/WebTemplatePreview'
import { getUnsplash } from 'middleware/assessments'
import iphoneImage from '../images/wizrd-iphone.png';
import { hexToHsl , hexToHsv }  from 'utils/helpers'
const CustomColor = (props) => {
    const { data, handleColorsData, isSubmit, loading, onBack } = props
    const color = data.colors && JSON.parse(data.colors)[ 'top-menu' ]
    const hslValue = hexToHsl(color)
    const hsvValue = hexToHsv(color)
    const isIpad = window.outerWidth <= 1024
    // const unsplashImages  = useSelector((state) => state.assessment.unsplashImages)
    const dispatch = useDispatch()
    // const [ openImageModal, setOpenImage ] = useState(false)
    const [ hsl,  setHsl ] = useState(hslValue)
    const [ hsv,  setHsv ] = useState(hsvValue)
    const [ colors ,setColors ] = useState(data.colors && JSON.parse(data.colors) || { })
    const [ active,setActiveBox ] = useState('top-menu')
    const [ isMobileView , setMobileView ] = useState(false)
    // const [ header, setHeader ] = useState({ heading: data?.header?.heading , subHeading: data?.header?.subHeading  })
    //const [ isHeaderBgColor, setHeaderBgColor ] = useState(false)

    useEffect(()=>{
        const query = 'cover'
        dispatch(getUnsplash('/photos',query))
    },[]);
    // const handleSearch = (event) => {
    //     const query = event.target.value
    //     dispatch(getUnsplash('/photos',query))
    // }
    // const [ loading, setLoading ] = useState(false)
    const disableBodyScroll = () => {
        const element =  document.querySelectorAll('.custom-color-modal .modal-content')[ 0 ]
        element.classList.add('stop-scroll')
    }
    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    const element =  document.querySelectorAll('.custom-color-modal .modal-content')[ 0 ]
                    element.classList.remove('stop-scroll')
                }
            }
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ ref ]);
    }

    const handleChangeColor = (d) => {
        isIpad && disableBodyScroll()
        const colorsData =  Object.assign({}, colors)
        colorsData[ active ] = d.hex
        setColors(colorsData)
        setHsl(d.hsl)
        setHsv(d.hsv)
    }
    const handleChange = (event,name) => {
        const colorsData =  Object.assign({}, colors)
        colorsData[ name ] = event.target.value
        setColors(colorsData)
        setActiveBox(name)
        setHsl(null)
        setHsv(null)
    }
    const handleClick = (name) => {
        setActiveBox(name)
        setHsl(null)
        setHsv(null)
    }
    const handleRadio = (event) => {
        if(event.target.checked){
            const colorsData =  Object.assign({}, colors)
            colorsData[ event.target.name ] = event.target.value
            setColors(colorsData)
        }
    }

    // const handleHeaderChange = (event) => {
    //     const headerObj = { ...header }
    //     headerObj[ event.target.name ] = event.target.value
    //     setHeader(headerObj)
    //     dispatch(reduxChange(formName, 'header', headerObj))
    // }

    // const toggleImageModal = () => {
    //     setOpenImage(!openImageModal)
    // }
    // const getBase64 = (base64) => {
    //     dispatch(reduxChange(formName, 'coverImage', base64))
    // }
    const radioView = (name,value1,value2) => {
        const colorsData =  Object.assign({}, colors)
        return(<div className="font-color-switcher">
            <div className={ `custom-radio white-color-font ${ colorsData[ name ] === value1 && 'active' } ` }>
                <div className="radio-item">
                    <input type="radio" onChange={ handleRadio } value={ value1 } name={ name } id={ `${ name }-white` } checked={ colorsData[ name ] === value1 } required />
                    <label className="label-icon option-white" htmlFor={ `${ name }-white` }><span>A</span></label>
                </div>
            </div>
            <div className={ `custom-radio black-color-font ${ colorsData[ name ] === value2 && 'active' }` }>
                <div className="radio-item">
                    <input type="radio" onChange={ handleRadio } value={ value2 } name={ name } id={ `${ name }-black` } checked={ colorsData[ name ] === value2 } required />
                    <label className="label-icon option-black" htmlFor={ `${ name }-black` }><span>A</span></label>
                </div>
            </div>
        </div>)
    }

    const inputViews = () => {

        return(<>
            <div className="color-selector-group">
                <label>
                    <span>Top Menu</span>
                    <span>Font Color</span>
                </label>
                <div className="header-color-selector">
                    <div onClick={ () => handleClick('top-menu') } className={ `color-box-view ${ active ==='top-menu' ? 'active' : '' }` }>
                        <span className="color-selector-preview" style={ { background: colors[ 'top-menu' ] } } ></span>
                        <input
                            type='text'
                            onChange={ (event) => handleChange(event,'top-menu') }
                            className='form-control'
                            defaultValue={ colors[ 'top-menu' ] }
                            value={ colors[ 'top-menu' ] }
                        />
                    </div>
                    <div className="font-color-selector">
                        {radioView('top-menu-font','#FFFFFF','#000000')}
                    </div>
                </div>
            </div>
            <div className="color-selector-group">
                <label>
                    <span>Button</span>
                    <span>Font Color</span>
                </label>
                <div className="header-color-selector">
                    <div onClick={ () => handleClick('button') } className={ `color-box-view ${ active ==='button' ? 'active' : '' }` }>
                        <span className="color-selector-preview" style={ { background: colors[ 'button' ] } }></span>
                        <input
                            type='text'
                            onChange={ (event) => handleChange(event,'button') }
                            className='form-control'
                            defaultValue={ colors[ 'button' ] }
                            value={ colors[ 'button' ] }
                        />
                    </div>
                    <div className="font-color-selector">
                        {radioView('button-font','#FFFFFF','#000000')}
                    </div>
                </div>
            </div>
            {/* <div className="color-selector-group">
                <label>Background</label>
                <div onClick={ () => handleClick('background') } className={ `color-box-view ${ active ==='background' ? 'active' : '' }` }>
                    <span className="color-selector-preview" style={ { background: colors[ 'background' ] } }></span>
                    <input
                        type='text'
                        onChange={ (event) => handleChange(event,'background') }
                        className='form-control'
                        defaultValue={ colors[ 'background' ] }
                        value={ colors[ 'background' ] }
                    />
                </div>
                <div className="font-switcher">
                    {radioView('background-font','#000000','#FFFFFF')}
                </div>
            </div> */}
            {/*<div className="color-selector-group">
                <label>Box Shadow</label>
                <div onClick={ () => handleClick('box-shadow') } className={ `color-box-view ${ active ==='box-shadow' ? 'active' : '' }` }>
                    <span className="color-selector-preview" style={ { background: colors[ 'box-shadow' ] } } ></span>
                    <input
                        type='text'
                        onChange={ (event) => handleChange(event,'box-shadow') }
                        className='form-control'
                        defaultValue={ colors[ 'box-shadow' ] }
                        value={ colors[ 'box-shadow' ] }
                    />
                </div>
        </div>
            <div className="color-selector-group home-bg">
                <div className="color-selector-label">
                    <label>Background</label>
                    <div className="font-switcher">
                        {radioView('home-background-font','#FFFFFF','#000000')}
                    </div>
                </div>
                <div onClick={ () => handleClick('home-background') } className={ `color-box-view ${ active ==='home-background' ? 'active' : '' }` }>
                    <span className="color-selector-preview" style={ { background: colors[ 'home-background' ] } } ></span>
                    <input
                        type='text'
                        onChange={ (event) => handleChange(event,'home-background') }
                        className='form-control'
                        defaultValue={ colors[ 'home-background' ] }
                        value={ colors[ 'home-background' ] }
                    />
                </div>

            </div>
            */}
        </>)
    }
    // const clearImage = () => {
    //     dispatch(reduxChange(formName, 'coverImage', null))
    // }

    const selected = Object.assign({}, data);
    selected[ 'colors' ] = JSON.stringify(colors)
    return(
        <>
            <Modal.Header closeButton>

                <div className="new-modal-header">
                    <Modal.Title>Create your own color palette</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="device-selection">
                    {onBack && <div className="back-to-home">
                        <a href='#' onClick={ (event) => onBack(event,false) }>
                            <span>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.5 18L9.5 12L15.5 6" stroke="#1B1B1B" strokeLinejoin="round"/>
                                </svg>
                                Back
                            </span>
                        </a>
                    </div>}
                    <div className="preview-switch">
                        <div className="preview-switcher-wrap">
                            <a className={ `preview-switcher ${ !isMobileView ? 'active' : '' }` } onClick={ () => setMobileView(false) }>
                                {/* <DesktopIcon /> */}
                                Desktop
                            </a>
                            <a className={ `preview-switcher ${ isMobileView ? 'active' : '' }` } onClick={ () => setMobileView(true) }>
                                {/* <MobileIcon /> */}
                                Mobile
                            </a>
                        </div>
                    </div>
                </div>

                <div className="custom-color-selection">
                    <div className="custom-color-template-preview">
                        <div className={ ` ${ isMobileView ? 'mobile-version' : 'wizrd-inner-preview' }  wizrd-blog-preview ` }>
                            { isMobileView && <img src={ iphoneImage } className="mobile-img" /> }
                            <WebTemplatePreview isMobileView={ isMobileView }  data={ selected || props.data } isHomePage={ true } />
                        </div>
                    </div>
                    <div className="custom-color-template-selection">
                        <ColorPicker useOutsideClick={ useOutsideClick }  active={ active } obj={ { hsl: hsl,hsv: hsv } } colors={ colors } onChange={ handleChangeColor } />

                        <div className="header-color-selectors">
                            { inputViews() }
                        </div>
                    </div>
                </div>
                <div className="modal-btns">
                    { isSubmit ? <ButtonLoader
                        button={ <Button className="btn-primary-2" variant="primary" onClick={ () => handleColorsData(colors) }>confirm</Button> }
                        loadingText={ 'Saving' }
                        loading={ loading }

                    /> : <Button variant="primary" className="btn-primary-2" onClick={ () => handleColorsData(colors) }>Confirm</Button> }
                </div>
            </Modal.Body>
        </>
    )
}

CustomColor.propTypes = {
    formName: PropTypes.string,
    previewFile: PropTypes.string,
    data: PropTypes.object,
    handleColorsData: PropTypes.func,
    //backFun: PropTypes.func,
    isSubmit: PropTypes.bool,
    formValues: PropTypes.object,
    loading: PropTypes.bool,
    onBack: PropTypes.func
};
export default CustomColor;