import {
    blogCreateRequest,
    blogCreateSuccess,
    blogCreateFailed,
    socialMediaRequest,
    socialMediaSuccess,
    socialMediaFailed,
    publishRequest,
    publishSuccess,
    publishFailed,
    getDraftBlogListSuccess,
    getPublishBlogListSuccess,
    getBlogListFailed,
    getBlogsRequest,
    deleteBlogSuccess,
    deleteBlogRequest,
    deleteBlogFailed,
    getBlogRequest,
    getBlogSuccess,
    getCategoriesRequest,
    getCategoriesSuccess,
    getSearchCategoriesRequest,
    getSearchCategoriesSuccess
} from '../actions/blog';
import strapiAxiosInstance from '../services/strapiApi';
import axiosInstance from '../services/api';
import { ROUTES } from '../constants/appRoutes';
import { NOTIFICATION_TYPES, BLOG_STATUS } from '../constants/app';
import { notification } from '../services/notification';
import history from '../utils/history'
import { imageUpload, getSiteById } from './assessments'
import { setItem } from 'utils/cache'
import { dataURLtoFile , uId, getSite, getUser } from '../utils/helpers'
import { EVENTS } from 'constants/app'
export const checkAvailbleSlug = async(route,data) => {
    const requestData = {
        contentTypeUID: `application::${ route }.${ route }`,
        data: data,
        field: 'slug'
    }
    try{
        const result = await strapiAxiosInstance.post('/content-manager/uid/generate', requestData)
        if([ 200,203 ].includes(result.status)){
            return result.data.data
        }
    }catch(error){
        return null
    }
}

export const createBlog = (domain, data, id, pathId, _slug, status, callback) => {
    return async(dispatch) => {
        const selectorData = getUser();
        dispatch(blogCreateRequest(data.status))
        if(data.imageUrl && !data.imageUrl.match('^(http|https)://')){
            const file = dataURLtoFile(data.imageUrl,uId()+'.png')
            data[ 'imageUrl' ] = await imageUpload(domain,'blog-images',file);
        }
        if(pathId) {
            axiosInstance.put(`/posts/${ id }`, data).then((response)=>{
                history.push(ROUTES.BLOGS)
                dispatch(blogCreateSuccess(response))
                callback('Blog Updated', { id: response?.data?.id, siteId: data?.siteId, status: data?.status, title: response?.data?.title, slug: response?.data?.slug, 'user identifier': selectorData?.id })
                callback(`Blog ${ data?.status.toLowerCase() }`, { id: response?.data?.id, siteId: data?.siteId, status: data?.status, title: response?.data?.title, slug: response?.data?.slug, 'user identifier': selectorData?.id })
                if (data.status !== status) {
                    response.data.status === 'PUBLISHED' ?
                        callback( EVENTS.number_of_publish_blog, { siteId: data?.siteId, count: response.data.publishedCount , 'user identifier': selectorData?.id }) : callback(EVENTS.numberOfDraftBlog, { siteId: data?.siteId, count: response.data.draftCount , 'user identifier': selectorData?.id })
                }
            }).catch((error) => {
                dispatch(blogCreateFailed(error))
                notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message);
            })
        } else {
            axiosInstance.post('/posts', data).then((response)=>{
                history.push(ROUTES.BLOGS)
                dispatch(blogCreateSuccess(response))
                callback('Blog Created', { id: response?.data?.id, siteId: data.siteId, status: data.status, title: response?.data?.title, slug: response?.data?.slug, 'user identifier': selectorData?.id })
                callback(`Blog ${ data.status.toLowerCase() }`, { id: response?.data?.id, siteId: data.siteId, status: data.status, title: response?.data?.title, slug: response?.data?.slug, 'user identifier': selectorData?.id })
                response.data.status === 'PUBLISHED' ?
                    callback(EVENTS.numberOfPublishBlog, { siteId: data?.siteId, count: response.data.publishedCount , 'user identifier': selectorData?.id }) : callback(EVENTS.numberOfDraftBlog, { siteId: data?.siteId, count: response.data.draftCount , 'user identifier': selectorData?.id })

            }).catch((error) => {
                dispatch(blogCreateFailed(error))
                notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message);
            })

        }
    };
};

export const createSocialMedia = (id,data, callback) => {
    return async(dispatch) => {
        const selectorData = getUser();
        dispatch(socialMediaRequest())
        axiosInstance.put(`/assessment/${ id }`, data).then((response)=>{
            setItem('site',response.data.data)
            dispatch(getSiteById(response.data.data.id))
            //dispatch(siteBuild(id,() => {}))
            dispatch(socialMediaSuccess(response))
            if(callback) {
                callback('Connected Social Media Count', { siteId: response?.data?.data?.id, count: Object.keys(response?.data?.data?.socialMediaLinks || {}).length, 'user identifier': selectorData?.id })
                callback('Connected Social Media', { siteId: response?.data?.data?.id, ...response?.data?.data?.socialMediaLinks, 'user identifier': selectorData?.id })
            }
        }).catch((error) => {
            dispatch(socialMediaFailed(error))
        })
    };
};

export const getDraftBlogs =  (args) => {
    return async(dispatch) => {
        try{
            const site = getSite();
            dispatch(getBlogsRequest())
            const result = await axiosInstance.get(`/publisher/site/${ site?.id }/posts?state=${ BLOG_STATUS.DRAFT }&${ args }`)
            if([ 200,203 ].includes(result.status)){
                dispatch(getDraftBlogListSuccess(result.data));
            }
        }catch(error){
            dispatch(getBlogListFailed(error))
            notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message);
        }
    }
}

export const getPublishedBlogs =  (args) => {
    return async(dispatch) => {
        try{
            const site = getSite();
            dispatch(getBlogsRequest())
            const result = await axiosInstance.get(`/publisher/site/${ site?.id }/posts?state=${ BLOG_STATUS.PUBLISHED }&${ args }`)
            if([ 200,203 ].includes(result.status)){
                dispatch(getPublishBlogListSuccess(result.data));
            }
        }catch(error){
            dispatch(getBlogListFailed(error))
            notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message);
        }
    }
}

export const callPublish = (blog,isPublish, publishArgs, draftArgs) => {
    return(dispatch) => {
        // eslint-disable-next-line camelcase
        const data = isPublish ? { status: BLOG_STATUS.PUBLISHED, categories: blog.categories, slug: blog.slug } : { status: BLOG_STATUS.DRAFT, categories: blog.categories, slug: blog.slug }
        dispatch(publishRequest())
        axiosInstance.put(`/posts/${ blog?.id }`, data).then((response)=>{
            dispatch(getDraftBlogs(draftArgs))
            dispatch(getPublishedBlogs(publishArgs))
            dispatch(publishSuccess(response))
            history.replace(ROUTES.BLOGS)
        }).catch((error) => {
            dispatch(publishFailed(error))
            notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message);
        })
    }
}

export const deleteBlog =  (id,draftArgs, publishArgs) => {
    return async(dispatch) => {
        try{
            dispatch(deleteBlogRequest())
            const result = await axiosInstance.delete(`/posts/${ id }`)
            if([ 200,203, 204 ].includes(result.status)){
                dispatch(deleteBlogSuccess())
                dispatch(getDraftBlogs(draftArgs))
                dispatch(getPublishedBlogs(publishArgs))
            }
        }catch(error){
            dispatch(deleteBlogFailed(error))
            notification(NOTIFICATION_TYPES.ERROR, error?.response?.data?.message);
        }
    }
}

export const getBlogById =  (slug, siteId) => {
    return async(dispatch) => {
        dispatch(getBlogRequest())
        axiosInstance.get(`/site/${ siteId }/posts/${ slug }`,{ headers: { edit: true } }).then((response) => {
            dispatch(getBlogSuccess(response.data))
        }).catch(() => {
            history.push(ROUTES.BLOGS)
        })
    }
}

export const getCategories = (siteId) => {
    const url =`/categories/recent?siteId=${ siteId }`
    return async(dispatch) => {
        dispatch(getCategoriesRequest())
        axiosInstance.get(url).then((response) => {
            dispatch(getCategoriesSuccess(response.data))
        }).catch(() => {
        })
    }
}
export const getSearchCategories = ( value ) => {
    const url =`/categories/_search?name=${ value }`
    return async(dispatch) => {
        dispatch(getSearchCategoriesRequest())
        axiosInstance.get(url).then((response) => {
            dispatch(getSearchCategoriesSuccess(response.data))
        }).catch(() => {
        })
    }
}