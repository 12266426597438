import { ActionTypes } from '../actions/plan/actionTypes'
const initialState = {
    plansLoading: false,
    plans: []
}

export default (state = initialState, action) => {
    switch (action.type) {
    case ActionTypes.GET_PLANS_REQUEST:
        return { ...state, plansLoading: true };
    case ActionTypes.GET_PLANS_SUCCESS:
        return { ...state, plans: action.payload, plansLoading: false };
    case ActionTypes.GET_PLANS_FAILURE:
        return { ...state, plansLoading: false };
    default:
        return state;
    }
};