/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory  } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import _ from 'lodash';
import { EVENTS } from 'constants/app'
import ConfirmAlert from 'components/core/confirm-alert';
import { getCurrentStep, getSite , getUser, mixpanelCommonData } from 'utils/helpers';
import { getSiteById } from 'middleware/assessments';
import { connectDomain, disconnectDomain, getDomainData } from 'middleware/domain';
import Step from './components/step';
import Home from './components/home';
import FinalStep from './components/final';
import { ROUTES } from 'constants/appRoutes';
import { MixpanelContext } from 'utils/tracking';
import Skeleton, { SkeletonTheme }  from 'react-loading-skeleton';

export const DummySekelton = () => {
    return(
        <SkeletonTheme color="lightGray">
            <div className="domain-step domain-final-step">
                <div className="domain-final-step-wrap">
                    <div className="domain-final-icon">
                        <Skeleton circle={ true } height={ 200 } width={ 200 } />
                    </div>
                    <div className="domain-cname-detail">
                        <Skeleton width='80%' count={ 1 } height={ 33 } />
                    </div>
                    <div className="domain-connect-info">
                        <Skeleton width='70%' count={ 1 } height={ 22 } />
                        <Skeleton width='60%' count={ 1 } height={ 22 } />
                        <Skeleton width='50%' count={ 1 } height={ 22 } />
                    </div>
                </div>
            </div>
        </SkeletonTheme>

    )
}
const DomainPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [ domainError, setDomainError ] = useState(null)
    const [ domainLoading, setDomainLoading ] = useState(false)
    const [ currentStep, setCurrentStep ] = useState(2);

    const [ formData, setFormStep ] = useState(null);
    const site =  useSelector(state => state.assessment.site);
    const domainData =  useSelector(state => state.domain);
    const currentSite = getSite();
    const user = getUser();
    const mixpanel = useContext(MixpanelContext);

    useEffect(() => {
        const stepNumber = getCurrentStep(domainData.data);
        setCurrentStep(stepNumber);
    }, [ domainData.data ]);

    useEffect(() => {
        dispatch(getSiteById(currentSite?.id))
        dispatch(getDomainData(currentSite?.id));
    }, [  ]);

    const handleError = (value) => {
        if(value.match(/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/)){
            setDomainError(null)
            setDomainLoading(false)
        }else{
            setDomainError('Invalid Domain')
        }
    }
    const asyncValidateFunc = _.debounce(handleError, 800);
    const handleErrorCallback = useCallback(asyncValidateFunc, []);

    const handleFormChange = (event) => {
        const value = event.target.value.replace(/[^a-zA-Z0-9._]/g, '')
        setDomainLoading(true)
        handleErrorCallback(value)
        setFormStep({ [ event.target.name ]: value })
    }

    const initiateProcess = (event) => {
        event.preventDefault();
        setCurrentStep(1)
    }

    const navigateDashboardPage = (event) => {
        event.preventDefault();
        history.push(ROUTES.DASHBOARD);
        setFormStep();
    }

    const handleSecondStepSuccess = () => {
        setCurrentStep(3);
        setFormStep();
    }

    const handleErrorDomain = () => {
        mixpanel.track(EVENTS.customDomainError,mixpanelCommonData({ siteId: site?.id , 'user identifier': user?.id }))
    }

    const handleSecondStep = (event) => {
        event.preventDefault();
        mixpanel.track(EVENTS.desiredDomain,mixpanelCommonData({ siteId: site?.id , 'user identifier': user?.id }))
        dispatch(connectDomain({ id: currentSite?.id, domain: formData?.domain }, handleSecondStepSuccess, handleErrorDomain ));
    }

    const handleDisconnectSuccess = () => {
        setCurrentStep(0)
        setFormStep();
    }

    const handleDisconnectDomain = (event) => {
        event.preventDefault();
        dispatch(disconnectDomain(currentSite?.id, handleDisconnectSuccess));
    }

    const handleDisconnectConfirm = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return(
                    <ConfirmAlert
                        key={ 'box' }
                        onClose={ onClose }
                        handleAction={ handleDisconnectDomain }
                        subHeading='Are you sure you want to diconnect your custom domain?'
                        heading=
                            { <div className='timerSection'>
                                <h4>Disconnect Domain</h4>
                            </div> }/>
                );
            }
        });
    }

    return (
        <main className="dashboard-data">
            <div className="domain-wrap active-step-3">
                { domainData.loading ? <DummySekelton /> : <>
                    {currentStep === 0 && !domainData.isLoading && <Home site={ site } initiateProcess={ initiateProcess } currentStep={ currentStep } />}
                    {currentStep === 1 && <Step domainLoading={ domainLoading } domainError={ domainError } handleFormChange={ handleFormChange } handleFirstStep={ handleSecondStep } currentStep={ currentStep } isDisabled={ (!formData?.domain || currentStep > 1) } isLoading={ domainData.isStep1Loading } formData={ formData } />}
                    {currentStep === 2 && <FinalStep handleDisconnectDomain={ handleDisconnectConfirm } navigateDashboardPage={ navigateDashboardPage } domainData={ domainData } />}
                </>}
            </div>
        </main>
    )
}

export default DomainPage;