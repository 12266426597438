import { ActionTypes } from '../actions/transaction/actionTypes'
const initialState = {
    listLoading: false,
    transactions: null
}

export default (state = initialState, action) => {
    switch (action.type) {
    case ActionTypes.GET_TRANSACTIONS_REQUEST:
        return { ...state, listLoading: true, transactions: [] };
    case ActionTypes.GET_TRANSACTIONS_SUCCESS:
        return { ...state, transactions: action.payload, listLoading: false };
    case ActionTypes.GET_TRANSACTIONS_FAILURE:
        return { ...state, listLoading: false, transactions: [] };
    default:
        return state;
    }
};
