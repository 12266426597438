import React from 'react';
import PropTypes from 'prop-types';
import './style.sass'
const Heading = (props)=> {
    return (
        <div className={ props.parentClassName + ' home-heading' }>
            <h2 className={ props.className }>{ props.heading }</h2>
        </div>
    )
}

Heading.propTypes = {
    parentClassName: PropTypes.string,
    className: PropTypes.string,
    heading: PropTypes.any
};

export default Heading;