/* eslint-disable react/prop-types */
/*
 * Collection of redux form fields
 * With some validations over these fields
*/

import React,{ useState } from 'react';
import { Form } from 'react-bootstrap';
import OtpInput from 'react-otp-input';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import Dropzone from 'react-dropzone';
import ColorImage from 'images/color.png';
import InputSelect from 'react-select-input'
import _ from 'lodash';
export const Validations = (props) => {
    const {
        touched,
        error,
        validationError,
        warning,
        withoutTouch
    } = props.props;

    return (
        <>
            {withoutTouch && <p>
                {((error && <span className={ `field_error ${ error === 'dummy-error' && 'dummy-error' }` }>{error}</span>) || (warning && <span>{warning}</span>))}
            </p>}
            {validationError && (
                <p>
                    {(validationError && <span className={ `field_error ${ error === 'dummy-error' && 'dummy-error' }` }>{validationError}</span>)}
                </p>
            )}

            {!withoutTouch && <p>
                {touched && ((error && <span className={ `field_error ${ error === 'dummy-error' && 'dummy-error' }` }>{error}</span>) || (warning && <span>{warning}</span>))}
            </p>
            }
        </>
    );
};

const renderFieldWG = (props) => {
    const {
        input,
        label,
        name,
        type,
        placeholder,
        asyncLoading,
        disabled,
        validationError,
        meta: { asyncValidating, touched, error, warning },
        maxLength,
        handleKeyUp,
        handleChange,
        rows,
        withoutTouch,
        defaultWarning
    } = props;

    const changeBlur= (event) =>{
        handleKeyUp && handleKeyUp(event.currentTarget.value)
        //input.onChange(event.currentTarget.value)
    }

    const changeValue=(event) => {
        handleChange && handleChange(event.currentTarget.value)
        input.onChange(event.currentTarget.value)
    }

    return (
        <Form.Group controlId={ name }>
            { label &&  <Form.Label>{label || ''}</Form.Label> }
            <Form.Control  rows={ rows } { ...input } onChange={ changeValue } onBlur={ changeBlur }  maxLength={ maxLength } disabled={ disabled || false } type={ type } className={ validationError || (touched && error) ? 'validation-error' : '' } placeholder={ placeholder || '' } />
            {defaultWarning && !input.value && <span className="default-warning"><i className="fas fa-exclamation-triangle"></i> {defaultWarning}</span>}
            <Validations
                props={ {
                    touched,
                    error,
                    validationError,
                    warning,
                    withoutTouch
                } }
            />
            { asyncLoading && asyncValidating ? <div className="small-up-loader">
                <div className="lds-facebook"><div></div><div></div><div></div></div>
            </div> : null }
        </Form.Group>
    );
};

const renderTextArea= (props) => {
    const {
        input,
        defaultValue,
        handleChange,
        label,
        name,
        placeholder,
        asyncLoading,
        disabled,
        validationError,
        meta: { asyncValidating, touched, error, warning },
        maxLength,
        maxWords,
        handleKeyUp,
        rows,
        withoutTouch,
        defaultWarning
    } = props;

    const changeValue = (event) =>{
        handleKeyUp && handleKeyUp(event.currentTarget.value)
        //input.onChange(event.currentTarget.value)
    }

    const change = (event) => {
        if(maxWords === undefined || (event?.target.value.split(/[\s]+/)?.length < maxWords)){
            handleChange && handleChange(event.target.value)
            input.onChange(event.target.value)
        }

    }

    return (
        <Form.Group controlId={ name }>
            { label &&  <Form.Label>{label || ''}</Form.Label> }
            <textarea  maxLength={ maxLength }  rows={ rows } { ...input } onChange={ change } onBlur={ changeValue } defaultValue={ defaultValue }   disabled={ disabled || false }  className={  validationError || (touched && error) ? 'form-control validation-error' : 'form-control' } placeholder={ placeholder || '' } />
            {defaultWarning && !input.value && <span className="default-warning"><i className="fas fa-exclamation-triangle"></i> {defaultWarning}</span>}
            <Validations
                props={ {
                    touched,
                    error,
                    validationError,
                    warning,
                    withoutTouch
                } }
            />
            { asyncLoading && asyncValidating ? <div className="small-up-loader">
                <div className="lds-facebook"><div></div><div></div><div></div></div>
            </div> : null }
        </Form.Group>
    );
};
const renderFieldChangeWG = (props) => {
    const {
        input,
        refData,
        label,
        name,
        type,
        placeholder,
        handleChange,
        disabled,
        validationError,
        meta: { touched, error, warning },
        maxLength,
        rows,
        isSlug,
        isDomain,
        withoutTouch,
        changeType,
        defaultWarning
    } = props;

    const changeValue = (event) =>{
        if(isSlug){
            input.onChange(event.target?.value?.replace(/[^a-zA-Z0-9-_ ]/g, ''))
            handleChange && handleChange(event.target?.value?.replace(/[^a-zA-Z0-9-_ ]/g, ''))
        }else if(isDomain){
            input.onChange(event.target?.value?.replace(/[^a-zA-Z0-9-]/gi, ''))
            handleChange && handleChange(event.target?.value?.replace(/[^a-zA-Z0-9]/gi, ''))
        }else{
            input.onChange(event.target?.value?.replace(/[^a-zA-Z0-9 ]/gi, ''))
            handleChange && handleChange(event.target?.value?.replace(/[^a-zA-Z0-9 ]/gi, ''))
        }

    }
    const handleValue = (event) =>{
        input.onChange(event.target?.value)
        handleChange && handleChange(event.target?.value)
    }

    return (
        <Form.Group controlId={ name }>
            { label &&  <Form.Label>{label || ''}</Form.Label> }
            <Form.Control ref={ refData } rows={ rows } { ...input } onChange={ changeType ==='normal' ? handleValue : changeValue  }  maxLength={ maxLength } disabled={ disabled || false } type={ type } className={ validationError || (touched && error) ? 'validation-error' : '' } placeholder={ placeholder || '' } />
            {defaultWarning && !input.value && <span className="default-warning"><i className="fas fa-exclamation-triangle"></i> {defaultWarning}</span>}
            <Validations
                props={ {
                    withoutTouch,
                    touched,
                    error,
                    validationError,
                    warning,
                } }
            />
        </Form.Group>
    );
};

const renderField = (props) => {
    const {
        input,
        type,
        placeholder,
        disabled,
        validationError,
        meta: { touched, error, warning },
        maxLength,
        rows,
        defaultWarning
    } = props;

    return (
        <>
            <Form.Control rows={ rows } { ...input }  maxLength={ maxLength } disabled={ disabled || false } type={ type } className={ validationError || (touched && error) ? 'form-control validation-error' : 'form-control' } placeholder={ placeholder || '' } />
            {defaultWarning && !input.value && <span className="default-warning"><i className="fas fa-exclamation-triangle"></i> {defaultWarning}</span>}
            <Validations
                props={ {
                    touched,
                    error,
                    validationError,
                    warning,
                } }
            />
        </>
    );
};

const renderOTPField = (props) => {
    const {
        input,
        validationError,
        meta: { touched, error, warning },
        formClass,
        numInputs,
        defaultValue
    } = props;
    const handleData = (value) => {
        input.onChange(value)
    }
    return (
        <div className={ formClass + ' form-group force-mb-10' } style={ { width: '100%' } }>
            <OtpInput
                value={ defaultValue || input.value }
                onChange={ handleData }
                numInputs={ numInputs }
                separator={ <span></span> }
            />
            <Validations
                props={ {
                    touched,
                    error,
                    validationError,
                    warning,
                } }
            />
        </div>
    );
};
const renderStyleView = (props,setValue,setChecked, inputValue, item, index ) => {
    const {
        input,
        className,
        isIcons,
        handleChange,
        isColors,
        handleClick,
        isHomePage,
        handleNext,
        fontStyled,
    } = props;
    item[ 'imageUrl' ] = item.icon?.match('http') ? item.icon : item.imageUrl
    return(
        <div key={ index } className={ `${ isColors && 'styled-color-view' } ${ className || 'styled-radio' }` }>

            <input { ...input }
                onChange={ (value) => {
                    const elm = value.currentTarget;
                    input.onChange(value || elm?.value)
                    handleChange && handleChange(value || elm?.value)
                } }
                type="radio"
                value={ setValue(item) }
                onClick={ (event) => {
                    isColors && item.label === 'Custom Color' ? handleClick && handleClick(event) : null }
                }
                checked={ setChecked(item) }
                id={ input.name }
                className="styled-radio"
            />
            <div className={ ` input-radio-label ${ inputValue?.name === item?.value?.name ? 'selected' : '' } ${ item.label === 'Custom Color' ? 'custom-color-input' : '' }` }>
                {isColors &&
                    <div>
                        {isHomePage && inputValue?.name === item?.value?.name   && <div className="home-next-step-btn">
                            <button onClick={ handleNext } >next</button>
                        </div>
                        }
                        <span className={ `checkbox-colors ${ item.label === 'Clean White' ? 'round-border' : null }` }>
                            {item.label === 'Custom Color' ? <img src={  ColorImage } /> : <span style={ { backgroundColor: item.value[ 'top-menu' ] } }></span>}
                        </span>
                    </div>

                }
                {isIcons && item.icon && !item.imageUrl &&  <span className='emojiText' dangerouslySetInnerHTML={ { __html:  item.icon  } } />}
                {item.imageUrl && <>
                    <img src={ item.imageUrl } className='styled-radio-img'  alt={ item.label } /></>}
                {fontStyled ? <p  className="emojiText" dangerouslySetInnerHTML={ { __html:  item.label  } } /> : <span className="emojiText" dangerouslySetInnerHTML={ { __html:  item.label === 'Custom Color' ? 'Customize' : item.label  } } /> }

            </div>

        </div>)

}

const renderStyleMultipleRadio = (props) => {
    const {
        input,
        validationError,
        options,
        defaultValue,
        isNiche,
        isColors,
        formValues,
        meta: { touched, error, warning },
    } = props;

    const setValue = (item) => {
        if(isColors){
            const obj = formValues && JSON.parse(formValues)
            const value = item.value
            value[ 'header-background' ] = obj && obj[ 'header-background' ] || ''
            value[ 'header-color' ] = obj && obj[ 'header-color' ] || '#000000'
            return JSON.stringify(value)
        }else{
            return  (isNiche ? JSON.stringify({ id: item.value, label: item.label }) :  item.value)
        }
    }
    const setChecked = (item) =>{
        if(isColors){
            const itemValue = typeof (item.value) === 'string' ? item.value && JSON.parse(item.value) : item.value
            const inputValue = typeof (input.value) === 'string' ? input.value && JSON.parse(input.value) : input.value
            return itemValue.name === (inputValue.name || defaultValue)
        }else if(isNiche){
            const value = typeof input.value === 'string' ? input.value &&  JSON.parse(input.value) : input.value
            const other = item.label === 'Other' ? input.value && !_.map(options,'label').includes(value.label) : false
            return value && value.id === item.value || other
        }else{
            return (item.value === (input.value || defaultValue))
        }
    }
    const inputValue = input.value && typeof input.value === 'string' ? JSON.parse(input.value) : input.value
    return (<>
        <Form.Group >
            {
                options.map((item, index) =>  renderStyleView(props,setValue,setChecked, inputValue,item,index))
            }
        </Form.Group>

        { isNiche && inputValue.label !== 'Other'  &&
        <Validations
            props={ {
                touched,
                error,
                validationError,
                warning,
            } }
        /> } </>)
}

const renderFileDrop = (props)=> {
    const {
        input,
        //imageUrl,
        formName,
        //setUrl,
        setImage,
        //url,
        formClass,
        isDropText,
        isDrop,
        allowExtenstions,
        placeholder,
        meta: { touched, error, warning },
    } = props;

    const handleDrop = (acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()

            reader.onabort = () => {}
            reader.onerror = () => {}
            reader.onload = () => {
                const base64 = reader.result
                formName ? setImage(base64) : input.onChange(base64)
                isDrop && isDrop()
                //setUrl(base64);
            }
            // input.onChange(file)
            reader.readAsDataURL(file);
        })
    }
    const extensions = allowExtenstions || 'image/jpeg, image/png'

    return(
        <>
            <Dropzone accept={ extensions } multiple={ false } onDrop={ acceptedFiles => handleDrop(acceptedFiles) } >
                {({ getRootProps, getInputProps }) => {
                    return(

                        <div { ...getRootProps() } className={ formClass + ' avatar-user' }>
                            <div className="c-avatar cursor-pointer upload-file" >
                                {/* <img src={ (url || imageUrl) } /> */}
                                <input  name={ input.name } { ...getInputProps()  } />
                                {/* <p className='' dangerouslySetInnerHTML={ { __html: placeholder } }/>
                                {isDropText ? <div className='drag-image-box'><p className='' dangerouslySetInnerHTML={ { __html: isDropText } }/></div> : null } */}
                                <p className='drag-file-wrapper'>
                                    <div>
                                        <span dangerouslySetInnerHTML={ { __html: placeholder } } />
                                        {isDropText ? <div className='drag-image-box'><p className='' dangerouslySetInnerHTML={ { __html: isDropText } }/></div> : null }
                                    </div>
                                </p>
                            </div>
                        </div>

                    )}}
            </Dropzone>
            <Validations
                props={ {
                    touched,
                    error,
                    warning
                } }
            /></>)
}

const renderNicheDropField = (props) => {
    const {
        input,
        validationError,
        meta: { touched, error, warning },
        options,
        defaultValue,
        placeHolder,
        handleChange,
        defaultWarning
    } = props;
    const onChange = (event,type) => {
        let value;
        if(type === 'change'){
            value =  { label: event.target.value }
        }else{
            value = options.filter((item) => item.label == event?.label) && options.filter((item) => item.label == event?.label)[ 0 ]
        }
        handleChange && handleChange(value)
        input.onChange(JSON.stringify(value))
    }
    return (
        <Form.Group>
            <InputSelect
                className='input-select'
                clearable={ false }
                onSelect={ (event) => onChange(event, 'click')  }
                onChange={ (event) => onChange(event, 'change') }
                options={ options.map((item) => ({ label: item.label, value: item.label })) || [] }
                autoFocus={ false }
                placeholder={ placeHolder }
                value = { defaultValue || '' }
            />
            {defaultWarning && !input.value && <span className="default-warning"><i className="fas fa-exclamation-triangle"></i> {defaultWarning}</span>} <Validations props={ { touched, error, validationError, warning, } } />
        </Form.Group> );
};

const renderFieldPaymentDeatils = (props) => {
    const {
        input,
        label,
        name,
        validationError,
        meta: {  touched, error, warning },
        defaultWarning,
        inputName,
        cardError,
        setCardError,
    } = props;

    const [ cardClass, setCardClass ] = useState('pf-credit-card')

    const cardBrandToPfClass = {
        'visa': 'pf-visa',
        'mastercard': 'pf-mastercard',
        'amex': 'pf-american-express',
        'discover': 'pf-discover',
        'diners': 'pf-diners',
        'jcb': 'pf-jcb',
        'unknown': 'pf-credit-card',
    }
    const handleChange = (event) => {
        const dd = { ...cardError }
        dd[ input.name ] = event.empty ? `Please insert ${ event.elementType }` : event.error && event.error.message
        setCardError('')
        input.onChange(dd)
        setCardClass(cardBrandToPfClass[ event.brand ] || 'pf-credit-card' )
    }
    let inputComponent = <div className='card-number-element'><div className='card-icon'><i className={ `pf ${ cardClass }` }></i></div><CardNumberElement  onChange={ handleChange } /></div>;
    switch(inputName) {
    case 'cardExpiry':
        inputComponent = <CardExpiryElement onChange={ handleChange } />;
        break;
    case 'cardCvc':
        inputComponent = <CardCvcElement onChange={ handleChange } />;
        break;
    }
    return (
        <Form.Group controlId={ name }>
            { label &&  <Form.Label>{label || ''}</Form.Label> }
            { inputComponent }
            {defaultWarning && !input.value && <span className="default-warning"><i className="fas fa-exclamation-triangle"></i> {defaultWarning}</span>}
            <Validations
                props={ {
                    touched,
                    error: error,
                    validationError,
                    warning,
                } }
            />
        </Form.Group>
    );
}

const renderMultipleCheckbox = (props) => {
    const {
        isSites,
        input,
        validationError,
        meta: { touched, error, warning },
        options,
        defaultWarning
    } = props;

    const sitesCheckboxGroup = () => {

        return options.map((option, index) => {
            return (
                <tr className="checkbox" key={ index }>
                    <td>{option.websiteName}</td>
                    <td>{option.domain}</td>
                    <td>
                        <label>
                            <input type="checkbox"
                                name={ `${ input.name }[${ index }]` }
                                value={ option.id }
                                checked={ input.value.indexOf(option.id) !== -1 }
                                onChange={ (event) => {
                                    const newValue = [ ...input.value ];
                                    if (event.target.checked) {
                                        newValue.push(option.id);
                                    } else {
                                        newValue.splice(newValue.indexOf(option.id), 1);
                                    }

                                    return input.onChange(newValue);
                                } }/>
                            {option.name}
                        </label>
                    </td>
                </tr>)
        });
    }
    return (
        <>
            { isSites ? sitesCheckboxGroup() : null }
            {defaultWarning && !input.value && <span className="default-warning"><i className="fas fa-exclamation-triangle"></i> {defaultWarning}</span>}
            <Validations
                props={ {
                    touched,
                    error: error,
                    validationError,
                    warning,
                } }
            />
        </> );
};

export {
    renderMultipleCheckbox,
    renderFileDrop,
    renderFieldWG,
    renderField,
    renderOTPField,
    renderStyleMultipleRadio,
    renderFieldChangeWG,
    renderTextArea,
    renderNicheDropField,
    renderFieldPaymentDeatils
};