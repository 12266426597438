export const ActionTypes = {
    GET_CARDS_REQUEST: 'GET_CARDS_REQUEST',
    GET_CARDS_SUCCESS: 'GET_CARDS_SUCCESS',
    GET_CARDS_FAILURE: 'GET_CARDS_FAILURE',
    CREATE_CARD_REQUEST: 'CREATE_CARD_REQUEST',
    CREATE_CARD_SUCCESS: 'CREATE_CARD_SUCCESS',
    CREATE_CARD_FAILURE: 'CREATE_CARD_FAILURE',
    SET_DEFAULT_CARD_REQUEST: 'SET_DEFAULT_CARD_REQUEST',
    SET_DEFAULT_CARD_SUCCESS: 'SET_DEFAULT_CARD_SUCCESS',
    SET_DEFAULT_CARD_FAILURE: 'SET_DEFAULT_CARD_FAILURE'
}
