export const ActionTypes = {
    GET_PAGE_LIST: 'GET_PAGE_LIST',
    GET_DRAFT_PAGE_LIST_SUCCESS: 'GET_DRAFT_PAGE_LIST_SUCCESS',
    GET_PUBLISH_PAGE_LIST_SUCCESS: 'GET_PUBLISH_PAGE_LIST_SUCCESS',
    GET_PAGE_LIST_FAILURE: 'GET_PAGE_LIST_FAILURE',
    GET_PAGE_REQUEST: 'GET_PAGE_REQUEST',
    GET_PAGE_SUCCESS: 'GET_PAGE_SUCCESS',

    PAGE_CREATE_REQUEST: 'PAGE_CREATE_REQUEST',
    PAGE_CREATE_SUCCESS: 'PAGE_CREATE_SUCCESS',
    PAGE_CREATE_FAILURE: 'PAGE_CREATE_FAILURE',

    DELETE_PAGE_REQUEST: 'DELETE_PAGE_REQUEST',
    DELETE_PAGE_SUCCESS: 'DELETE_PAGE_SUCCESS',
    DELETE_PAGE_FAILURE: 'DELETE_PAGE_FAILURE',

    PUBLISH_REQUEST: 'PUBLISH_REQUEST',
    PUBLISH_SUCCESS: 'PUBLISH_SUCCESS',
    PUBLISH_FAILURE: 'PUBLISH_FAILURE',
    CLEAR_PAGE_FORM: 'CLEAR_PAGE_FORM',

    GET_ALL_PAGES_REQUEST: 'GET_ALL_PAGES_REQUEST',
    GET_ALL_PAGES_SUCCESS: 'GET_ALL_PAGES_SUCCESS',
    GET_ALL_PAGES_FAILED: 'GET_ALL_PAGES_FAILED',

    GET_RECENT_PAGES_REQUEST: 'GET_RECENT_PAGES_REQUEST',
    GET_RECENT_PAGES_SUCCESS: 'GET_RECENT_PAGES_SUCCESS',
    GET_RECENT_PAGES_FAILED: 'GET_RECENT_PAGES_FAILED'

}
