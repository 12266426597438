/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import { SkeletonTheme } from 'react-loading-skeleton';

// import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom';
import Footer from './Footer'
import SideBar from './Sidebar'
import Navbar from './Navbar'
import { addBodyClass } from '../../utils/helpers'
// import Loader from  '../../components/core/loader'
import { isLoggedIn } from '../../utils/helpers'
const TemplateWrapper = (props) => {
    //const stateData = useSelector((state)=> state);
    const pathname = props.location.pathname
    const [ toggleMobileSideBar, setToggleMobileSideBar ] = React.useState(false)
    useEffect(()=> {
        addBodyClass(pathname)
    },[ pathname ])

    const isSideBar = () => {
        if(isLoggedIn()){
            return pathname !== '/assessment' &&  pathname !== '/confirm-account'
        }
        return false
    }
    const mobilieHamberger = () => {
        setToggleMobileSideBar(!toggleMobileSideBar)
    }
    return (
        <div >
            <SkeletonTheme color="lightGray">
                {/*<Loader isLoading={ isLoading(stateData) } />*/}
                { pathname !== '/assessment' && <Navbar mobilieHamberger={ mobilieHamberger } toggleMobileSideBar={ toggleMobileSideBar }  pathname={ pathname } />}
                { isSideBar() ?
                    <section className="dashboard-wrapper">
                        <SideBar setToggleMobileSideBar={ setToggleMobileSideBar } toggleMobileSideBar={ toggleMobileSideBar } />{props.children}
                    </section> : <div>{props.children}</div> }
                <Footer />
            </SkeletonTheme>
        </div>
    )
}

export default withRouter(TemplateWrapper)
