/* eslint-disable no-param-reassign */
import React, { useEffect,  useRef } from 'react';
import  { EditorState,SelectionState,ContentState, convertToRaw, RichUtils  } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
//import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToMarkdown from 'draftjs-to-markdown';
import ImageUpload  from './components/ImageUpload'
import htmlToDraft from 'html-to-draftjs';
import PropTypes from 'prop-types';
const WsEditor = (props) =>  {
    const { initialValue, handleRTEdata, editorState, setEditorState  } = props
    const editorRef = useRef(null)
    const validString = (stringData) => {
        stringData = stringData.replace(/<[^>]+>/g, '')
        stringData = stringData.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '')
        stringData = stringData.replace('![](','')
        return stringData

    }

    const handleOnChange = () => {
        console.log('fdsafs',draftToHtml(convertToRaw(editorState.getCurrentContent())))
        const htmlData =   draftToHtml(convertToRaw( editorState.getCurrentContent()),null,
            true,
            (entity) => {
                if (entity.type === 'IMAGE') {
                    var textAlign = 'none';
                    var height = entity.data.height || 'auto'
                    var width = entity.data.width || 'auto'
                    if (entity.data.alignment) {
                        if (entity.data.alignment === 'none') {
                            textAlign = 'center';
                        } else {
                            textAlign = entity.data.alignment;
                        }
                    }else{
                        textAlign = 'center'
                    }
                    return '<span style="text-align:' + textAlign + ';display: block"><img src="' + entity.data.src + '" alt="' + entity.data.src + '" style="height: ' + height + ';width: ' + width + '"/></span>';
                }
                if(entity.type ==='EMBEDDED_LINK' ){
                    const time = new Date().getTime()
                    return '<iframe title="embed-video-'+time+'" frameborder="0" src="'+entity.data.src+'" height="'+entity.data.height+'" width="'+entity.data.width+'"></iframe>'
                }
            })
        let stringData =  draftToMarkdown(convertToRaw(editorState.getCurrentContent()))
        stringData = validString(stringData)
        handleRTEdata(htmlData, stringData)
    }
    useEffect(() => {
        handleOnChange()
    },[ editorState ])
    const onEditorStateChange = (state) => {
        console.log('state',draftToHtml(convertToRaw(editorState.getCurrentContent())))
        setEditorState(state);
        // handleChange(state)
    };

    const embedVideoCallBack = (link) =>{
        if (link.indexOf('youtube') >= 0){
            link = link.replace('watch?v=','embed/');
            link = link.replace('/watch/', '/embed/');
            link = link.replace('youtu.be/','youtube.com/embed/');
        }
        return link
    }

    // Include 'paragraph' as a valid block and updated the unstyled element but
    // keep support for other draft default block types

    useEffect(() => {
        if(initialValue){
            const blocksFromHtml = htmlToDraft(initialValue , (nodeName, node) => {
                const entityConfig = {}
                if (nodeName === 'img' && node instanceof HTMLImageElement) {
                    entityConfig.src = node.getAttribute
                        ? node.getAttribute('src') || node.src
                        : node.src
                    entityConfig.alt = node.alt
                    entityConfig.height = node.style.height
                    entityConfig.width = node.style.width
                    entityConfig.style = node.style
                    if(node?.parentElement?.style){
                        entityConfig.alignment = node?.parentElement?.style.textAlign === 'center' ? 'none' : node?.parentElement?.style.textAlign
                    }
                    if (node.style.float) {
                        entityConfig.alignment = node.style.float
                    } else {
                        if (node.style.textAlign) {
                            entityConfig.alignment = node.style.textAlign
                        }else{
                            if(!node?.parentElement?.style?.textAlign){
                                entityConfig.alignment ='left'
                            }
                        }
                    }
                    return {
                        type: 'IMAGE',
                        mutability: 'MUTABLE',
                        data: entityConfig,
                    }
                }
                if (nodeName === 'iframe') {
                    const entityConfigIframe = {}
                    entityConfigIframe.src = node.getAttribute
                        ? node.getAttribute('src') || node.src
                        : node.src
                    entityConfigIframe.frameboder = node.frameboder;
                    entityConfigIframe.style = node.style;
                    entityConfigIframe.height = node.style.height || node.height || '315px';
                    entityConfigIframe.width = node.style.width || node.width || '560px';
                    entityConfigIframe.title = node.title || `embed-video-${ new Date().getTime() }`
                    return {
                        type: 'EMBEDDED_LINK',
                        mutability: 'MUTABLE',
                        data: entityConfigIframe,
                    }
                }
            });
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const ed = EditorState.createWithContent(contentState);
            setEditorState(ed)
        }

    },[ initialValue ])

    const onChange = (editorState1) => {
        let newEditorState = editorState1;
        const content = editorState1.getCurrentContent();
        const key = editorState1.getSelection().getFocusKey();
        const type = content.getBlockForKey(key).getType();

        if (type === 'atomic') {
            const keyAfter = content.getKeyAfter(key);
            const newSelection = SelectionState.createEmpty(keyAfter);
            newEditorState = EditorState.forceSelection(editorState1, newSelection);
        }
        console.log('new',draftToHtml(convertToRaw(editorState1.getCurrentContent())))
        setEditorState(newEditorState);

    };

    const handleKeyCommand = (command,editorState1) =>{
        const newContent = RichUtils.handleKeyCommand(editorState1, command);
        if (newContent) {
            onChange(newContent);
            return 'handled';
        } else {
            return 'not-handled';
        }
    }
    return (
        <div>
            <Editor
                ref={ editorRef }
                editorState={ editorState }
                handleKeyCommand={ handleKeyCommand }
                wrapperClassName="ws-editor-wrapper"
                editorClassName="ws-editor"
                toolbarClassName="ws-editor-toolbar"
                onEditorStateChange={ onEditorStateChange }
                toolbarCustomButtons={ [ <ImageUpload setEditorState={ setEditorState }  key={ 1 } editorState={ editorState } { ...props }  /> ] }
                toolbar={ {
                    options: [ 'inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', /*'remove'*/ 'history' ],
                    embedded:{
                        embedCallback: embedVideoCallBack,
                        defaultSize: {
                            height: '315',
                            width: '560'
                        },
                        title: 'iframe-video'

                    }
                } }
            />
        </div>
    );

}

WsEditor.propTypes = {
    initialValue: PropTypes.object,
    handleRTEdata: PropTypes.func,
    editorState: PropTypes.object,
    setEditorState: PropTypes.func
};
export default WsEditor