/* eslint-disable react/prop-types */
import React, { useContext } from 'react'
import { truncateString, mixpanelCommonData } from 'utils/helpers'
import { Spinner } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom'
import { SHARING_URLS } from 'constants/app'
import { ROUTES } from 'constants/appRoutes';
import { deleteAssessment } from 'middleware/assessments/'
import { Dropdown  } from 'react-bootstrap'
import { MixpanelContext } from 'utils/tracking';
import { EVENTS } from 'constants/app';
import ConfirmAlert from 'components/core/confirm-alert'
import { confirmAlert } from 'react-confirm-alert';
import {
    EditBlogListIcon,
    DeleteBlogListIcon,
    ShareBlogListIcon,
    ChevronRight,
    PageLayoutIcon,
    TopRightArrow
} from 'utils/svg'
import { setItem } from 'utils/cache'
import {  getSiteById } from 'middleware/assessments/'
const SiteView = ( props ) => {
    const mixpanel = useContext(MixpanelContext)
    //var timeoutData = null;
    const { item, history , isListView , activePage,setActivePage, limit } = props
    const dispatch = useDispatch()
    const siteSelectLoading = useSelector((state) => state.assessment.siteSelectLoading )
    const siteData = useSelector((state) => state.assessment.sites) || []
    const siteMetaData = useSelector((state) => state.assessment.siteMetaData)
    const siteLoadingId = useSelector((state) => state.assessment.siteLoadingId )
    // const checkDomainStatus = async(domain) => {
    //     try{
    //         const response = await trackDomainStatus(domain)
    //         dispatch(domainStatusSuccess(response))
    //         if(response.data.status === 'SUCCESS'){
    //             const siteObj = { ...site }
    //             siteObj[ 'buildStatus' ] = 'SUCCESS'
    //             setSiteData(siteObj)
    //             clearInterval(timeoutData)
    //         }
    //     // eslint-disable-next-line no-empty
    //     }catch(err){

    //     }
    // }
    const deleteCallback = (page) => {
        setActivePage(page)
    }
    const handleDeleteSite = (event,assessment) => {
        event.preventDefault()
        const page = siteData.length === 1 && siteMetaData.count !== 1  ? activePage - 1 : activePage
        confirmAlert({
            // eslint-disable-next-line react/display-name
            customUI: ({ onClose }) => {
                return(
                    <ConfirmAlert
                        key={ 'box' }
                        onClose={ onClose }
                        handleAction={ () => dispatch(deleteAssessment(assessment.id, `?page=${ page }&size=${ limit }`,page, deleteCallback)) }
                        subHeading='By confirming this site & information will be deleted forever.' />
                );
            }
        });
    }
    // useEffect(() =>{
    //     const domain = site?.domain
    //     if(site.buildStatus !== 'SUCCESS'){
    //         domain && checkDomainStatus(domain )
    //         timeoutData = domain &&  setInterval(function(){
    //             checkDomainStatus(domain)
    //         },20000)
    //         return () =>{
    //             clearInterval(timeoutData)
    //         }
    //     }
    // },[ site?.domain ])

    const setSite = (event,path) => {
        event.stopPropagation();
        mixpanel.track(EVENTS.dashboardVisit,mixpanelCommonData({}))
        dispatch(getSiteById(item.id,(response) => {
            if(response.status === 200){
                setItem('site',response.data)
                history.push(path)
            }
        }))
    }
    if(item){
        const domain = item?.customDomain || item?.domain;
        if(isListView){
            const { copySuccess,toggleSite ,setSiteListView, setToggleSite, copyToClipBoard, share } = props
            return(<div key={ item.id } className={ `page-name blog-list-table blog-list-publish blog-list-header ${ item.id === toggleSite ? 'active' : '' }` } >
                {/* <div className="blog-list-column blog-list-live">
                <Form.Check
                    type="switch"
                    id={ 'custom-switch-'+item.id  }
                    label=""
                    checked={ true }
                />
            </div> */}
                <div className="blog-list-column blog-list-title">
                    <span className="table-post-title">
                        {item?.websiteName}
                        <a href='#' onClick={ () => setToggleSite(item.id === toggleSite ? null : item.id) } className="toggle-blog-detail">
                            <ChevronRight />
                        </a>
                    </span>
                </div>
                <div className="blog-list-column blog-list-domain">
                    <span> <a href={ `https://${ domain }` } rel="noreferrer" target='_blank'>{(domain)} <TopRightArrow /></a></span>
                </div>
                <div className="blog-list-column blog-list-status statusView">
                    {/* <span className={ 'SUCCESS' }> Done</span> */}
                </div>
                <div className="blog-list-column blog-list-actions  blog-list-delete">
                    <div className="hover-actions">
                        {copySuccess}
                        <a onClick={ (e) => setSiteListView(e, item) } className="table-action-btns" href="/#">
                            <EditBlogListIcon />
                            <span>Edit</span>
                        </a>
                        <a onClick={ (event) => handleDeleteSite(event,item)  } className="table-action-btns" href="#">
                            <DeleteBlogListIcon />
                            <span>Delete</span>
                        </a>
                        <Dropdown>
                            <Dropdown.Toggle as={ Link } className='table-action-btns'>
                                <ShareBlogListIcon />
                                <span>Share</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#" onClick={ (e) => copyToClipBoard(e, item) }>Copy to clipboard</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={ (e) => share('FACEBOOK',e, item ) } >Share on facebook</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={ (e) => share('TWITTER',e,item ) } >Share on twitter</Dropdown.Item>
                                <Dropdown.Item href={ SHARING_URLS.EMAIL(domain, 'https://'+domain) }  >Share on email</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                    </div>
                </div>
            </div>)
        }else{
            return(
                <li className="site-list">
                    <div className="page-detail">

                        <div className="page-name">
                            <h6>
                                { truncateString(item.websiteName,22) }
                                {siteSelectLoading && siteLoadingId === item.id && <Spinner animation="border" size={ 'sm' } role="status"><span className="sr-only">Loading...</span></Spinner> }
                                <span className="statusView"><a href={ `https://${ domain }` } rel="noreferrer" target='_blank'>{truncateString(domain,20)} <TopRightArrow /></a>
                                    {/* <span className={ 'success' }>Done</span> */}
                                </span>
                            </h6>

                            {/* <Form.Check
                            type="switch"
                            id="custom-switch"
                            label="Live"
                            checked={ true }
                        /> */}
                        </div>
                        <div className="page-layout" onClick={ (event) => !siteSelectLoading && setSite(event,ROUTES.DASHBOARD) }>
                            <div className="page-layout-img">
                                <PageLayoutIcon />
                            </div>

                        </div>
                        <div className="page-action">
                            <div className="page-action-btns">
                                {/*<a className="btn delete-icon">
                                <SiteDeleteIcon />
                                Delete
                            </a>
                            <a className="btn clone-icon">
                                <SiteCloneIcon />
                                Clone
                    </a>*/}
                            </div>
                            <div className="page-edit">
                                <a href='#' onClick={ (event) => !siteSelectLoading && setSite(event,ROUTES.EDIT_SITE) }>Edit </a>
                                | <a onClick={ (event) => handleDeleteSite(event,item)  } href="#">
                                    Delete
                                </a>
                            </div>
                        </div>
                    </div>
                </li>)
        }
    }else{ return null }

}

SiteView.propTypes = {
    onClose: PropTypes.func,
    item: PropTypes.object,
    key: PropTypes.string,
    activePage: PropTypes.number,
    limit: PropTypes.number,
}

export default withRouter(SiteView)