import React from 'react';
import {
    Container,
    Button
} from 'react-bootstrap';
// import {
//     //VideoPlayicon,
//     HowWorkImgVector1,
//     HowWorkImgVector2,
//     HowWorkImgVector3,
//     HowWorkImgVector4
// } from '../../../utils/svg';
import Heading from '../../../components/core/heading';
import './style.sass'
import PropTypes from 'prop-types';

const HowItWork = (props)=> {
    const { redirectAssessment } = props
    return (
        <section className="publisher-how-work">
            <Container>
                <div className="publisher-how-work-inner">
                    <div className="how-work-left">
                        <div className="how-work-img">
                            <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/6vu2Sf8GRuM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            {/* <a>
                                <VideoPlayicon />
                            </a> */}
                            {/* <div className="how-work-img-vector">
                                <div className="vector1">
                                    <HowWorkImgVector1 />
                                </div>
                                <div className="vector2">
                                    <HowWorkImgVector2 />
                                </div>
                                <div className="vector3">
                                    <HowWorkImgVector4 />
                                </div>
                                <div className="vector4">
                                    <HowWorkImgVector3 />
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="how-work-right">
                        <Heading
                            className="light-heading"
                            heading={ <>How it Works</> }
                        />
                        <ul>
                            <li>
                                <span className="counter">1</span>
                                <div className="how-work-steps">
                                    <h4>Choose your colors </h4>
                                    <p>Choose from our web designer approved color schemes or create your own!</p>
                                </div>
                            </li>
                            <li>
                                <span className="counter">2</span>
                                <div className="how-work-steps">
                                    <h4>Name Your Blog </h4>
                                    <p>Pick a name and choose a header image from thousands of free images or upload your own!</p>
                                </div>
                            </li>
                        </ul>
                        <h3>Just start writing!</h3>
                        <div className="how-work-action">
                            <Button onClick={ () => redirectAssessment() } className="btn-primary-2 home-btn">Try for Free</Button>
                            <span>*No Credit Card Required</span>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    )
}
HowItWork.propTypes = {
    redirectAssessment: PropTypes.func
};
export default HowItWork;