import { ActionTypes } from './actionTypes';

export const getPagesRequest = () => {
    return {
        type: ActionTypes.GET_PAGE_LIST
    }
}

export const getDraftPageListSuccess = (data) => {
    return {
        type: ActionTypes.GET_DRAFT_PAGE_LIST_SUCCESS,
        payload: data
    }
}
export const getPublishPageListSuccess = (data) => {
    return {
        type: ActionTypes.GET_PUBLISH_PAGE_LIST_SUCCESS,
        payload: data
    }
}

export const getPageListFailed = (error) => {
    return {
        type: ActionTypes.GET_PAGE_LIST_FAILURE,
        error: error
    }
}

export const getPageRequest = () => {
    return {
        type: ActionTypes.GET_PAGE_REQUEST
    }
}
export const getPageSuccess = (data) => {
    return {
        type: ActionTypes.GET_PAGE_SUCCESS,
        payload: data
    }
}

export const pageCreateRequest = (status) => {
    return {
        type: ActionTypes.PAGE_CREATE_REQUEST,
        payload: status
    };
};

export const pageCreateSuccess = (data) => {
    return {
        type: ActionTypes.PAGE_CREATE_SUCCESS,
        payload: data
    };
};

export const pageCreateFailed = (error) => {
    return {
        type: ActionTypes.PAGE_CREATE_FAILURE,
        error: error
    };
};
export const deletePageRequest = () => {
    return {
        type: ActionTypes.DELETE_PAGE_REQUEST
    }
}

export const deletePageSuccess = (data) => {
    return {
        type: ActionTypes.DELETE_PAGE_SUCCESS,
        payload: data
    }
}
export const deletePageFailed = () => {
    return {
        type: ActionTypes.DELETE_PAGE_FAILURE
    }
}
export const publishRequest = () => {
    return {
        type: ActionTypes.PUBLISH_REQUEST,
    };
};

export const publishSuccess = (data) => {
    return {
        type: ActionTypes.PUBLISH_SUCCESS,
        payload: data
    };
};

export const publishFailed = (error) => {
    return {
        type: ActionTypes.PUBLISH_FAILURE,
        error: error
    };
};

export const getAllPagesRequest = () => {
    return {
        type: ActionTypes.GET_ALL_PAGES_REQUEST,
    }
}
export const getAllPublishPagesSuccess = (data) => {
    return {
        type: ActionTypes.GET_ALL_PAGES_SUCCESS,
        payload: data
    }
}

export const getAllPagesFailed = (error) => {
    return {
        type: ActionTypes.GET_ALL_PAGES_FAILED,
        error: error
    }
}

export const getRecentPagesRequest = () => {
    return {
        type: ActionTypes.GET_RECENT_PAGES_REQUEST,
    }
}
export const getRecentPagesSuccess = (data) => {
    return {
        type: ActionTypes.GET_RECENT_PAGES_SUCCESS,
        payload: data
    }
}

export const getRecentPagesFailed = (error) => {
    return {
        type: ActionTypes.GET_RECENT_PAGES_FAILED,
        error: error
    }
}