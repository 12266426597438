import React, { useState, useEffect , useContext } from 'react'
import { reset } from 'redux-form';
import PropTypes from 'prop-types';
// import Router from 'next/router'
import { useHistory, withRouter  } from 'react-router-dom';
import { useDispatch , useSelector } from 'react-redux'
// import withPublicRoute from '../../components/hoc/withPublicRoute'
//import StepOne from  '../../components/assessment/StepOne';
import StepTwo from  '../../components/assessment/StepTwo';
import StepThree from  '../../components/assessment/StepThree';
import Preview from  '../../components/assessment/Preview';
import { ROUTES } from '../../constants/appRoutes'
import { createAssessment } from '../../middleware/assessments';
import { isLoggedIn, getUser } from '../../utils/helpers'
import { MixpanelContext } from 'utils/tracking';
import { EVENTS } from 'constants/app'
import { setItem } from 'utils/cache'
const AssessmentPage = (props) => {
    const mixpanel = useContext(MixpanelContext)
    const history = useHistory();
    const dispatch  = useDispatch()
    const [ step, setStep ] = useState(1)
    const { assessmentData } = useSelector((state) => state.assessment)
    const assessmentForm = useSelector((state) => state.form.assessmentForm)

    useEffect(() =>{
        //dispatch(getAssessment())
        return () => {
            dispatch(reset('assessmentForm'))
        }
    },[])
    useEffect(() => {
        if(props?.location?.state?.step){
            setStep(props?.location?.state?.step)
        }
    },[ props?.location?.state?.step ])
    const saveData = () =>{
        const values = assessmentForm.values
        if(values){
            setItem('assessmentForm', (values))
        }
    }
    const nextPage = () => {
        step ===1 && mixpanel.track(EVENTS.clickonNext2,{ formData: assessmentForm.values })
        setStep(step+1)
        saveData()
    }

    const prevPage = () =>  {
        setStep(step-1)
    }

    const finalSubmit = (data) =>{
        if(isLoggedIn()){
            const user = getUser()
            dispatch(createAssessment(data, !user.test,true))
        }else{
            setItem('assessmentForm', (data))
            history.push(ROUTES.REGISTER)
            mixpanel.track(EVENTS.visitRegistrationVia,data)
        }
    }

    const handleView = () => {
        switch(step){
        // case 0:
        //     return <StepOne kindOfBuild={ niches } saveData={ saveData } onSubmit={ nextPage } />
        case 1:
            return <StepTwo
                title={ <> Choose a color scheme or create your own. </> }
                className="step-color-pallate"
                saveData={ saveData }
                onSubmit={ nextPage }
                nextPage={ nextPage }
            />
        case 2:
            return <StepThree setStep={ setStep }  assessmentData={ assessmentData } saveData={ saveData } prevPage={ prevPage } finalSubmit={ finalSubmit } />
        case 3:
            return <Preview assessmentData={ assessmentData } saveData={ saveData } prevPage={ prevPage } finalSubmit={ finalSubmit } />
        default:
            return null
        }
    }
    return(
        <>
            {handleView()}
        </>
    )
}
AssessmentPage.propTypes = {
    location:  PropTypes.shape({
        pathname: PropTypes.string.isRequired,
        state: PropTypes.any
    })
};

export default withRouter(AssessmentPage)
