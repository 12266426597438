import React from 'react';
import Container from 'react-bootstrap/Container';
import Heading from '../../../components/core/heading';
import KeyFeature from './key-feature';
import './style.sass'
const KeyFeatures = ()=> {
    return (
        <section className="publisher-key-feature">
            <Container>
                <div className="publisher-key-feature-inner">
                    <Heading
                        className="dark-heading"
                        heading={ <>Why should your business invest in <span>blogging</span> and <span>content marketing</span>?</> }
                    />
                    <div className="keySection">
                        <KeyFeature
                            className="light-box"
                            percentage="Voted #1 Marketing Channel"
                            detail={ <><b>50%</b> of software companies voted SEO and Blogging as the <b>most effective marketing channels</b></> }
                        />
                        <KeyFeature
                            className="dark-box"
                            percentage="6x Conversion Rate"
                            detail={ <><b>Website conversion rate</b> is nearly six times higher for content marketing adopters than non-adopters</> }
                        />
                        <KeyFeature
                            className="light-box"
                            percentage="40% of marketing budget invested"
                            detail={ <><b>40% </b>- the average amount of their <b>entire marketing budget</b> that software companies invest into SEO and Blogging</> }
                        />
                        <KeyFeature
                            className="dark-box"
                            percentage="82% See Positive ROI"
                            detail={ <> <b>82%</b> of marketers who blog <b>see positive ROI</b> from their inbound marketing</> }
                        />
                        <KeyFeature
                            className="light-box"
                            percentage="“More likely to buy”"
                            detail={ <><b>61%</b> of consumers say they feel better about, and are <b>more likely to buy from</b>, a company that delivers custom content</> }
                        />
                        <KeyFeature
                            className="dark-box"
                            percentage="“The future of marketing”"
                            detail={ <><b>78%</b> of Chief Marketing Officers (CMOs) believe custom <b>content is the future of marketing</b></> }
                        />
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default KeyFeatures;