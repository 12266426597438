import { ActionTypes } from '../actions/domain/actionTypes';
const initialState = {
    loading: false,
    isLoading: false,
    isStep1Loading: false,
    isStep1UndoLoading: false,
    isStep2Loading: false,
    isStep2UndoLoading: false,
    data: null
}

export default (state = initialState, action) => {
    switch (action.type) {
    case ActionTypes.GET_DOMAIN_DATA_REQUEST:
        return { ...state, loading: true }
    case ActionTypes.CONNECT_DOMAIN_REQUEST:
    case ActionTypes.DISCONNECT_DOMAIN_REQUEST:
        return { ...state, [ action?.payload?.type ]: true };
    case ActionTypes.CONNECT_DOMAIN_SUCCESS:
    case ActionTypes.GET_DOMAIN_DATA_SUCCESS:
        return { ...state, data: action?.payload?.data, [ action?.payload?.type ]: false, loading: false };
    case ActionTypes.CONNECT_DOMAIN_FAILURE:
        return { ...state, [ action?.payload?.type ]: false, loading: false };
    case ActionTypes.GET_DOMAIN_DATA_FAILURE:
        return { ...state, loading: false }
    case ActionTypes.DISCONNECT_DOMAIN_FAILURE:
        return { ...state, [ action?.payload?.type ]: false, loading: false };
    case ActionTypes.DISCONNECT_DOMAIN_SUCCESS:
        return { ...state, [ action?.payload?.type ]: false };
    default:
        return state;
    }
};
