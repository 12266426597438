import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { convertCurrencyWithFraction } from 'services/currency';
import { convertLongFormat } from 'services/date';
import ButtonLoader from 'components/core/loader/button-loader';
import { Button } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import _ from 'lodash'
const TransactionListing = (props) => {
    const { loadTransactions } = props;
    const [ transectionList, setTransactionList ] = useState([]);
    const data = useSelector((state)=>state?.transaction);
    const loading = useSelector((state)=>state?.transaction.listLoading);

    useEffect(() => {
        setTransactionList(data.transactions);
    }, [ data.transactions ]);

    return (<div className="row">
        <div className="col-sm-12">
            <div className="profile-billing-info">
                <div className="profile-billing-history">
                    <div className="profile-subhead-wrap">
                        <h4 className="profile-subhead">Billing History</h4>
                        <ButtonLoader
                            button={ <Button type="button" onClick= { () => loadTransactions() }variant="secondary">Refresh</Button> }
                            loading={ data.isLoading }
                            loadingText={ 'Loading' }
                        />
                    </div>
                    <div className="dashboard-table billing-detail-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Amount</th>
                                    <th>Description</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <TransactionSkelton loading={ loading && _.isEmpty(transectionList) } />
                                {transectionList?.map(transaction =>
                                    <tr key={ transaction.id }>
                                        <td className="font-bold">{convertCurrencyWithFraction(transaction?.amount / 100)}</td>
                                        <td>{transaction?.description}</td>
                                        <td>{convertLongFormat(transaction?.date)}</td>
                                    </tr>
                                )}
                                {!transectionList?.length && !loading && 'No Transactions exist'}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}
const TransactionSkelton = (props) => {
    if(props.loading){
        const arr  = [ ...Array(3).keys() ]
        return arr.map((item) => {
            return(
                <tr key={ item } >
                    <td >
                        <Skeleton width='100px'  />
                    </td>
                    <td >
                        <Skeleton width='300px'  />
                    </td>
                    <td >
                        <Skeleton width='300px' />
                    </td>
                </tr>)
        })
    }else{
        return(null)
    }
}

TransactionListing.propTypes = {
    loadTransactions: PropTypes.func,
    isLoading: PropTypes.bool
};

export default TransactionListing;
