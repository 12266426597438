import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import _ from 'lodash'
import { setDefaultCard } from 'middleware/card';

const CardListing = (props) => {
    const dispatch = useDispatch();
    const cardBrandToPfClass = {
        'visa': 'pf pf-visa',
        'mastercard': 'pf pf-mastercard',
        'american express': 'pf pf-american-express',
        'discover': 'pf pf-discover',
        'diners club': 'pf pf-diners',
        'jcb': 'pf pf-jcb',
        'unionpay': 'pf pf-unionpay',
        'unknown': 'pf pf-credit-card',
    }

    const cards = useSelector((state)=>state?.card?.cards);
    const [ activeCard, setActiveCard ] = useState(null)
    const loading = useSelector((state) => state.card?.listLoading )
    const setPrimaryCard = (event, card) => {
        if(card?.id !== activeCard?.id ){
            setActiveCard(card)
            dispatch(setDefaultCard(card?.id));
        }
    }
    useEffect(() => {
        setActiveCard(cards && cards.filter((item) => item.primary)[ 0 ])
    },[ cards ])
    return (<div className="profile-cards-sec col-sm-6">
        <div className="profile-subhead-wrap">
            <h4 className="profile-subhead">Payment Information</h4>
            <button onClick= { () => props.setAddCardModal(true) } className="btn btn-secondary">Add Card</button>
        </div>
        <div className="profile-cards">
            <div className="profile-right profile-card-listing">
                <p className="paymetMethod">Current Payment Method</p>
                <ul>
                    <CardListSkelton loading={ loading && _.isEmpty(cards) }  />
                    {cards?.map(card =>
                        <li key={ card.id } className={ card?.id === activeCard?.id ? 'active' : '' } >
                            <Form.Check
                                name="card"
                                type="radio"
                                onClick={ (event) => setPrimaryCard(event,card) }
                                id="card-1"
                                className="card-radio"
                            />
                            <label htmlFor="card-1">
                                <div className="card-radio-btn">

                                </div>
                                <div className="card-icon">
                                    <i className={ card?.brand && cardBrandToPfClass[ card?.brand.toLowerCase() ] || 'pf pf-credit-card' }></i>
                                </div>
                                <div className="card-number">
                                    **** {card?.last4Digits}
                                </div>
                                <div className="card-exp-date">
                                    {card?.expMonth}/{card?.expYear}
                                </div>
                            </label>
                        </li>
                    )}
                    {!cards?.length && !loading && 'No Cards exist'}
                </ul>
            </div>
        </div>
    </div>)
}

const CardListSkelton = (props) => {
    if(props.loading){
        const arr  = [ ...Array(2).keys() ]
        return arr.map((item) => {
            return(
                <li key={ item } >
                    <label htmlFor="card-1">
                        <div className="card-radio-btn">
                            <Skeleton width='100%' count={ 1 } />
                        </div>
                        <div className="card-icon">
                            <Skeleton width='100px' count={ 1 } />
                        </div>
                        <div className="card-number">
                            <Skeleton width='120px' count={ 1 } />
                        </div>
                        <div className="card-exp-date">
                            <Skeleton width='140px' count={ 1 } />
                        </div>
                    </label>
                </li>)
        })
    }else{
        return(null)
    }
}
CardListing.propTypes = {
    setAddCardModal: PropTypes.func
};

export default CardListing;
