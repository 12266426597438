import { ActionTypes } from './actionTypes'

export const createSubscriptionRequest = () => {
    return {
        type: ActionTypes.CREATE_SUBSCRIPTION_REQUEST,

    };
};
export const createSubscriptionSuccess = (data) => {
    return {
        type: ActionTypes.CREATE_SUBSCRIPTION_SUCCESS,
        payload: data,
    };
};
export const createSubscriptionFailure = (error) => {
    return {
        type: ActionTypes.CREATE_SUBSCRIPTION_FAILURE,
        error: error,
    };
};

export const updateSubscriptionRequest = () => {
    return {
        type: ActionTypes.UPDATE_SUBSCRIPTION_REQUEST,

    };
};
export const updateSubscriptionSuccess = (data) => {
    return {
        type: ActionTypes.UPDATE_SUBSCRIPTION_SUCCESS,
        payload: data,
    };
};
export const updateSubscriptionFailure = (error) => {
    return {
        type: ActionTypes.UPDATE_SUBSCRIPTION_FAILURE,
        error: error,
    };
};

export const cancelAccountRequest = () => {
    return {
        type: ActionTypes.CANCEL_ACCOUNT_REQUEST,

    };
};
export const cancelAccountSuccess = (data) => {
    return {
        type: ActionTypes.CANCEL_ACCOUNT_SUCCESS,
        payload: data,
    };
};
export const cancelAccountFailure = (error) => {
    return {
        type: ActionTypes.CANCEL_ACCOUNT_FAILURE,
        error: error,
    };
};

export const freezeAccountRequest = () => {
    return {
        type: ActionTypes.FREEZE_ACCOUNT_REQUEST,

    };
};
export const freezeAccountSuccess = (data) => {
    return {
        type: ActionTypes.FREEZE_ACCOUNT_SUCCESS,
        payload: data,
    };
};
export const freezeAccountFailure = (error) => {
    return {
        type: ActionTypes.FREEZE_ACCOUNT_FAILURE,
        error: error,
    };
};

export const setDiscountRequest = () => {
    return {
        type: ActionTypes.SET_DISCOUNT_ACCOUNT_REQUEST,

    };
};
export const setDiscountSuccess = (data) => {
    return {
        type: ActionTypes.SET_DISCOUNT_ACCOUNT_SUCCESS,
        payload: data,
    };
};
export const setDiscountFailure = (error) => {
    return {
        type: ActionTypes.SET_DISCOUNT_ACCOUNT_FAILURE,
        error: error,
    };
};