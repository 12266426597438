import React,{ useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import { renderFileDrop } from '../../../utils/formUtils'
import { dataUrlToBase64, debounce } from '../../../utils/helpers'
import { change as reduxChange } from 'redux-form'
import
{
    Button,
    Modal,
    Form
} from 'react-bootstrap';
import _ from 'lodash';
const UploadImageModal = (props) => {
    const dispatch = useDispatch()
    const { openModal, handleMixpanelEvents ,handleToggleModal,submitData,formName, fieldName,unsplashImages ,getBase64,clearImage, handleSearch, previewFile } = props
    const [ selectedUnsplash, setSelectedUnsplash ] = useState(null);
    const unsplashLoading = useSelector((state) => state.assessment.unsplashLoading)

    const [ imageUrl , setImage ] = useState(null)
    const handleSelect = async(id) => {
        setSelectedUnsplash(id)
        const image = unsplashImages.filter((item) => item.id == id)[ 0 ];
        image && dataUrlToBase64(image.urls.regular,function(result){
            formName ? setImage(result) :  getBase64(result)
        });
    }

    const clearImageFun = (event) => {
        setSelectedUnsplash(null)
        setImage(null)
        clearImage(event)
    }
    const handleConfirm = () => {
        handleMixpanelEvents && handleMixpanelEvents(formName, fieldName, imageUrl)
        formName && dispatch(reduxChange(formName, fieldName, imageUrl))
        submitData && submitData() || handleToggleModal && handleToggleModal('confirm')
        setImage(null)
    }

    const handleClose = () => {
        setImage(null)
        handleToggleModal()
    }
    return(
        <Modal show={ openModal } onHide={ handleClose } className="image-upload-modal new-modal">
            <Modal.Header closeButton>
                <div className="new-modal-header">
                    <Modal.Title>Images</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="image-upload-modal-search">
                    <Form.Group>
                        <input onChange={ (event) => debounce(handleSearch,event,1000) } name='search' className='form-control' placeholder="Search images" />
                    </Form.Group>
                    <div className="powered-by-unsplash">
                        <a href="https://unsplash.com/" target="_blank"  rel="noreferrer" >Powered by Unsplash</a>
                    </div>
                </div>

                <div className="image-upload-modal-unsplash-gallery">
                    { unsplashLoading ?  <div className='unsplash-emtpy'><div className="small-up-loader btn-loader ">
                        <div className="lds-facebook"><div></div><div></div><div></div></div>
                    </div></div> : null }
                    {_.isEmpty(unsplashImages) && !unsplashLoading ? <div className='unsplash-emtpy'><p>No Records found</p></div> : <>
                        <div className="unsplash-gallery">
                            {unsplashImages.slice(0,10).map((item)=>{
                                return( <div onClick={ () => handleSelect(item.id) } key={ item.id } className={ `${ selectedUnsplash === item.id ? 'active' : '' }` }>
                                    <img src={ item.urls.small } alt="media1" />
                                </div>)
                            })}
                            {unsplashImages.slice(10,20).map((item)=>{
                                return( <div onClick={ () => handleSelect(item.id) } key={ item.id } className={ `${ selectedUnsplash === item.id ? 'active' : '' }` }>
                                    <img src={ item.urls.small } alt="media1" />
                                </div>)
                            })}
                        </div>
                    </>
                    }
                </div>
                <div className="image-upload-modal-form">
                    <div className={ `image-upload-modal-select ${ imageUrl || previewFile ? 'uploaded' : '' }` }>
                        <Field
                            name={ fieldName }
                            component={ renderFileDrop }
                            imageUrl={ imageUrl }
                            setImage={ setImage }
                            formName={ formName }
                            isDrop={ ()=> { setSelectedUnsplash(null)} }
                            placeholder={ '<a> <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="6" width="2" height="14" rx="1" fill="#5540BF"/><rect x="14" y="6" width="2" height="14" rx="1" transform="rotate(90 14 6)" fill="#5540BF"/></svg> upload your image</a>' }
                            isDropText={ 'Drag your images' }
                        />
                        {(previewFile || imageUrl)  && <div className="preview-logo">

                            <img src={ (imageUrl || previewFile) } />

                            <span onClick={ clearImageFun } className="clear-logo">clear</span>
                        </div>}
                    </div>
                    <div className="modal-btns">
                        { !props.children && <Button variant="primary" onClick={ handleConfirm } className="btn-primary-2">Confirm</Button>}
                        { props.children }
                    </div>
                </div>
            </Modal.Body>
        </Modal>

    )
}

UploadImageModal.propTypes = {
    formName: PropTypes.string,
    handleToggleModal: PropTypes.func,
    openModal: PropTypes.bool,
    handleSearch: PropTypes.func,
    getBase64: PropTypes.func,
    clearImage: PropTypes.func,
    previewImage: PropTypes.object,
    fieldName: PropTypes.string,
    unsplashImages: PropTypes.array,
    previewFile: PropTypes.object,
    submitData: PropTypes.func,
    children: PropTypes.children,
    handleMixpanelEvents: PropTypes.func

};

export default UploadImageModal