import { ActionTypes } from './actionTypes'

// GET /api/v1/user/sites/{id}/custom-domain
export const getDomainDataRequest = (data) => {
    return {
        type: ActionTypes.GET_DOMAIN_DATA_REQUEST,
        payload: data,
    };
};
export const getDomainDataSuccess = (data) => {
    return {
        type: ActionTypes.GET_DOMAIN_DATA_SUCCESS,
        payload: data,
    };
};
export const getDomainDataFailure = (data) => {
    return {
        type: ActionTypes.GET_DOMAIN_DATA_FAILURE,
        payload: data,
    };
};

// POST /api/v1/user/sites/{id}/custom-domain
export const connectDomainRequest = (data) => {
    return {
        type: ActionTypes.CONNECT_DOMAIN_REQUEST,
        payload: data
    };
};
export const connectDomainSuccess = (data) => {
    return {
        type: ActionTypes.CONNECT_DOMAIN_SUCCESS,
        payload: data,
    };
};
export const connectDomainFailure = (data) => {
    return {
        type: ActionTypes.CONNECT_DOMAIN_FAILURE,
        payload: data,
    };
};

// PATCH /api/v1/user/sites/{id}/custom-domain/_disconnect
export const disconnectDomainRequest = (data) => {
    return {
        type: ActionTypes.DISCONNECT_DOMAIN_REQUEST,
        payload: data
    };
};
export const disconnectDomainSuccess = (data) => {
    return {
        type: ActionTypes.DISCONNECT_DOMAIN_SUCCESS,
        payload: data,
    };
};
export const disconnectDomainFailure = (data) => {
    return {
        type: ActionTypes.DISCONNECT_DOMAIN_FAILURE,
        payload: data,
    };
};
