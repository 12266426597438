/* eslint-disable react/prop-types */
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import moment from 'moment'
import { ROUTES } from 'constants/appRoutes';
import { getCards } from 'middleware/card';

import { getTransactions } from 'middleware/transaction';
import TransactionListing from './components/transactionListing';
import CancelModal from './components/cancelModal';
import { cancelAccount, freezeAccount, setDiscountAccount } from 'middleware/subscription';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmAlert from 'components/core/confirm-alert'
import AddPaymentMethod  from './components/addPaymentMethod';
import { convertLongFormat } from 'services/date';
import CardListing from './components/cardListing';
import { getCurrentUser } from 'middleware/auth';
import { convertCurrencyWithOutFraction } from 'services/currency';
import { PLAN_STATUS } from 'constants/app';
import { MixpanelContext } from 'utils/tracking';
import { EVENTS } from 'constants/app'
import { convertPriceInDollerAndCent, isAnnualPlan, isBusinessPlan, mixpanelCommonData } from 'utils/helpers';

const BillingPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [ openCancelModal, setCancelModal ] = useState(false);
    const [ openAddCardModal, setAddCardModal ] = useState(false);
    const sessionData = useSelector((state)=>state?.user?.sessionData?.data?.data);
    const mixpanel = useContext(MixpanelContext);
    const [ userData, setUserData ] = useState(null);

    const loadTransactions = () => {
        dispatch(getTransactions());
    }

    useEffect(async () => {
        dispatch(getCurrentUser());
        dispatch(getCards());
        loadTransactions();
        dispatch({
            type: 'SET_ACTIVE_SIDEBAR',
            payload: '/billing'
        })
    }, [])

    useEffect(async () => {
        setUserData(sessionData)
    }, [ sessionData ])

    const handleToggleCancelModal = (event) => {
        event?.preventDefault();
        !openCancelModal && mixpanel.track(EVENTS.clickOnCancelModal,mixpanelCommonData({}))
        setCancelModal(!openCancelModal);
    }

    const handleNavigation = () => {
        history.push(ROUTES.PLAN_SELECTION);
    }

    const handleEvent = (eventName, data) => {
        mixpanel.track(eventName, mixpanelCommonData(data));
    }

    const handleCancelAccount = () => {
        handleToggleCancelModal()
        confirmAlert({
            // eslint-disable-next-line react/display-name
            customUI: ({ onClose }) => {
                return(
                    <ConfirmAlert
                        key={ 'box' }
                        onClose={ onClose }
                        handleAction={ () => dispatch(cancelAccount(userData?.subscription?.id, handleEvent)) }
                        subHeading='By confirming this your site(s) data will be deleted forever.'
                        linkLabel='Downgrade Your Account'
                        handleNavigation={ handleNavigation } />
                );
            }
        });
    }

    const handleFreezeAccount = () => {
        dispatch(freezeAccount(userData?.subscription?.id));
        setCancelModal(false);
    }

    const handleSetDiscountAccount = () => {
        dispatch(setDiscountAccount(userData?.subscription?.id));
        setCancelModal(false);
    }

    const handleClose = () => {
        setAddCardModal(false);
    }

    const renderAmountBox = () => {
        let planAmount = null;
        const amount = convertCurrencyWithOutFraction(+userData?.subscription?.planAmount);
        const nextBillingDate = <><b>Next Billing Date:</b> { convertLongFormat(userData?.subscription?.nextBillingDate) }</>;
        const amountFactor = convertPriceInDollerAndCent(+userData?.subscription?.planAmount);
        if (isAnnualPlan(userData?.subscription?.interval)) {
            planAmount = <>${amountFactor[ 0 ]}<sup>{+amountFactor[ 1 ]}</sup><sub>/mo</sub></>;
        } else {
            planAmount = <>{amount}<sub>/mo</sub></>;
        }

        return { planAmount, amount, nextBillingDate };
    }

    const handleChangePlan = (event, buttonLabel) => {
        event?.preventDefault();
        handleEvent(`Clicked on ${ buttonLabel }`, { subscription: userData.subscription, status: userData?.user?.status, 'user identifier': userData?.user?.id });
        handleNavigation();
    }

    const getPlanDetails = () => {
        let planName = null
        let endsAt = null
        let remaningDays = null;
        let amountData = null;
        let planAmount = null;
        let isBlocked = false;
        let nextBillingDate = null;
        let amount = 0;
        let buttonLabel = 'Change Plan';

        switch(userData?.user?.status) {
        case PLAN_STATUS.TRIAL:
            planName = 'Free Trial';
            endsAt = `${ userData?.user?.preSubscribedDays?.endsAt || 0 }`;
            remaningDays = `${ userData?.user?.preSubscribedDays?.daysRemaining || 0 } Days Left`
            break;
        case PLAN_STATUS.GRACE_PERIOD:
            planName = 'Grace Period';
            endsAt = `${ userData?.user?.preSubscribedDays?.endsAt || 0 }`;
            remaningDays = `${ userData?.user?.preSubscribedDays?.daysRemaining || 0 } Days Left`
            break;
        case PLAN_STATUS.ARCHIVED:
            planName = 'Archived Period';
            endsAt = `${ userData?.user?.preSubscribedDays?.endsAt || 0 }`;
            remaningDays = `${ userData?.user?.preSubscribedDays?.daysRemaining || 0 } Days Left`
            buttonLabel = 'Unarchive';
            break;
        case PLAN_STATUS.FROZEN:
            planName = 'Account Freezed';
            buttonLabel = 'Unfreeze';
            isBlocked = true;
            break;
        case PLAN_STATUS.DORMANT:
            planName = 'Subscription not taken';
            buttonLabel = 'Subscribe';
            isBlocked = true;
            break;
        case PLAN_STATUS.SUBSCRIPTION_CANCELLED:
            planName = 'Subscription cancelled';
            buttonLabel = 'Subscribe';
            isBlocked = true;
            break;
        case PLAN_STATUS.VIEWS_LIMIT_EXHAUSTED:
            planName = 'Monthly views limit exhausted';
            buttonLabel = 'Upgrade';
            isBlocked = true;
            amountData = renderAmountBox();
            amount = amountData.amount;
            nextBillingDate = amountData.nextBillingDate;
            planAmount = amountData.planAmount;
            break;
        case PLAN_STATUS.SUBSCRIBED:
            amountData = renderAmountBox();
            amount = amountData.amount;
            nextBillingDate = amountData.nextBillingDate;
            planAmount = amountData.planAmount;
            planName = userData?.user?.planDetails?.planName || '';
            break;
        }

        return { planName, remaningDays, endsAt, amount, planAmount, nextBillingDate, isBlocked, buttonLabel };
    }

    const renderSelectPlan = () => {
        const { planName,endsAt, amount, planAmount, nextBillingDate, isBlocked, buttonLabel } = getPlanDetails();
        return (<div className="profile-active-plan col-sm-6">
            <div className="profile-subhead-wrap">
                <h4 className="profile-subhead">Manage Plan{ nextBillingDate && <span className="nextBillDate">{nextBillingDate}</span>}</h4>
            </div>
            <div className="subscribed-plan annual-state">
                <div className={ `subscribed-plan-info ${ isBlocked ? 'unsubscribed-state' : '' }` }>
                    {isAnnualPlan(userData?.subscription?.interval) && <span className="annual-box">Annual</span>}
                    {planName && <h6 className={ isBusinessPlan(userData?.user?.planDetails?.planName) ? 'businessPlan' : '' }>{planName}</h6>}
                    {planAmount && <div className="plan-price">{planAmount}</div>}
                    {endsAt && <h4 className="">Ends on {moment(endsAt).format('L')}</h4> }
                    {/* {remaningDays && <h4 className="">{remaningDays}</h4>} */}
                    {isAnnualPlan(userData?.subscription?.interval) && <h4 className="">Billed at {amount}/yr.</h4>}
                    <div className="alignBox">
                        <Link to='#' onClick={ (e) => handleChangePlan(e, buttonLabel) } className={ 'btn btn-primary' }>{buttonLabel}</Link>
                        {userData?.user?.status === PLAN_STATUS.SUBSCRIBED && <a href="/" onClick={ handleToggleCancelModal } className={ 'cancelLink' }>
                            Cancel Account
                        </a>}
                    </div>
                </div>
            </div>
        </div>)
    }

    return(
        <main className="dashboard-data billing-address billing-boxes">
            <section className="dashboard-body row">
                <div className="dashboard-header col-sm-12">
                    <div className="dashboard-title row">
                        <h2 className="col-12 pl-0">Billing</h2>
                    </div>
                </div>
                <div className="profile-subscription row">
                    {renderSelectPlan()}
                    <CardListing setAddCardModal={ setAddCardModal } />
                </div>
                <TransactionListing loadTransactions={ loadTransactions } />
                <Modal show={ openAddCardModal } onHide={ handleClose } className={ 'updateBill' }>
                    <AddPaymentMethod callbackEvent={ handleClose } />
                </Modal>
                {userData?.subscription?.id && <Modal show={ openCancelModal } className="modalCancle" onHide={ handleToggleCancelModal } >
                    <CancelModal
                        handleCancelAccount={ handleCancelAccount }
                        handleFreezeAccount={ handleFreezeAccount }
                        handleSetDiscountAccount={ handleSetDiscountAccount }
                        onClose={ handleToggleCancelModal }
                        discountTaken={ userData?.user?.discountTaken }
                        isAnnualPlan={ isAnnualPlan(userData?.subscription?.interval) }
                    />
                </Modal>}
            </section>
        </main>
    )
}

BillingPage.propTypes = {
    handleSubmit: PropTypes.func,
    initialize: PropTypes.object,
    submitting: PropTypes.bool,
    asyncValidating: PropTypes.bool
};

export default BillingPage;
